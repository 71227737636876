import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import { uploadFile, AddComments } from "../../services";
import * as helper from "../../helper";
import Spinner from "../common/Spinner";

const AddComment = (props) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [commentError, setCommentError] = useState('');

  let userData = localStorage.getItem("user");
  userData = JSON.parse(userData);
  const [user, setUser] = useState(userData);

  const AddCommentSchema = Yup.object().shape({
    // comment: Yup.string().required("Comment is required"),
  });


  const onChangeImage = async (file) => {
    if (file) {
      setAlertMessage("");
      if (helper.isVideoFormat(file.name)) {
        const duration = await helper.getVideoDuration(file);
        if (!helper.isVideoFormat(file.name)) {
          setAlertMessage("Please upload valid video format");
          return false;
        } else if (duration > 300) {
          setAlertMessage("Video duration should be less than 5 minutes");
          return false;
        } else {
          const formData = new FormData();
          formData.append("file", file, file.name);
          await uploadMedia(formData);
        }
      } else if (
        !["png", "jpg", "jpeg", "gif", "svg", "webp", "pdf"].includes(
          file.name.split(".")[1]
        )
      ) {
        setAlertMessage("Please upload valid image format");
        return false;
      } else {
        const formData = new FormData();
        formData.append("file", file, file.name);
        await uploadMedia(formData);
      }
    }
  };

  const uploadMedia = (formData) => {
    setLoading(true);
    uploadFile(formData)
      .then((response) => {
        let uploadedFileName = response?.data?.data?.fd;
        setFileName(uploadedFileName);
        setLoading(false);
      })
      .catch((error) => {
        let message = error.response?.data?.message;
        if (message) {
          setAlertMessage(message);
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Formik
        validationSchema={AddCommentSchema}
        initialValues={{
          user_id: props.userId,
          comment: "",
          media: "",
          isReply: 0,
          subId: 0,
          topic_id: props.topicId,
        }}
        onSubmit={(values, { resetForm }) => {
          // console.log(values);
          values.media = fileName;
          if (values?.comment || fileName) {
            setLoading(true);
            AddComments(values, props.token)
              .then((response) => {
                resetForm({ values: "" });
                setFileName();
                props.getCommentsData();
                setLoading(false);
                setCommentError('');
              })
              .catch((error) => {
                let message = error.response?.data?.message;
                if (message) {
                  setAlertMessage(message);
                  setLoading(false);
                  setCommentError('');
                }
              });
          } else {
            setCommentError('Comment is required');
          }
        }}
      >

        {({ handleChange, handleSubmit, values, errors }) => (
          <Form
            className="contribute-modal-form"
            noValidate
            onSubmit={handleSubmit}
            method="post"
          >
            <Form.Group className="form-group mb-0">
              {(user == " " || user == null || user == undefined) ?
                <>
                  <Form.Control
                    type="text"
                    disabled
                    // isInvalid={!!errors.comment}
                    placeholder="Add a Comment or Review"
                  />
                  <div className="comment-area-action">
                    <div className="upload-input-file-btn">
                      <Form.Control
                        disabled
                        type="file"
                        className="file-blank"
                      />
                      <a className="btn">
                        <img src="/static/images/icon-add.png" alt="Medcraze" />
                      </a>
                    </div>
                    <div className="send-btn">
                      <button disabled>
                        <img src="/static/images/icon-send.png" alt="Medcraze" />
                      </button>
                    </div>
                  </div>
                </>
                :
                <>
                  <Form.Control
                    type="text"
                    name="comment"
                    value={values.comment}
                    onChange={handleChange}
                    // isInvalid={!!errors.comment}
                    placeholder="Add a Comment or Review"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.comment}
                  </Form.Control.Feedback>

                  {alertMessage && <div>{alertMessage}</div>}

                  <div className="comment-area-action">
                    <div className="upload-input-file-btn">
                      <Form.Control
                        type="file"
                        name="media"
                        onChange={(e) => {
                          onChangeImage(e.target.files[0]);
                          handleChange(e);
                        }}
                        className="file-blank"
                      />
                      <a className="btn">
                        <img src="/static/images/icon-add.png" alt="Medcraze" />
                      </a>
                    </div>
                    <div className="send-btn">
                      <button type="submit">
                        <img src="/static/images/icon-send.png" alt="Medcraze" />
                      </button>
                    </div>
                  </div>
                </>
              }
            </Form.Group>
            {fileName}
            <span>{commentError}</span>
          </Form>
        )}
      </Formik>
      {isLoading && <div className="page-spinner"><div className="white-box"><Spinner /></div></div>}
    </>
  );
};

export default AddComment;
