import React from 'react'
import { Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import GoogleAds from './GoogleAds';

function AddTopicGuide() {
  return (
    <main className="main-container add-topic-guide-page">
      <Container>
        <div className="white-box mb-50">
          <div className="sub-title justify-content-center">
            <h2>Content Creation Guide</h2>           
          </div>
          <div className="skip-action text-end mb20">
            <Link to="/add-topic" className="btn btn-blue btn-gray-outline btn-radius20"> Skip</Link>
          </div>
          <h5>CREATE A SUMMARY/EVOLUTION FOR THE TWO UMBRELLA CATEGORIES RELATED TO YOUR SPECIFIC TECHNOLOGY (TECHNOLOGY AND PROCEDURE/APPLICATION)</h5>
          <p>- Umbrella Diagnosis categories = Overarching Technology and Procedure (if applicable)</p>
          <p>- The Summary/Evolution is a concise but detailed description of the umbrella category that:</p>
          <ol className="ol-list">
            <li>Introduces the assigned Umbrella Category (and how they have evolved over time (products/materials/ surgical techniques utilized, if applicable). Specific technologies/brands should not be mentioned here.</li>
            <li>Mentions subcategories if applicable</li>
            <li>Reports the evolution of the Umbrella Categories over time</li>
            <li>Includes representative images</li>
          </ol>
          <p>****When creating the Summary/Evolution for the Procedure and Technology categories, do NOT provide detailed diagnostic information. The procedure and technology summary/evolution assumes that the user has obtained their diagnosis and has been educated about that specific condition.</p>
          <p>MEDcraze is strictly an educational platform. MEDcraze does not provide medical advice or treatment.</p>
          <p>When writing, please keep in mind your audience is consumers so all content shall be composed in a way that is easily understood. To use a popular phrase, “Dumb it down”.</p>
          <h5>CREATE SUMMARY/EVOLUTION FOR A SPECIFIC MEDICAL TECHNOLOGY/INNOVATION</h5>
          <p>-The summary/evolution for the specific technology/ innovation is a concise but detailed introduction to the specific technology that: Note: MEDcraze is not just about revealing medical product information, it’s main goal is to reveal information about the NEWEST product information – information you can’t easily find on the internet.</p>
          <h5>CREATE SUMMARY/EVOLUTION FOR TWO COMPETING OR RELEVANT TECHNOLOGIES IN THE SAME MANNER AS YOU CREATED IN STEP 3 ABOVE.</h5>
          <p>- After this step, you will have created a Summary/ Evolution of 3 distinct (but competing) technologies</p>
          <h5>CREATE INTRO VIDEO</h5>
          <p>– This is a quick 30-60 second informal introduction to the topicpage you have created. It’s for general orientation purposes and will help the user understand</p>
          <ol className="ol-list">
            <li>Why they have arrived on your technology topic page</li>
            <li>Provide quick info on what they will see and experience on the page – very general background on technologies and procedure info</li>
            <li>Tell them they will see a few new technology options as well. You can simply use your cell phone or have someone film it on ipad etc.. Again, this is informal. Please dress casually, in scrubs, or whatever you’re comfortable wearing. VERY IMPORTANT – PLEASE TURN YOUR CELL PHONE HORIZONTALLY WHEN FILMING. We will attach an example video for your reference.</li>
          </ol>
          <div className="page-action text-end  mt-2 mb-2">
            <Link className="btn btn-blue" to="/add-topic">
              Next
            </Link>
          </div>
        </div>


        <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />           
      </Container>
    </main>
  )
}

export default AddTopicGuide