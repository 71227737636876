const phoneNumberFormat = (phone) => {
    phone = phone.replace(/\D/g,'');
    phone = phone.substring(0,10);

    // Based upon the length of the string, we add formatting as necessary
    const size = phone.length;
    if(size == 0){
        phone = phone;
    } else if(size < 4){
        phone = '('+phone;
    } else if(size < 7){
        phone = '('+phone.substring(0,3)+') '+phone.substring(3,6);
    } else{
        phone = '('+phone.substring(0,3)+') '+phone.substring(3,6)+'-'+phone.substring(6,10);
    }
    
    return phone;    
}

const getExtension = (filename) => {
    //const parts = filename.split('.');
    const parts = filename.split(/\.(?=[^\.]+$)/);

    return parts[parts.length - 1];
}

const isVideoFormat = (filename) => {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'm4v':
        case 'avi':
        case 'mpg':
        case 'mpeg':
        case 'mp4':
        case 'webm':
        case 'mkv':
        case 'flv':
        // etc
        return true;
    }
    return false;
}

const isValidLinkURL = (url) => {
    const pattern = /^((http|https|ftp):\/\/)/;
    let link = url; 
    if(!pattern.test(url)) {
        link = "http://" + url;
    }

    return link;
} 

const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
];
  
const timeSince = (date) => {
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
}

export { phoneNumberFormat, isVideoFormat, isValidLinkURL, timeSince };