import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import GoogleAds from './GoogleAds';

function EditorialBoard() {

  const [openItem1, setOpenItem1] = useState(false);
  const [openItem2, setOpenItem2] = useState(false);
  const [openItem3, setOpenItem3] = useState(false);
  const [openItem4, setOpenItem4] = useState(false);
  const [openItem5, setOpenItem5] = useState(false);
  const [openItem6, setOpenItem6] = useState(false);
  const [openItem7, setOpenItem7] = useState(false);

  return (
    <main className="main-container editorial-board-page">
      <Container>

        <Row className="mb-50">
          <Col md={12} lg={12}>
            <div className="sub-title justify-content-center">
              <h2 className="text-blue">Editorial Board</h2>
            </div>
            <div className="accordian-panel">
              <ul>
                <li>
                  <div className="acc-team-panel">
                    
                    <div className="acc-team-pic">
                      <img src="/static/images/board-member/member1.jpg" alt="Medcraze" />
                    </div>
                    <div className="acc-team-info">
                    <Button
                      onClick={() => setOpenItem1(!openItem1)}
                      aria-controls="example-collapse-text"
                      aria-expanded={openItem1}
                      className="accordian-arrow"
                    >
                      {openItem1 ?
                        <i className="fas fa-chevron-up"></i>
                        :
                        <i className="fas fa-chevron-down"></i>
                      }

                    </Button>
                      <h3>UCHECHI WOSU MD</h3>
                      <h4>Editorial Board Member</h4>
                      <div className="acc-team-description">
                        <p>Dr. Uchechi Wosu is a native Washingtonian and a practicing physician in the Greater Washington DC Metropolitan Area. She obtained a dual MD/MPH degree from George Washington University. In 2014 she authored Straight Talk From the Doctor and travels across the country as a public speaker promoting health and wellness. Her goal is to empower her patients and audience to be their best self and provide them with the knowledge to implement healthy behaviors on a daily basis.</p>
                        <Collapse in={openItem1} dimension="height">
                          <div id="example-collapse-text">
                            <p>While emphasizing the impact of personal choices and behaviors on a persons health, she also values medical advances that address a variety of medical conditions. By educating her patients on both they are able to make informed decisions and receive the care they deserve.</p>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="acc-team-panel">
                    
                    <div className="acc-team-pic">
                      <img src="/static/images/board-member/member2.jpg" alt="Medcraze" />
                    </div>
                    <div className="acc-team-info">
                    <Button
                      onClick={() => setOpenItem2(!openItem2)}
                      aria-controls="example-collapse-text2"
                      aria-expanded={openItem2}
                      className="accordian-arrow"
                    >
                      {openItem2 ?
                        <i className="fas fa-chevron-up"></i>
                        :
                        <i className="fas fa-chevron-down"></i>
                      }

                    </Button>
                      <h3>DANIEL GERSCOVICH MD</h3>
                      <h4>Editorial Board Member</h4>
                      <div className="acc-team-description">
                        <p>Born and raised in Orlando, Florida, Dr. Gerscovich attended the University of Virginia as a Division 1 swimmer and a degree in BSEd in Exercise Physiology. He completed medical school at the University of South Florida, Tampa. Dr. Gerscovich then attended George Washington University for Orthopedic Surgery Residency, in Washington, D.C..</p>
                        <Collapse in={openItem2} dimension="height">
                          <div id="example-collapse-text2">
                            <p>Following his residency, he completed fellowship training in Adult Reconstruction, primary and complex hip and knee reconstruction at the Anderson Orthopedic Clinic in Alexandria, Virginia. During his spare time, Dr. Gerscovich enjoys spending time with his wife, and two young sons along with fishing and golfing.</p>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="acc-team-panel">
                    
                    <div className="acc-team-pic">
                      <img src="/static/images/board-member/member3.jpg" alt="Medcraze" />
                    </div>
                    <div className="acc-team-info">
                    <Button
                      onClick={() => setOpenItem3(!openItem3)}
                      aria-controls="example-collapse-text2"
                      aria-expanded={openItem3}
                      className="accordian-arrow"
                    >
                      {openItem3 ?
                        <i className="fas fa-chevron-up"></i>
                        :
                        <i className="fas fa-chevron-down"></i>
                      }

                    </Button>
                      <h3>BARAN KILICAL MD</h3>
                      <h4>Editorial Board Member</h4>
                      <div className="acc-team-description">
                        <p>Dr. Kilical was born in Turkey and lived in several countries before his family settled in Northern Virginia when he was fifteen. He attended the Thomas Jefferson High School for Science and Technology and the The George Washington University for undergraduate studies. He was embraced by GWU and stayed on for medical school, internship and residency in internal medicine, fellowships in cardiology and cardiac electrophysiology.</p>
                        <Collapse in={openItem3} dimension="height">
                          <div id="example-collapse-text3">
                            <p>Dr Kilical is board certified in both cardiology and cardiac electrophysiology and is a fellow of the American College of Cardiology, but his true passion is the treatment of heart rhythm disorders. He provides medical management, implants pacemakers and defibrillators, and performs catheter ablations all for the treatment of heart rhythm disturbances. When he is not at work, he enjoys spending time with his wife and two children, kitesurfing, and traveling. His wife is an orthopaedic hand surgeon also practicing in Maryland; the family resides in Annapolis. Dr. Kilical strives to provide excellent, evidence-based and up-to-date cardiac and electrophysiology care to all his patients.</p>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="acc-team-panel">
                    
                    <div className="acc-team-pic">
                      <img src="/static/images/board-member/member4.jpg" alt="Medcraze" />
                    </div>
                    <div className="acc-team-info">
                    <Button
                      onClick={() => setOpenItem4(!openItem4)}
                      aria-controls="example-collapse-text2"
                      aria-expanded={openItem4}
                      className="accordian-arrow"
                    >
                      {openItem4 ?
                        <i className="fas fa-chevron-up"></i>
                        :
                        <i className="fas fa-chevron-down"></i>
                      }

                    </Button>
                      <h3>DWANA SHABAZZ, MD-MPH</h3>
                      <h4>Editorial Board Member</h4>
                      <div className="acc-team-description">
                        <p>Dwana R. Shabazz, MD-MPH is a board-certified Dermatologist with a Master of Public Health in Community Health. Upon completing residency in 2006, she joined Dermatology Associates of Northern Virginia. While there, she further developed her clinical, teamwork, and leadership skills. This led to her stepping out on faith and opening Renascance Dermatology, P.C in 2013.</p>
                        <Collapse in={openItem4} dimension="height">
                          <div id="example-collapse-text2">
                            <p>While developing her business, she continued to not only practice clinically, but also educate and serve her colleagues and the community. In the Summer 2013, she was a speaker for the Dermatology Section of the National Medical Association (NMA)- topic Maintenance of Certification. Also, in 2013, she served as a community consultant for Galderma by educating consumers about a new product line.</p>
                            <p>From 2014-2016, Dr. Shabazz was a contributor to Fairfax Woman Magazine. She was a mentee to the Journal of American Academy of Dermatology (JAAD) Editorial Mentorship Program in 2016. The NBC Health and Fitness Expo is an annual event in Washington, D.C. In 2017, through the American Academy of Dermatology (AAD) Spot Me Program, she volunteered as a skin cancer screener. Dr. Shabazz participated in Howard University’s Dermatology Visiting Lecturer Series in 2020. She spoke to residents about career choices after residency.</p>
                            <p>She received her Bachelor of Science degree in Biology from the Xavier University of Louisiana. She completed both her Medical degree and Master of Public Health degree at George Washington University. Her dermatology residency was completed at King Drew Medical Center in Los Angeles, California.</p>
                            <p>In her spare time, she enjoys spending time with her husband, daughter, and son. The family particularly enjoys participating in and watching sports and travelling. Her desire is to be able to bridge her dermatologic skills with her enthusiasm for sports.</p>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </li>


                <li>
                  <div className="acc-team-panel">
                  
                    <div className="acc-team-pic">
                      <img src="/static/images/board-member/member5.jpg" alt="Medcraze" />
                    </div>
                    <div className="acc-team-info">
                    <Button
                      onClick={() => setOpenItem5(!openItem5)}
                      aria-controls="example-collapse-text2"
                      aria-expanded={openItem5}
                      className="accordian-arrow"
                    >
                      {openItem5 ?
                        <i className="fas fa-chevron-up"></i>
                        :
                        <i className="fas fa-chevron-down"></i>
                      }

                    </Button>
                      <h3>SARENTHIA EPPS, MD</h3>
                      <h4>Editorial Board Member</h4>
                      <div className="acc-team-description">
                        <p>Dr. Epps, a native of El Dorado, Arkansas, is a board-certified Internal Medicine physician with a Master of Business Administration, who earned her Bachelor of Science degree in Chemistry at the University of Arkansas in Fayetteville. After completing her bachelor’s degree, Dr. Epps completed her medical degree and Internal Medicine residency at the University of Arkansas for Medical Sciences in Little Rock, Arkansas. While in residency, she participated in many research projects focused on pulmonary and critical care medicine with several publications and conference presentations.</p>
                        <Collapse in={openItem5} dimension="height">
                          <div id="example-collapse-text2">
                            <p>Dr. Epps is currently completing a fellowship in Pulmonary and Critical Care Fellowship at the University of Arkansas for Medical Sciences. Her career plans involve pulmonary and critical care medicine, helping build her community through healthy living and healthcare, and magnifying her entrepreneurial spirit with focus on anti-aging medicine.</p>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="acc-team-panel">
                  
                    <div className="acc-team-pic">
                      <img src="/static/images/board-member/member6.jpg" alt="Medcraze" />
                    </div>
                    <div className="acc-team-info">
                    <Button
                      onClick={() => setOpenItem6(!openItem6)}
                      aria-controls="example-collapse-text2"
                      aria-expanded={openItem6}
                      className="accordian-arrow"
                    >
                      {openItem6 ?
                        <i className="fas fa-chevron-up"></i>
                        :
                        <i className="fas fa-chevron-down"></i>
                      }

                    </Button>
                      <h3>ALEX SAILON, MD</h3>
                      <h4>Editorial Board Member</h4>
                      <div className="acc-team-description">
                        <p>Dr. Alexander M. Sailon is a Washington, D.C. based plastic surgeon focused on aesthetic and reconstructive surgery of the face, breast, and body. He believes in listening to the concerns of patients and combines that approach with attention to detail and an analytical methodology to deliver beautiful and natural-looking results. He is committed to helping each and every patient achieve his or her desired goals through an attentive, highly individualized approach.</p>
                        <Collapse in={openItem6} dimension="height">
                          <div id="example-collapse-text2">
                            <p>Dr. Sailon is a native of New Jersey & New York and attended the Horace Mann School. After graduating cum laude from Georgetown University, he attended New Jersey Medical School on scholarship. He was elected to the Alpha Omega Alpha Honor Medical Society as a result of his academic achievements. Subsequently, he completed a six-year integrated plastic surgery residency at Mount Sinai Hospital in New York City where he had the chance to work with many of Manhattan’s plastic surgeons.</p>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="acc-team-panel">
                  
                    <div className="acc-team-pic">
                      <img src="/static/images/board-member/member7.jpg" alt="Medcraze" />
                    </div>
                    <div className="acc-team-info">
                    <Button
                      onClick={() => setOpenItem7(!openItem7)}
                      aria-controls="example-collapse-text2"
                      aria-expanded={openItem7}
                      className="accordian-arrow"
                    >
                      {openItem7 ?
                        <i className="fas fa-chevron-up"></i>
                        :
                        <i className="fas fa-chevron-down"></i>
                      }

                    </Button>
                      <h3>ANGELA JONES, MD</h3>
                      <h4>Editorial Board Member</h4>
                      <div className="acc-team-description">
                        <p>Originally from Seattle, Washington, Dr. Jones spent her childhood there and graduated from Seattle Preparatory School in the top 3 students of her class. She majored in Cell and Molecular Biology while completing the Honors Program at the University of Washington. She graduated with honors, Golden Key Honors and Cum Laude. Upon graduating in the top third of her class from Washington University in St. Louis School of Medicine, Dr. Jones completed internship and residency in Orthopaedic Surgery at The George Washington University in Washington, DC. She then moved to Charlottesville, Virginia for a one year Hand and Microvascular Surgery Fellowship at the University of Virginia. She is Board Certified in Orthopaedic Surgery and a diplomat of the American Academy of Orthopaedic Surgery.</p>
                        <Collapse in={openItem7} dimension="height">
                          <div id="example-collapse-text2">
                            <p>Dr. Jones has more than ten years’ experience as a practicing surgeon in Maryland, enjoying a steady volume of orthopedic and hand surgery patients during that time. She transitioned from a large, hospital-based group model to a private, independent practitioner setting to provide the best care to patients in a positive, personalized environment. She enjoys treating a variety of specialty hand problems including arthritic conditions, trauma, nerve problems and general orthopaedic issues as well. Dr. Jones is now excited to start working with a company that helps patients get up-to-date information on their medical conditions, as well as the technologies and implants involved with their care. Medical practice, additional medical writing assignments, along with family activities, wine tasting and gym work (not in that order), help foster a balanced and busy life. A cardiac electrophysiologist, a 4th grader, a pre-K learner, and two pets round out her family. Dr. Jones has ties to the Annapolis and Southern Maryland communities by way of the professional medical community, family and friends.</p>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </li>


              </ul>
            </div>
          </Col>
        </Row>
        <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
      </Container>
    </main>
  )
}

export default EditorialBoard