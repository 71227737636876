import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getFetchProfile, getTopicList, signIn, webIntroDone } from "../services";
import { imgPath } from "../services";
import { Link } from "react-router-dom";
import Search from "./Search";
import Spinner from "./common/Spinner";
import GoogleAds from "./GoogleAds";

function TopicList() {
  const [topicList, setTopicList] = useState([]);

  let userData = localStorage.getItem("user");
  userData = JSON.parse(userData);
  const userId = userData?.id;
  const userTypeData = userData?.userType;
  const token = userData?.auth?.token;
  const isIntroDone = userData?.isWebIntroDone;
  const [profileData, setProfileData] = useState([])
  const [user, setUser] = useState(userData);
  const [show, setShow] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const getTopicListCall = async (searchKey = "") => {
    let statusParams = userTypeData && userTypeData == "contributor" ? '' : 'published';
    let userParams = userTypeData && userTypeData == "consumer" ? '' : userId;
    let params = { pageSize: 1000, pageNumber: 1, name: searchKey, status: statusParams, user_id: userParams };
    let response = await getTopicList(params);
    setLoading(false);
    let data = response.data?.data;
    setTopicList(data);
  };

  const getFetchProfileCall = async () => {
    let response = await getFetchProfile(userId, token);
    console.log("response ProfileData", response.data.data);
    let data = response.data?.data;
    setProfileData(data);
  };
  useEffect(() => {
    getFetchProfileCall();
  }, []);

  const webIntroData = async () => {
    let params = { user_id: userId, isWebIntroDone: 1 };
    let response = await webIntroDone(params);
    setLoading(false);
    let data = response.data?.data;
  };

  useEffect(() => {
    getTopicListCall();
  }, []);

  useEffect(() => {
    webIntroData();
  }, []);

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', show && user && (userTypeData == "contributor") && (profileData?.isWebIntroDone === 0));
  }, [show && user && userTypeData && profileData?.isWebIntroDone])

  const searchList = (values) => {
    getTopicListCall(values.searchText);
  };

  const introButton = async () => {
    setShow(false)
  }



  return (
    <main className="main-container topic-list-page">
      {isLoading && <div className="page-spinner"><div className="white-box"><Spinner /></div></div>}
      <div className="search-section mb-50">
        <Container>
          <Search searchList={searchList} />
        </Container>
      </div>
      <section className="topic-listing-panel mb-50">
        <Container>
          <div className="sub-title d-flex align-items-center justify-content-between">
            <h2 className="text-capitalize">Topics</h2>
            {(user == " " || user == null || user == undefined || userTypeData == "consumer") ? (
              ''
            )
              :
              (
                <div className={show ? 'userguide' : ''}>

                  {
                    show && profileData?.isWebIntroDone === 0 ? <div className="guidebg-overlay"></div> : null
                  }

                  <Link className="btn btn-blue btn-sm ml-auto" to="/add-topic-guide">
                    <i className="fa fa-plus me-1" aria-hidden="true"></i> Add Topic
                  </Link>

                  {
                    show && profileData?.isWebIntroDone === 0 ?
                      <>
                        <div className="display-handicon">
                          <img src="/static/images/hand-click.png" alt="Medcraze" />
                        </div>
                        <div className="userguide-view text-uppercase">
                          <h3>Click to add topic - <i className="fa fa-plus" aria-hidden="true"></i> icon</h3>
                          <div className="down-arrow-line">
                            <img src="/static/images/arrow-down-long.png" alt="Medcraze" />
                          </div>
                          <Button className="btn btn-blue btn-white" onClick={() => introButton()}>Got It</Button>
                        </div>
                      </>
                      : null
                  }

                </div>
              )
            }

          </div>
          <Row>
            {topicList?.records ? (
              topicList?.records.map((list) => (
                <Col lg={4} md={6} sm={6} className="mb30">
                  <div className="topic-item height100">
                    <div className="topic-pic">
                      <video
                        src={imgPath + list.introVideo}
                        width="100"
                        height="200"
                      >
                      </video>
                    </div>
                    {list.status === "draft" && (user == " " || user == null || user == undefined || userTypeData == "contributor") ?
                      (
                        <div className="topic-info">
                          {/* <Link to={`/add-topic`} className="share">
                            <i className="fas fa-share-alt"></i>
                          </Link> */}
                          <h4 className="text-uppercase">
                            <Link to={`/edit-topic/${list.id}/${list.user_id}`}>{list.name}</Link>
                          </h4>
                          <p>
                            {list.firstName} {list.lastName},{" "}
                          </p>
                          <p className="text-capitalize">{list.contributorType}</p>
                          <div className="topic-action">
                            <Link
                              to={`/edit-topic/${list.id}/${list.user_id}`}
                              className="btn btn-blue btn-sm"
                            >
                              {list.status == "draft" && <>Saved as Draft</>}
                            </Link>
                          </div>
                        </div>
                      )
                      :
                      (
                        <div className="topic-info">
                          <Link to={`/topic-detail/${list.id}/${list.user_id}`} className="share">
                            <i className="fas fa-share-alt"></i>
                          </Link>
                          <h4 className="text-uppercase">
                            <Link to={`/topic-detail/${list.id}/${list.user_id}`}>{list.name}</Link>
                          </h4>
                          <p>
                            {list.firstName} {list.lastName},{" "}
                          </p>
                          <p className="text-capitalize">{list.contributorType}</p>
                          <div className="topic-action">
                            <div className="topic-action-left">
                              <Link
                                to={`/topic-detail/${list.id}/${list.user_id}`}
                                className="btn btn-blue btn-sm text-capitalize mr-1"
                              >
                                Read More
                              </Link>
                              {user && userTypeData == "contributor" && <span
                                className="btn btn-blue btn-blue-outline text-capitalize btn-sm disable-blue-outline"
                              >
                                {list.status}
                              </span>
                              }
                            </div>

                            <span className="btn btn-gray-light btn-sm btn-radius20">
                              <i className="fas fa-arrow-up text-blue"></i>{" "}
                              {list.upvotes}
                            </span>
                          </div>
                        </div>
                      )
                    }

                  </div>
                </Col>
              ))
            ) : (
              <div className="no-record-found text-center mb-3">No Record Found</div>
            )}
          </Row>
          <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
        </Container>
      </section>
    </main>
  );
}

export default TopicList;
