import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom';
import AboutUs from "./components/AboutUs";
import Home from './components/Home';
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import AddTechnology from "./components/AddTechnology";
import AddTopic from "./components/AddTopic";
import AddTopicGuide from "./components/AddTopicGuide";
import ConfidentialAggrement from "./components/ConfidentialAggrement";
import ContactUs from "./components/ContactUs";
import EditorialBoard from "./components/EditorialBoard";
import EngagementLetter from "./components/EngagementLetter";
import MediaPressDetail from "./components/MediaPressDetail";
import MediaPressList from "./components/MediaPressList";
import MyProfile from "./components/MyProfile";
import NewsList from "./components/NewsList";
import Notification from "./components/Notification";
import PrivacyPolicy from "./components/PrivacyPolicy";
import RecentSearchList from "./components/RecentSearchList";
import SpotlightTechnologiesList from "./components/SpotlightTechnologiesList";
import SpotlightTechnologiesDetail from "./components/SpotlightTechnologiesDetail";
import TermsOfUse from "./components/TermsOfUse";
import TopicList from "./components/TopicList";
import TopicDetails from "./components/TopicDetails";
import ScrollToTop from "./layout/ScrollTop";
import AddTechWrapper from "./components/AddTechWrapper";
import EditTopic from "./components/EditTopic";
import TrendingTopicList from "./components/TrendingTopics";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/confidential-aggrement" element={<ConfidentialAggrement />} />
          <Route path="/engagement-letter" element={<EngagementLetter />} />   
          <Route path="/add-topic" element={<AddTopic />} />       
          <Route path="/edit-topic/:id/:userid" element={<EditTopic />} />       
          <Route path="/add-topic-guide" element={<AddTopicGuide />} /> 
          <Route path="/editorial-board" element={<EditorialBoard />} />   
          <Route path="/about" element={<AboutUs />} />
          <Route path="/add-technology/:id/:userid" element={<AddTechWrapper />} />    
          <Route path="/contact-us" element={<ContactUs />} />                  
          <Route path="/mediapress-detail/:id" element={<MediaPressDetail />} />
          <Route path="/mediapress-list" element={<MediaPressList />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/news-list" element={<NewsList />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/recent-search-list" element={<RecentSearchList />} />
          <Route path="/spotlighttech-list" element={<SpotlightTechnologiesList />} />
          <Route path="/spotlighttech-detail/:id" element={<SpotlightTechnologiesDetail />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/topic-list" element={<TopicList />} />
          <Route path="/topic-detail/:id/:user_id" element={<TopicDetails />} />
          <Route path="/trending-topics" element={<TrendingTopicList />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
