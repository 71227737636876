import axios from "axios";
import { baseUrl } from "../constants";

export const imgPath = "https://medcraze.s3.us-west-2.amazonaws.com/";

export const getHome = () => {
  return axios.get(`${baseUrl}/api/post/getHomepage`);
};

export const userSignup = (data) => {
  return axios.post(`${baseUrl}/api/user/register`, data);
};

export const getNewList = (records = 10) => {
  return axios.get(`${baseUrl}/api/user/medtechNews/${records}`);
};

export const getMediaPressList = (records = 500, page = 1, token) => {
  return axios.get(`${baseUrl}/api/user/pressRelease/${records}/${page}`, {
    headers: {
      authtoken: token,
    },
  });
};

export const getTopicList = (params) => {
  return axios.post(`${baseUrl}/api/admin/topics/list`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getTopicDetail = (id, token) => {
  return axios.get(`${baseUrl}/api/post/topic/${id}`);
};

export const addTopicRecentSearch = (id, token, status) => {
  return axios.get(`${baseUrl}/api/post/topic/${id}/${status}`, {
    headers: {
      authtoken: token,
    },
  });
};

export const getFetchProfile = (id) => {
  return axios.get(`${baseUrl}/api/user/fetchProfile/${id}`);
};

export const signIn = (data, successCallback) => {
  return axios.post(`${baseUrl}/api/user/login`, data)
    .then((response) => {
        successCallback(response.data);
    })
    .catch(function (error) {
        if(error.response) {
          console.log('hello', error.response);
          if(error.response.status === 400) {
              successCallback(error.response.data);
          }
        }
  });
};

export const getRecentSearch = (params, token) => {
  return axios.post(`${baseUrl}/api/user/recentSearches`, params, {
    headers: {
      authtoken: token,
    },
  });
};

export const getSpotlightTechnologies = (params) => {
  return axios.post(`${baseUrl}/api/post/searchTechnologies`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const listUser = (params) => {
  return axios.post(`${baseUrl}/api/admin/listUsers`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadFile = (params) => {
  return axios.post(`${baseUrl}/api/common/uploadFile`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const forgotPassword = (params) => {
  return axios.post(`${baseUrl}/api/common/sendOTP`, params);
};

export const verifyOTP = (params) => {
  return axios.post(`${baseUrl}/api/common/verifyOTP`, params);
};

export const changePassword = (params) => {
  return axios.post(`${baseUrl}/api/user/changePassword`, params);
};

export const getNotification = (params, token) => {
  return axios.post(`${baseUrl}/api/user/notifications`, params, {
    headers: {
      authtoken: token,
    },
  });
};
export const markReadNotification = (params, token) => {
  return axios.post(`${baseUrl}/api/user/notifications/markRead`, params, {
    headers: {
      authtoken: token,
    },
  });
};

export const addRequest = (params, token) => {
  return axios.post(`${baseUrl}/api/post/addRequest`, params, {
    headers: {
      authtoken: token,
    },
  });
};

export const AddComments = (params, token) => {
  return axios.post(`${baseUrl}/api/post/topic/addComment`, params, {
    headers: {
      authtoken: token,
    },
  });
};

export const getComments = (params) => {
  return axios.post(`${baseUrl}/api/post/comments`, params);
};

export const webIntroDone = (params) => {
  return axios.post(`${baseUrl}/api/user/webIntro`, params);
};

export const deleteComments = (commentId, token) => {
  return axios.get(`${baseUrl}/api/post/comment/delete/${commentId}`, {
    headers: {
      authtoken: token,
    },
  });
};

export const upVotes = (commentId, token) => {
  return axios.post(`${baseUrl}/api/post/comment/upvote/${commentId}`, {}, {
    headers: {
      authtoken: token,
    },
  });
};

export const topicUpVotes = (topicId, token) => {
  return axios.post(`${baseUrl}/api/post/topic/upvote/${topicId}`, {}, {
    headers: {
      authtoken: token,
    },
  });
};

export const uploadMedia = (formData) => {
  const url = `${baseUrl}/api/common/uploadFile`;
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  return axios.post(url, formData, { headers: headers })
};

export const addTopic = (params, token) => {
  return axios.post(`${baseUrl}/api/post/addTopic`, params, {
    headers: {
      authtoken: token,
      "Content-Type": "application/json",
    },
  });
};

export const addTechnology = (params, token) => {
  return axios.post(`${baseUrl}/api/post/addTechnology`, params, {
    headers: {
      authtoken: token,
      "Content-Type": "application/json",
    },
  });
};

export const updateProfile = (params, token) => {
  return axios.post(`${baseUrl}/api/user/updateProfile`, params, {
    headers: {
      authtoken: token,
      "Content-Type": "application/json",
    },
  });
};
