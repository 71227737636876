import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getTopicList } from "../services";
import { imgPath } from "../services";
import { Link } from "react-router-dom";
import Spinner from "./common/Spinner";
import GoogleAds from "./GoogleAds";

function TrendingTopicList() {
    const [topicList, setTopicList] = useState([]);

    let userData = localStorage.getItem("user");
    userData = JSON.parse(userData);
    const userTypeData = userData?.userType;
    const [user, setUser] = useState(userData);
    const [isLoading, setLoading] = useState(true);

    const getTopicListCall = async (searchKey = "") => {
        let params = { pageSize: 1000, pageNumber: 1, status: "published" };
        let response = await getTopicList(params);
        setLoading(false);
        let data = response.data?.data;
        setTopicList(data);
    };

    useEffect(() => {
        getTopicListCall();
    }, []);

    return (
        <main className="main-container topic-list-page">
            {isLoading && <div className="page-spinner"><div className="white-box"><Spinner /></div></div>}
            <section className="topic-listing-panel mb-50">
                <Container>
                    <div className="sub-title d-flex align-items-center justify-content-between">
                        <h2 className="text-capitalize">Trending Topics</h2>
                    </div>
                    <Row>
                        {topicList?.records ? (
                            topicList?.records.map((list) => (
                                <Col lg={4} md={6} sm={6} className="mb30">
                                    <div className="topic-item height100">
                                        <div className="topic-pic">
                                            <video
                                                src={imgPath + list.introVideo}
                                                width="100"
                                                height="200"
                                            >
                                            </video>
                                        </div>
                                        <div className="topic-info">
                                            <Link to={`/topic-detail/${list.id}/${list.user_id}`} className="share">
                                                <i className="fas fa-share-alt"></i>
                                            </Link>
                                            <h4 className="text-uppercase">
                                                <Link to={`/topic-detail/${list.id}/${list.user_id}`}>{list.name}</Link>
                                            </h4>
                                            <p>
                                                {list.firstName} {list.lastName},{" "}
                                            </p>
                                            <p className="text-capitalize">{list.contributorType}</p>
                                            <div className="topic-action">
                                                <div className="topic-action-left">
                                                    <Link
                                                        to={`/topic-detail/${list.id}/${list.user_id}`}
                                                        className="btn btn-blue btn-sm text-capitalize mr-1"
                                                    >
                                                        Read More
                                                    </Link>                                                   
                                                </div>

                                                <span className="btn btn-gray-light btn-sm btn-radius20">
                                                    <i className="fas fa-arrow-up text-blue"></i>{" "}
                                                    {list.upvotes}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            ))
                        ) : (
                            <div className="no-record-found text-center mb-3">No Record Found</div>
                        )}
                    </Row>
                    <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
                </Container>
            </section>
        </main>
    );
}

export default TrendingTopicList;
