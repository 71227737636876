import React, { useEffect, useState } from 'react'
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from 'swiper';
import { Link, useLocation, useParams } from "react-router-dom";
import { getHome, imgPath, topicUpVotes, getTopicDetail } from "../services";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';

function SpotlightTechnologiesDetail() {

  const [data, setTopicDetails] = useState(null);
  let userData = localStorage.getItem("user");
  userData = JSON.parse(userData);
  const userId = userData?.id;
  const token = userData?.auth?.token;
  const userType = userData?.userType;

  const params = useParams();

  const fetchData = useLocation();

  const descriptionData = fetchData.state.description;
  const topicId = fetchData.state.topic_id;
  const descriptionText = descriptionData.replace(/(?:\r\n|\r|\n)/g, "<br />")
  const [topicData, setTopicData] = useState();

  console.log('fetched data', fetchData)
  useEffect(() => {
    getHome()
      .then((response) => {
        let data = response.data?.data;
        setTopicData(data?.trendingTopics);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getTopicDetailCall = async () => {
    let response = await getTopicDetail(topicId, token);
    // console.log("response topic detail", response);
    let data = response.data?.data;
    setTopicDetails(data);
  };

  useEffect(() => {
    getTopicDetailCall();
  }, []);

  const handleTopicUpVote = (e, topicId) => {
    topicUpVotes(topicId, token)
      .then((response) => {
        getTopicDetailCall();
        // console.log("resposce", response)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getExtension = (filename) => {
    //const parts = filename.split('.');
    const parts = filename.split(/\.(?=[^\.]+$)/);

    return parts[parts.length - 1];
  };
  const isVideoFormat = (filename) => {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mpeg":
      case "mp4":
      case "webm":
      case "mkv":
      case "flv":
        // etc
        return true;
    }
    return false;
  };

  const isValidLinkURL = (url) => {
    const pattern = /^((http|https|ftp):\/\/)/;
    let link = url;
    if (!pattern.test(url)) {
      link = "http://" + url;
    }

    return link;
  };


  const shareUrl = window.location.href;

  return (
    <main className="main-container spotlight-detail-page">
      <Container>
        <Row>
          <Col lg={9}>
            <div className="white-box">
              <div className="sub-title">
                <h2 className="text-uppercase">SPOTLIGHT TECHNOLOGY</h2>
              </div>
              {/* <Row>
                <Col md={6}>
                  <div className="video-panel topic-info-video">
                    <div className="video-item">
                      <video width="100%" height="200" controls>
                        <source src="" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </Col>
              </Row> */}
              <div className="spotlight-technology-view">
                <h3 className="text-uppercase">{fetchData?.state?.name}</h3>
                <h4>For <span className="text-blue">{fetchData?.state?.topicName}</span></h4>
                <p dangerouslySetInnerHTML={{ __html: descriptionText }}></p>
                <div className="topic-info-gallery">
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={3}
                    navigation
                    modules={[Navigation, Pagination]}
                    pagination={{ clickable: true }}
                    breakpoints={{
                      769: {
                        slidesPerView: 3,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      576: {
                        slidesPerView: 2,
                      },
                      320: {
                        slidesPerView: 1,
                      },
                    }}
                  >

                    {
                      Object.keys(fetchData?.state?.media).map(
                        (x, i) => {
                          return (
                            <SwiperSlide key={i}>
                              <div className="topic-gallery-image">
                                {fetchData?.state?.media[x] !==
                                  null &&
                                  fetchData?.state?.media[x] !==
                                  "" ? (
                                  isVideoFormat(
                                    fetchData?.state?.media[x]
                                  ) ? (
                                    <div className="topic-gallery-video">
                                      <video
                                        width="100%"
                                        height="230"
                                        controls
                                      >
                                        <source
                                          src={
                                            imgPath +
                                            fetchData?.state?.media[x]
                                          }
                                          type="video/mp4"
                                        />
                                      </video>
                                    </div>
                                  ) : (
                                    <a
                                      href={
                                        imgPath +
                                        fetchData?.state?.media[
                                        x
                                        ]
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={
                                          imgPath +
                                          fetchData?.state?.media[x]
                                        }
                                        alt="medcraze"
                                      />
                                    </a>
                                  )
                                ) : (
                                  <img
                                    src="/static/images/placeholder.jpg"
                                    alt="placeholder"
                                    layout="fill"
                                  />
                                )}
                              </div>
                            </SwiperSlide>
                          );
                        }
                      )
                    }

                  </Swiper>

                </div>
                {Object.keys(fetchData?.state?.sources).length > 0 ?
                  <div className="mb-3 source-panel">
                    <h5>Sources:</h5>
                    {
                      Object.values(fetchData?.state?.sources).map((sourseItem) => (
                        <p><a href={sourseItem} target="_blank" rel="noreferrer">{sourseItem}</a></p>
                      ))
                    }
                  </div>
                  :
                  ''
                }

                {Object.keys(fetchData?.state?.caseStudies).length > 0 ?
                  <div className="mb-3 view-pdf-list">
                    <h5>Case Studies:</h5>
                    <ul className="list-style-none">
                      {
                        Object.values(fetchData?.state?.caseStudies).map((caseStudyItem) => (
                          <li>
                            <a href="javascript:void(0);" className="pdf-link">
                              <i className="far fa-file-pdf me-1"></i> {caseStudyItem}
                            </a>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                  :
                  ''
                }
              </div>
              {fetchData?.state?.manufactureLink.length > 0 ?
                <div className="spotlight-tech-action mb-3 text-end">
                  <a href={isValidLinkURL(fetchData?.state?.manufactureLink)} target="_blank" rel="noreferrer" className="btn btn-blue btn-sm btn-radius20 btn-yellow ms-1">Learn More</a>
                </div>
                :
                ''
              }


              <div className="spotlight-tech-social mb-3">
                <div className="spotlight-view">
                  <a className="btn btn-gray-light btn-sm btn-radius20" onClick={(e) => { handleTopicUpVote(e, fetchData?.state?.topic_id) }}>
                    <i className="fas fa-arrow-up text-blue"></i>{" "}
                    {data?.upvotes}
                  </a>
                </div>
                <ul className="list-style-none">
                  <li>
                    <FacebookShareButton
                      url={shareUrl}
                      quote={
                        `We have a diagnoses topic ${fetchData?.state?.name} for you on MEDcraze.
                        Download the app now. 
                        iOS: https://apps.apple.com/us/app/medcraze/id1638147946
                        Android: https://play.google.com/store/apps/details?id=com.medcraze`
                      }
                      className="Demo__some-network__share-button"
                    >
                      <a className="fb">
                        <i className="fab fa-facebook-square"></i>
                      </a>
                    </FacebookShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton title={fetchData?.state?.name} url={shareUrl} className="Demo__some-network__share-button">
                      <a className="linkedin">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </LinkedinShareButton>
                  </li>
                  <li>
                    <TwitterShareButton
                      url={shareUrl}
                      title={
                        `We have a diagnoses topic ${fetchData?.state?.name} for you on MEDcraze.
                        Download the app now. 
                        iOS: https://apps.apple.com/us/app/medcraze/id1638147946
                        Android: https://play.google.com/store/apps/details?id=com.medcraze`
                      }
                      className="Demo__some-network__share-button"
                    >
                      <a className="twitter">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </TwitterShareButton>

                  </li>
                  <li>
                    <CopyToClipboard text={window.location.href}>
                      <a className="copy">
                        <i className="far fa-copy"></i>
                      </a>
                    </CopyToClipboard>

                  </li>
                </ul>
              </div>

            </div>

          </Col>
          <Col lg={3}>
            <aside className="sidebar">
              <h3>Trending on MEDcraze</h3>
              {topicData?.slice(0, 4).map((topic) => (
                <div className="sidebar-trending-item" key={topic.id}>
                  <Link to={`/topic-detail/${topic.id}/${topic.user_id}`}>
                    <figure>
                      <video playsInline width="100%" height="200">
                        <source
                          src={imgPath + topic.introVideo}
                          type="video/mp4"
                        />
                      </video>
                      <figcaption className="single-line-ellipsis">
                        {topic.name}
                      </figcaption>
                    </figure>
                  </Link>
                </div>
              ))}
            </aside>
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default SpotlightTechnologiesDetail