import React, { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import { userSignup, signIn, getNotification, imgPath } from "../services";
import ContributorType from "../components/contributor/ContributorType";
import { Link, NavLink } from "react-router-dom";
import ContributorSignup from "../components/contributor/ContributorSignup";
import ForgotPassword from "../components/forgotPassword/ForgotPassword";
import VerifyOTP from "../components/forgotPassword/VerifyOTP";
import ResetPassword from "../components/forgotPassword/ResetPassword";
import { useNavigate } from 'react-router-dom';
import Emitter from "../components/common/Emitter";
import EventName from "../components/common/EventName";
import Spinner from "../components/common/Spinner";

function Header() {
  let userData = localStorage.getItem("user");
  const [count, setCount] = useState(0);

  userData = JSON.parse(userData);
  const userId = userData?.id;
  const userProfile = userData?.profileMedia;
  const token = userData?.auth?.token;
  const userTypeData = userData?.userType;

  const [show, setShow] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [userTypePanel, setUserTypePannel] = useState("block");
  const [consumerTypePanel, setConsumerTypePannel] = useState("none");
  const [contributorTypePanel, setContributorTypePannel] = useState("none");
  const [contributorClinicianPanel, setContributorClinicianPanel] =
    useState("none");
  const [contributorIndustryRepPanel, setContributorIndustryRepPanel] =
    useState("none");
  const [nextButtonPanel, setNextButtonPannel] = useState("none");
  const [showSignIn, setShowSignIn] = useState(false);
  const [forgotPasswordPanel, setForgotPasswordPanel] = useState("none");
  const [OTPModelPanel, setOTPModelPanel] = useState("none");
  const [resetPasswordPanel, setResetPasswordPanel] = useState("none");
  const [changedPasswordPanel, setChangedPasswordPanel] = useState("none");
  const [signInPanel, setSignInPanel] = useState("block");
  const [user, setUser] = useState(userData);
  const [loginUser, setLoginUser] = useState();
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [userDetail, setUserDetailAfterSignUp] = useState(null);  

  const [value, setValue] = useState();
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    setShowSignIn(false);
  };
  const handleSignInRoute = () => {
    setShow(false);
    navigate('/');
  }
  const handleShow = () => {
    setShowSignIn(false);
    setShow(true);
    setUserTypePannel("block");
    setConsumerTypePannel("none");
    setContributorTypePannel("none");
    setContributorClinicianPanel("none");
    setContributorIndustryRepPanel("none");
    setNextButtonPannel("none");
    setAlertMessage("");
  };

  const handleSignIn = () => {
    setShowSignIn(true);
    setAlertMessage("");
    setSignInPanel("block");
    setForgotPasswordPanel("none");
    setContributorClinicianPanel("none");
    setContributorTypePannel("none");
    setConsumerTypePannel("none");
    setNextButtonPannel("none");
    setChangedPasswordPanel("none");
    setOTPModelPanel("none");
    setResetPasswordPanel("none");
  };

  const handleSignUpContributor = () => {
    setContributorTypePannel("none");
    setContributorClinicianPanel("block");
  };

  const handleContributorNextPanel = (display, data) => {
    if (display === "block") {
      setContributorClinicianPanel("none");
    }

    if (display === "none") {
      setContributorClinicianPanel("block");
    }
    setUserDetailAfterSignUp(data);
    setNextButtonPannel(display);
  };

  const handleOTPModelPanel = (display, values) => {
    if (display === "block") {
      setForgotPasswordEmail(values.email);
      setForgotPasswordPanel("none");
    }

    if (display === "none") {
      setForgotPasswordPanel("block");
    }
    setOTPModelPanel(display);
  };

  const handleResetPasswordPanel = (display) => {
    if (display === "block") {
      setOTPModelPanel("none");
    }

    if (display === "none") {
      setOTPModelPanel("block");
    }
    setResetPasswordPanel(display);
  };

  const handleChangedPasswordPanel = (display) => {
    if (display === "block") {
      setResetPasswordPanel("none");
    }

    if (display === "none") {
      setResetPasswordPanel("block");
    }
    setChangedPasswordPanel(display);
  };

  const handleLogout = () => {
    setUser("");
    localStorage.clear();
    navigate('/');
    window.location.reload(false);
  };

  const handleChangeUserType = (value) => {
    setSelectedUserType(value.contributorType);
  };

  const handleClick = (event, value) => {
    setUserTypePannel("none");
    if (value === "consumer") {
      setConsumerTypePannel("block");
    }
    if (value === "contributor") {
      setContributorTypePannel("block");
    }
    if (value === "consumerNext") {
      setNextButtonPannel("block");
    }
    if (value === "forgotPassword") {
      setSignInPanel("none");
      setForgotPasswordPanel("block");
    }
    if (value === "contributorClincian") {
      setContributorTypePannel("block");
    }
    if (value === "contributorIndustryRep") {
      setContributorClinicianPanel("block");
    }
    if (value === "contributor") {
      setContributorTypePannel("block");
    }
    event.preventDefault();
  };

  // const phoneRegExp =
  // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const SignUpValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    // phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    password: Yup.string()
      // .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    consumerTerms: Yup.bool()
      .required()
      .oneOf([true], "Please check this mark if you want to proceed."),
  });

  const SignInValidationSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      // .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const phoneNumberFormat = (phone) => {
    phone = phone.replace(/\D/g, '');
    phone = phone.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    const size = phone.length;
    if (size == 0) {
      phone = phone;
    } else if (size < 4) {
      phone = '(' + phone;
    } else if (size < 7) {
      phone = '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6);
    } else {
      phone = '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + '-' + phone.substring(6, 10);
    }

    return phone;
  }

  const phoneFormat = (phone) => {
    setValue(phoneNumberFormat(phone));
  }

  const getNotificationItems = async () => {
    const params = { "pageSize": 300, "pageNumber": 1, "user_id": userId };

    const response = await getNotification(params, token);
    if (response && response.data.type == 'success') {

      if (response.data.data.records.filter((value) => value.isRead == '0').length > 0) {
        document.getElementById('notification-unread-count').className = 'd-block text-center';
        setCount(response.data.data.records.filter((value) => value.isRead == '0').length);
      }

    }
  }

  const useOnceCall = (cb, condition = true) => {
    const isCalledRef = useRef(false);
    useEffect(() => {
      if (condition && !isCalledRef.current) {
        isCalledRef.current = true;
        cb();
      }
      Emitter.on(EventName.SIGNUP_MODAL, someFunction);
      return () => {
        Emitter.off(EventName.SIGNUP_MODAL, someFunction);
      };
    }, [cb, condition]);
  }

  const someFunction = (data) => {
    setShow(true)
  }

  useOnceCall(() => {
    getNotificationItems();
  });

  const handleSignupComplete = () => {
    setShow(false);
    navigate('/');
  }

  const consumerSignup = async (values) => {
    setLoading(true)
    let response = await userSignup(values);
    console.log('response consumerSignup', values, response);
    if (response.data?.type == "success") {
      // let message = response?.data?.message;

      // if (message) {
      //   await userSignin(values);
      //   setAlertShow(true);
      //   setAlertMessage(message);

      //   setLoading(false)
      // }
      const obj = {email: values.email, password: values.password};
      setUserDetailAfterSignUp(obj);
      setLoading(false)
      setNextButtonPannel("block");
      setUserTypePannel("none");
      setConsumerTypePannel("none");
      setContributorTypePannel("none");

    } else {
      setUserDetailAfterSignUp(null);
      setLoading(false)
      let message = response?.data?.message;
      if (message) {
        setAlertShow(true);
        setAlertMessage(message);
      }
    }
  }

  const userSignin = (values) => {
    signIn(values, (response) => {
        if(response.type === 'success') {
            setNextButtonPannel("none");
            localStorage.setItem("user", JSON.stringify(response.data));
            setUser(localStorage.getItem("user"));
            setLoginUser(response.data)
            handleClose();            
            Emitter.emit(EventName.SHOW_SEARCHES);
        } else {
            setAlertShow(true);
            setAlertMessage(response.message);            
        }
    });    
  }

  const closeAllModal = () => {
    handleClose(); 
    setNextButtonPannel("none");
  }
  
  return (
    <>
      {isLoading && <div className="page-spinner" style={{zIndex: "999"}}><div className="white-box"><Spinner /></div></div>}
      <header className="header">
        <Navbar expand="lg">
          <Container>
            <Link to="/" className="navbar-brand">
              <img src="/static/images/logo.png" alt="Medcraze" />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="top-menu">
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <NavLink to="/topic-list" className="nav-link">
                    {(user == " " || user == null || user == undefined || userTypeData == "consumer") ? (
                      <>Topics</>
                    )
                      :
                      (
                        <>My Topics</>
                      )
                    }
                  </NavLink>
                  <NavLink to="/spotlighttech-list" className="nav-link">
                    Browse Technologies
                  </NavLink>
                  <NavLink to="/about" className="nav-link">
                    About Us
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
              <div className="top-menu-action">
                {user == " " || user == null || user == undefined ? (
                  <div className="login-action">
                    <Nav.Link
                      href="#"
                      className="signin-btn"
                      onClick={handleSignIn}
                    >
                      Sign In
                    </Nav.Link>
                    <Nav.Link
                      href="#"
                      className="signup-btn"
                      onClick={handleShow}
                    >
                      Sign Up
                    </Nav.Link>
                  </div>
                ) : (
                  <>

                    <div className="notification-icon">
                      <Link to="/notification">
                        <span>
                          <img
                            src="/static/images/notification-icon.png"
                            alt="notification"
                          />
                        </span>

                        <b id='notification-unread-count' className="badge text-center d-none">{count}</b>
                      </Link>
                    </div>


                    <div className="user-profie" style={{ display: "block" }}>
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          {userProfile ?
                            <img
                              src={imgPath + userProfile} alt="User Profile"
                            />
                            :
                            <img
                              src="/static/images/profile-placeholder.png"
                              alt="User Profile"
                            />
                          }
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item><Link to="/my-profile">My Profile</Link></Dropdown.Item>
                          <Dropdown.Item onClick={handleLogout}>
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Container>
        </Navbar>
      </header>

      {/* Remove Action Button Start */}
      {/* <div className="signup-action-remove mb-3">
        <Button variant="primary" onClick={handleShow}>
          Sign Up modal
        </Button>
      </div> */}
      {/* Remove Action Button End */}

      {/* Sign Up Modal Start Here */}

      <Modal show={show} onHide={handleClose} dialogClassName="signup-modal">
        <Modal.Body>
          <div className="user-type-panel" style={{ display: userTypePanel }}>
            <Modal.Header closeButton>
              <Modal.Title>Choose User Type</Modal.Title>
            </Modal.Header>
            <div className="user-type-list">
              <ul>
                <li>
                  <Nav.Link
                    href="javascript:void(0);"
                    onClick={(event) => handleClick(event, "consumer")}
                  >
                    <h4>Consumer</h4>
                    <p>
                      I would like to search for medical technologies that
                      address a diagnosis
                    </p>
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link
                    href="javascript:void(0);"
                    onClick={(event) => handleClick(event, "contributor")}
                  >
                    <h4>Contributor</h4>
                    <p>
                      I am a Clinician or Medical Company Representative and
                      would like to contribute information about the latest
                      medical technologies to help consumers
                    </p>
                  </Nav.Link>
                </li>
              </ul>
            </div>
          </div>
          {/* Consumer Type Signup Panel Start */}
          <div
            className="consumer-type-signup-panel"
            style={{ display: consumerTypePanel }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Sign Up</Modal.Title>
            </Modal.Header>

            {alertMessage && (
              <Alert
                variant="danger"
                onClose={() => {
                  setAlertShow(false);
                  setAlertMessage("");
                }}
                dismissible
              >
                <p>{alertMessage}</p>
              </Alert>
            )}

            <Formik
              validationSchema={SignUpValidationSchema}
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                userType: "consumer",
                password: "",
                confirmPassword: "",
                consumerTerms: false,
              }}
              onSubmit={(values) => {

                if (values.phone == "") {
                  values.phone = value;
                }

                consumerSignup(values);
              }}
            >
              {({ handleChange, handleSubmit, touched, values, errors }) => (
                <Form
                  className="consumer-signup-form"
                  noValidate
                  onSubmit={handleSubmit}
                  method="post"
                >
                  <Row>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Control
                          type="text"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          isInvalid={touched.firstName && !!errors.firstName}
                          placeholder="First Name *"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Control
                          type="text"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          isInvalid={touched.lastName && !!errors.lastName}
                          placeholder="Last Name *"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Control
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={touched.email && !!errors.email}
                          placeholder="Email *"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Control
                          type="tel"
                          name="phone"
                          value={value}
                          onChange={(e) => { phoneFormat(e.target.value); handleChange(e); }}
                          isInvalid={touched.phone && !!errors.phone}
                          placeholder="Phone Number *"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>

                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={touched.password && !!errors.password}
                          placeholder="Password *"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                          placeholder="Confirm Password *"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmPassword}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Check type="checkbox" id="">
                          <Form.Check.Input
                            type="checkbox"
                            required
                            name="consumerTerms"
                            value={values.consumerTerms}
                            onChange={handleChange}
                            isInvalid={!!errors.consumerTerms}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.consumerTerms}
                          </Form.Control.Feedback>
                          <Form.Check.Label>
                            I have read and agree to MEDcraze’s{" "}
                            <Link to="/terms-of-use" target="_blank">
                              Terms & Conditions
                            </Link>{" "}
                            &{" "}
                            <Link to="/privacy-policy" target="_blank">
                              Privacy Policy
                            </Link>
                          </Form.Check.Label>
                        </Form.Check>

                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <div className="modal-action-btns">
                          <Button type="submit" className="btn-blue w-100">
                            Sign Up
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
          {/* Consumer Type Signup Panel End */}

          {/* Consumer Type Signup Next Button Panel Start need to design */}
          <div className="next-btn-panel" style={{ display: nextButtonPanel }}>
            <div className="unique-modal-panel">
              <div className="d-flex justify-content-end">
                  <button type="button" class="btn-close" aria-label="Close" 
                    onClick={() => closeAllModal()} />
              </div>
              <div className="modal-medcraze-logo">
                <img src="/static/images/logo-lg.png" alt="Medcraze" />
              </div>
              <div className="modal-hand-logo">
                <span>
                  <img src="/static/images/hand-icon.png" alt="Medcraze" />
                </span>
              </div>
              <div className="unique-info">
                <p>
                  MEDcraze is a unique online destination built by healthcare
                  providers and you, the consumer.
                </p>
              </div>
              <div className="modal-action-btns">
                <Button
                  type="button"
                  onClick={() => userSignin(userDetail)}
                  className="btn-blue"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
          {/* Consumer Type Signup Next Button Panel end */}

          {/* Contributor Type Signup Panel Start */}
          <div
            className="contributor-type-signup-panel"
            style={{ display: contributorTypePanel }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Sign Up</Modal.Title>
            </Modal.Header>

            <ContributorType
              handleChangeUserType={handleChangeUserType}
              handleSignUpContributor={handleSignUpContributor}
            />
          </div>
          {/* Contributer Clinician Signup form */}
          <div
            className="contributor-type-signup-panel"
            style={{ display: contributorClinicianPanel }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Sign Up</Modal.Title>
            </Modal.Header>

            <ContributorSignup
              selectedUserType={selectedUserType}
              handleContributorNextPanel={(display, data) => handleContributorNextPanel(display, data)}
            />
          </div>
          {/* End Contributer Clinician Signup form */}
        </Modal.Body>
      </Modal>

      {/* Sign In Modal Start Here */}

      <Modal
        show={showSignIn}
        onHide={handleClose}
        dialogClassName="signin-modal"
      >
        <Modal.Body>
          <div className="signin-modal-panel" style={{ display: signInPanel }}>
            <Modal.Header closeButton>
              <Modal.Title>Sign In</Modal.Title>
            </Modal.Header>
            {alertMessage && (
              <Alert
                variant="danger"
                onClose={() => {
                  setAlertShow(false);
                  setAlertMessage("");
                }}
                dismissible
              >
                <p>{alertMessage}</p>
              </Alert>
            )}
            <div className="signin-form">
              <Formik
                validationSchema={SignInValidationSchema}
                onSubmit={(values) => {
                 userSignin(values)
                }}
                initialValues={{
                  email: "",
                  password: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="form-group">
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                        placeholder="Email *"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                        placeholder="Confirm Password *"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="form-group forgot-pass text-end ">
                      <a
                        href="javascript:void(0);"
                        onClick={(event) =>
                          handleClick(event, "forgotPassword")
                        }
                      >
                        Forgot Password?
                      </a>
                    </Form.Group>
                    <Form.Group className="form-group ">
                      <div className="modal-action-btns">
                        <Button type="submit" className="btn-blue w-100" onClick={handleSignInRoute}>
                          Sign In
                        </Button>
                      </div>
                    </Form.Group>
                    <Form.Group className="form-group no-acc text-center">
                      Don't have account?{" "}
                      <a href="javascript:void(0);" onClick={handleShow}>
                        Sign Up
                      </a>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <div
            className="forgot-modal-panel"
            style={{ display: forgotPasswordPanel }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <div className="forgot-pass-form">
              <p className="send-otp-text">
                We will send you a One Time Password on your email
              </p>
              <ForgotPassword
                handleSignIn={handleSignIn}
                handleOTPModelPanel={handleOTPModelPanel}
              />
            </div>
          </div>

          <div className="otp-modal-panel" style={{ display: OTPModelPanel }}>
            <Modal.Header closeButton>
              <Modal.Title>Enter OTP</Modal.Title>
            </Modal.Header>
            <div className="forgot-pass-form">
              <p className="send-otp-text">
                Enter verification code we have sent to your email address.
              </p>
              <VerifyOTP
                forgotPasswordEmail={forgotPasswordEmail}
                handleResetPasswordPanel={handleResetPasswordPanel}
              />
            </div>
          </div>

          <div
            className="reset-password-panel"
            style={{ display: resetPasswordPanel }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>
            <div className="forgot-pass-form">
              <p className="send-otp-text">Set your new password</p>
              <ResetPassword
                forgotPasswordEmail={forgotPasswordEmail}
                handleChangedPasswordPanel={handleChangedPasswordPanel}
              />
            </div>
          </div>

          <div
            className="changed-password-panel"
            style={{ display: changedPasswordPanel }}
          >
            <div className="forgot-pass-form">
              <p className="send-otp-text">
                Your password has been changed successfully
              </p>
              <Button
                type="submit"
                className="btn-blue w-100"
                onClick={handleSignIn}
              >
                Okay
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
