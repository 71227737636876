import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import { Route, Link, Routes, useParams } from "react-router-dom";
import {
  getTopicDetail,
  getFetchProfile,
  getHome,
  getComments,
  deleteComments,
  upVotes,
  topicUpVotes,
} from "../services";
import { imgPath } from "../services";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AddComment from "./topicDetails/AddComment";
import { fileUrl } from "../constants";
import * as helper from "../helper";
import ReplyComment from "./topicDetails/ReplyComment";
import ListReplyComments from "./topicDetails/ListReplyComments";
import GuestUser from "./GuestUser";
//Need token for api

function TopicDetails() {
  let userData = localStorage.getItem("user");
  userData = JSON.parse(userData);
  const userId = userData?.id;
  const token = userData?.auth?.token;
  const userType = userData?.userType;
  const userImage = userData?.profileMedia;

  const params = useParams();
  const topicId = params?.id;
  const topicUserId = params?.user_id;

  const [data, setTopicDetails] = useState(null);
  const [profileData, setProfileData] = useState([]);
  const [user, setUser] = useState(userData);
  const [toggleReplied, setToggleReplied] = useState("");
  const [topicData, setTopicData] = useState();
  const [commentsData, setCommentsData] = useState();
  const [showReply, setShowReply] = useState("");
  const [isActive, setActive] = useState(false);
  const [guestUser, setGuestUser] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [UsrProfileData, setUsrProfileData] = useState([]);
  const [showDelectComment, setShowDelectComment] = useState(false);
  const handleDelectCommentClose = () => setShowDelectComment(false);
  const handleDelectCommentShow = () => setShowDelectComment(true);

  const [showUserDetail, setShowUserDetail] = useState(false);
  const handleUserDetailClose = () => setShowUserDetail(false);

  const shareUrl = window.location.href;

  const handleCommentReply = (e, i) => {
    setShowReply(i);
    setActive(!isActive);
  };

  const guestUserAlert = () => {
    setGuestUser(!guestUser);
  }

  const guestUserShow = () => {
    setGuestUser(true);
  }


  const handleCommentDelete = (e, commentId) => {
    deleteComments(commentId, token)
      .then((response) => {
        let data = response.data?.data;
        getCommentsData();
        handleDelectCommentClose(true);
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const handleUpVote = (e, commentId) => {
    upVotes(commentId, token)
      .then((response) => {
        let data = response.data?.data;
        getCommentsData();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleTopicUpVote = (e, topicId) => {
    topicUpVotes(topicId, token)
      .then((response) => {
        getTopicDetailCall();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    getHome()
      .then((response) => {
        let data = response.data?.data;
        setTopicData(data?.trendingTopics);
      })
      .catch((error) => {
        console.error(error);
      });

    getCommentsData();
  }, []);

  const getCommentsData = () => {
    const commentsParam = {
      type: "main",
      pageSize: 100,
      pageNumber: 1,
      topic_id: topicId,
      // filter: userType,
    };

    getComments(commentsParam)
      .then((response) => {
        // console.log("commentsData : ", response?.data?.data);

        setCommentsData(response?.data?.data);
      })
      .catch((error) => {
        console.error("getComments ", error);
      });
  };

  const getTopicDetailCall = async () => {
    let response = await getTopicDetail(params?.id, token);
    // console.log("response topic detail", response);
    let data = response.data?.data;
    setTopicDetails(data);
  };
  const getFetchProfileCall = async () => {
    let response = await getFetchProfile(topicUserId, token);
    // console.log("response ProfileData", response);
    let data = response.data?.data;
    setProfileData(data);
  };
  useEffect(() => {
    getTopicDetailCall();
  }, []);
  useEffect(() => {
    getFetchProfileCall();
  }, []);

  const getExtension = (filename) => {
    //const parts = filename.split('.');
    const parts = filename.split(/\.(?=[^\.]+$)/);

    return parts[parts.length - 1];
  };
  const isVideoFormat = (filename) => {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mpeg":
      case "mp4":
      case "webm":
      case "mkv":
      case "flv":
        // etc
        return true;
    }
    return false;
  };

  const isValidLinkURL = (url) => {
    const pattern = /^((http|https|ftp):\/\/)/;
    let link = url;
    if (!pattern.test(url)) {
      link = "http://" + url;
    }

    return link;
  };

  const handleUserDetailShow = (e) => {
    setShowUserDetail(true);
    const userProfileId = e.currentTarget.id;
    // console.log(userProfileId)

    getFetchProfile(userProfileId, token)
      .then((response) => {
        setUsrProfileData(response.data.data);
        // console.log(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const toggleRepliedView = () => {
    // console.log("clicked")
    setToggleReplied(!toggleReplied)
  }

  return (
    <main className="main-container topic-detail-page">
      <GuestUser alertGuestUser={guestUserAlert} toggleAlert={guestUser} />
      <Container>
        <Row className="mb-50">
          {/* Left Side Panel Start */}
          <Col lg={9}>
            <div className="white-box">
              <div className="sub-title">
                <h2 className="text-uppercase">{data?.name}</h2>
              </div>
              <div className="topic-profile">
                <a id={data?.user_id} className="cursor-pointer" onClick={handleUserDetailShow}>
                  <figure>
                    {data?.profileMedia ? (
                      <img src={imgPath + data?.profileMedia} alt="Medcraze" />
                    ) : (
                      <img
                        src="/static/images/profile-placeholder.png"
                        alt="Medcraze"
                      />
                    )}
                  </figure>
                </a>
                <div className="topic-profile-info">
                  <h6>
                    {data?.firstName} {data?.lastName}
                  </h6>
                  <p className="text-capitalize">{profileData?.contributorDetails?.contributorType}</p>
                </div>
              </div>
              <Row>
                <Col md={6}>
                  <div className="video-panel topic-info-video">
                    <div className="video-item">
                      {data?.introVideo ? (
                        <video controls>
                          <source
                            src={imgPath + data?.introVideo}
                            type="video/mp4"
                          />
                        </video>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="topic-info-tab">
                <span className="tab-name-title">Evolution Of: </span>

                <Tabs
                  defaultActiveKey="tech"
                  transition={false}
                  className="mb-3 nav"
                >
                  <Tab eventKey="tech" title="TECHNOLOGIES">
                    {/* Tab data */}
                    <div>
                      <h3 className="text-uppercase text-blue">
                        {data?.evolutionTechnologies?.title}
                      </h3>
                      <p>{data?.evolutionTechnologies?.description}</p>

                      <div className="topic-info-gallery">
                        <Swiper
                          spaceBetween={20}
                          slidesPerView={3}
                          navigation
                          modules={[Navigation, Pagination]}
                          pagination={{ clickable: true }}
                          breakpoints={{
                            769: {
                              slidesPerView: 3,
                            },
                            768: {
                              slidesPerView: 2,
                            },
                            576: {
                              slidesPerView: 2,
                            },
                            320: {
                              slidesPerView: 1,
                            },
                          }}
                        >
                          {data !== null &&
                            data.evolutionTechnologies.media &&
                            Object.keys(data.evolutionTechnologies.media).map(
                              (x, i) => {
                                return (
                                  <SwiperSlide key={i}>
                                    <div className="topic-gallery-image">
                                      {data.evolutionTechnologies.media[x] !==
                                        null &&
                                        data.evolutionTechnologies.media[x] !==
                                        "" ? (
                                        isVideoFormat(
                                          data.evolutionTechnologies.media[x]
                                        ) ? (
                                          <div className="topic-gallery-video">
                                            <video
                                              width="100%"
                                              height="230"
                                              controls
                                            >
                                              <source
                                                src={
                                                  imgPath +
                                                  data.evolutionTechnologies
                                                    .media[x]
                                                }
                                                type="video/mp4"
                                              />
                                            </video>
                                          </div>
                                        ) : (
                                          <a
                                            href={
                                              imgPath +
                                              data.evolutionTechnologies.media[
                                              x
                                              ]
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={
                                                imgPath +
                                                data.evolutionTechnologies
                                                  .media[x]
                                              }
                                              alt="medcraze"
                                            />
                                          </a>
                                        )
                                      ) : (
                                        <img
                                          src="/static/images/placeholder.jpg"
                                          alt="placeholder"
                                          layout="fill"
                                        />
                                      )}
                                    </div>
                                  </SwiperSlide>
                                );
                              }
                            )}
                        </Swiper>
                      </div>
                    </div>
                    {/* End Tab data */}
                  </Tab>
                  <Tab eventKey="procedure" title="PROCEDURES">
                    <div>
                      <h3 className="text-uppercase text-blue">
                        {data?.evolutionProcedures?.title}
                      </h3>
                      <p>{data?.evolutionProcedures?.description}</p>

                      <div className="topic-info-gallery">
                        <Swiper
                          spaceBetween={20}
                          slidesPerView={3}
                          navigation
                          modules={[Navigation, Pagination]}
                          pagination={{ clickable: true }}
                          breakpoints={{
                            769: {
                              slidesPerView: 3,
                            },
                            768: {
                              slidesPerView: 2,
                            },
                            576: {
                              slidesPerView: 2,
                            },
                            320: {
                              slidesPerView: 1,
                            },
                          }}
                        >
                          {data !== null &&
                            data.evolutionProcedures.media &&
                            Object.keys(data.evolutionProcedures.media).map(
                              (x, i) => {
                                return (
                                  <SwiperSlide key={i}>
                                    <div className="topic-gallery-image">
                                      {data.evolutionProcedures.media[x] !==
                                        null &&
                                        data.evolutionProcedures.media[x] !==
                                        "" ? (
                                        isVideoFormat(
                                          data.evolutionProcedures.media[x]
                                        ) ? (
                                          <div className="topic-gallery-video">
                                            <video
                                              width="100%"
                                              height="270"
                                              controls
                                            >
                                              <source
                                                src={`${imgPath +
                                                  data.evolutionProcedures
                                                    .media[x]
                                                  }`}
                                                type="video/mp4"
                                              />
                                            </video>
                                          </div>
                                        ) : (
                                          <a
                                            href={`${imgPath +
                                              data.evolutionProcedures.media[x]
                                              }`}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={`${imgPath +
                                                data.evolutionProcedures.media[
                                                x
                                                ]
                                                }`}
                                              alt="placeholder"
                                            />
                                          </a>
                                        )
                                      ) : (
                                        <img
                                          src="/static/images/placeholder.jpg"
                                          alt="placeholder"
                                        />
                                      )}
                                    </div>
                                  </SwiperSlide>
                                );
                              }
                            )}
                        </Swiper>
                      </div>
                    </div>
                    {/* End Tab data */}
                  </Tab>
                </Tabs>

                <div className="tab-content">
                  <div className="tech-data">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="d-block mb-1 text-capitalize font-weight400 gray-title">
                        Spotlight Technologies
                      </span>
                      {(user == " " || user == null || user == undefined || userType == "consumer") ? (
                        ''
                      )
                        :
                        <span className="add-tech-btn">
                          <Link
                            to={`/add-technology/${topicId}/${userId}`}
                            className="btn btn-blue btn-sm btn-radius20 btn-pink ms-1"
                          >
                            <i className="fa fa-plus me-1" aria-hidden="true"></i>
                            Add a Technology
                          </Link>
                        </span>
                      }


                    </div>
                    {data !== null &&
                      data.technologies.map((x, i) => {
                        return (
                          <div className="spotlight-technology-view" key={i}>
                            <h4 className="text-uppercase text-blue spot-tech-view-title">
                              {x.name}
                            </h4>
                            <div className="topic-profile profile-small">
                              <a id={data?.user_id} className="cursor-pointer" onClick={handleUserDetailShow}>
                                {data !== null &&
                                  data.profileMedia !== null &&
                                  data.profileMedia !== "" ? (
                                  <figure>
                                    <img
                                      src={imgPath + data.profileMedia}
                                      alt="Medcraze"
                                    />
                                  </figure>
                                ) : (
                                  <figure>
                                    <img
                                      src="/static/images/profile-placeholder.png"
                                      alt="Medcraze"
                                    />
                                  </figure>
                                )}
                              </a>
                              <div className="topic-profile-info">
                                <h6>
                                  {data !== null ? data.firstName : ""}{" "}
                                  {data !== null ? data.lastName : ""}
                                  <small className="small text-capitalize">
                                    {" "}
                                    {x.contributorType
                                      ? "(" + x.contributorType + ")"
                                      : ""}
                                  </small>
                                </h6>
                              </div>
                            </div>
                            <div className="mb-3 topic-desc">
                              <p>{x.description}</p>
                            </div>
                            {Object.keys(x.media).length > 0 && (
                              <div className="technology-media">
                                <div className="topic-info-gallery">
                                  <Swiper
                                    modules={[Navigation, Pagination]}
                                    spaceBetween={20}
                                    navigation
                                    pagination={{ clickable: true }}
                                    breakpoints={{
                                      767: {
                                        slidesPerView: 1,
                                      },
                                      768: {
                                        slidesPerView: 2,
                                      },
                                      1024: {
                                        slidesPerView: 3,
                                      },
                                    }}
                                  >
                                    {Object.keys(x.media).map((k) => {
                                      return (
                                        <SwiperSlide key={k}>
                                          <div className="topic-gallery-image">
                                            {isVideoFormat(x.media[k]) ? (
                                              <div className="topic-gallery-video">
                                                <video
                                                  width="100%"
                                                  height="270"
                                                  controls
                                                >
                                                  <source
                                                    src={imgPath + x.media[k]}
                                                    type="video/mp4"
                                                  />
                                                </video>
                                              </div>
                                            ) : (
                                              <a
                                                href={
                                                  x.media[k]
                                                    ? imgPath + x.media[k]
                                                    : "/static/images/placeholder.jpg"
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <img
                                                  src={
                                                    x.media[k]
                                                      ? imgPath + x.media[k]
                                                      : "/static/images/placeholder.jpg"
                                                  }
                                                  alt="placeholder"
                                                  width={320}
                                                  height={180}
                                                />
                                              </a>
                                            )}
                                          </div>
                                        </SwiperSlide>
                                      );
                                    })}
                                  </Swiper>
                                </div>
                              </div>
                            )}

                            {Object.keys(x.sources).length > 0 ?
                              <div className="mb-3 source-panel">
                                <h5>Sources:</h5>
                                {Object.keys(x.sources).length > 0 && (
                                  <div className="p-0 m-0 list-group">
                                    {Object.keys(x.sources).map((key, i) => (
                                      <p className="mb-1" key={i}>
                                        <a
                                          href={isValidLinkURL(
                                            x.sources[key]
                                          )}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="sources-link"
                                        >
                                          {x.sources[key]}
                                        </a>
                                      </p>
                                    ))}
                                  </div>
                                )}
                              </div>
                              :
                              null
                            }

                            {Object.keys(x.caseStudies).length > 0 ?
                              <div className="mb-3 view-pdf-list">
                                <h5>Case Studies:</h5>
                                {x.caseStudies && (
                                  <ul>
                                    {Object.keys(x.caseStudies).map(
                                      (study, ix) => {
                                        return (
                                          <li key={ix}>
                                            <a
                                              href={`${imgPath}${x.caseStudies[study]}`}
                                              target="_blank"
                                              rel="noreferrer"
                                              className="pdf-link case-study-doc"
                                            >
                                              <i
                                                className="far fa-file-pdf me-1"
                                                aria-hidden="true"
                                              ></i>
                                              {x.caseStudies[study]}
                                            </a>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                )}
                              </div>
                              :
                              null
                            }
                            <div className="spotlight-tech-action mb-3 text-end">
                              <a
                                href={isValidLinkURL(x.manufactureLink)}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-blue btn-sm btn-radius20 btn-yellow ms-1"
                              >
                                Learn More
                              </a>
                            </div>

                          </div>
                        );
                      })}
                    <div className="spotlight-tech-action action-edit-request mb-3 text-center">
                      {(user == " " || user == null || user == undefined) ?
                        <span className="btn btn-blue btn-radius20 btn-pink" onClick={guestUserShow}>Request more info</span>
                        :
                        <Link
                          to="/contact-us"
                          state={data}
                          className="btn btn-blue btn-radius20 btn-pink"
                        >
                          {(user == " " || user == null || user == undefined || userType == "consumer") ? (
                            'Request more info'
                          )
                            :
                            (
                              'Create Edit Request'
                            )
                          }
                        </Link>
                      }

                    </div>
                    <div className="spotlight-tech-social mb-3">
                      <div className="spotlight-view">
                        {(user == " " || user == null || user == undefined) ?
                          <a className="btn btn-gray-light btn-sm btn-radius20" onClick={guestUserShow}>
                            <i className="fas fa-arrow-up text-blue"></i>{" "}
                            {data?.upvotes}
                          </a>
                          :
                          <a className="btn btn-gray-light btn-sm btn-radius20" onClick={(e) => { handleTopicUpVote(e, data?.id) }}>
                            <i className="fas fa-arrow-up text-blue"></i>{" "}
                            {data?.upvotes}
                          </a>
                        }

                      </div>
                      <ul className="list-style-none">
                        <li>
                          <FacebookShareButton
                            url={shareUrl}
                            quote={
                              `We have a diagnoses topic ${data?.name} for you on MEDcraze.
                              Download the app now. 
                              iOS: https://apps.apple.com/us/app/medcraze/id1638147946
                              Android: https://play.google.com/store/apps/details?id=com.medcraze`
                            }
                            className="Demo__some-network__share-button"
                          >
                            <a className="fb">
                              <i className="fab fa-facebook-square"></i>
                            </a>
                          </FacebookShareButton>
                        </li>
                        <li>
                          <LinkedinShareButton
                            url={shareUrl}
                            summary={
                              `We have a diagnoses topic ${data?.name} for you on MEDcraze.
                              Download the app now. 
                              iOS: https://apps.apple.com/us/app/medcraze/id1638147946
                              Android: https://play.google.com/store/apps/details?id=com.medcraze`
                            }
                            className="Demo__some-network__share-button"
                          >
                            <a className="linkedin">
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </LinkedinShareButton>
                        </li>
                        <li>
                          <TwitterShareButton
                            url={shareUrl}
                            title={
                              `We have a diagnoses topic ${data?.name} for you on MEDcraze.
                              Download the app now. 
                              iOS: https://apps.apple.com/us/app/medcraze/id1638147946
                              Android: https://play.google.com/store/apps/details?id=com.medcraze`
                            }
                            className="Demo__some-network__share-button"
                          >
                            <a className="twitter">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </TwitterShareButton>
                        </li>
                        <li>
                          <CopyToClipboard text={window.location.href}>
                            <a className="copy">
                              <i className="far fa-copy"></i>
                            </a>
                          </CopyToClipboard>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="topic-comment-panel">

                <div className="main-comment">
                  {(user == " " || user == null || user == undefined) ?
                    <a onClick={guestUserShow} className="disabledClickGuest">
                      <figure>
                        {userImage ?
                          <img src={fileUrl + userImage} alt="Medcraze" />
                          :
                          <img src="/static/images/profile-placeholder.png" alt="Medcraze" />
                        }

                      </figure>
                    </a>
                    :
                    <a id={user?.id} className="cursor-pointer" onClick={handleUserDetailShow}>
                      <figure>
                        {userImage ?
                          <img src={fileUrl + userImage} alt="Medcraze" />
                          :
                          <img src="/static/images/profile-placeholder.png" alt="Medcraze" />
                        }

                      </figure>
                    </a>
                  }
                  <div className="main-comment-area">
                    {(user == " " || user == null || user == undefined) ?
                      <div onClick={guestUserShow} className="disabledClickGuest">
                        <AddComment
                          userId={userId}
                          topicId={topicId}
                          token={token}
                          getCommentsData={getCommentsData}
                        />
                      </div>
                      :
                      <AddComment
                        userId={userId}
                        topicId={topicId}
                        token={token}
                        getCommentsData={getCommentsData}
                      />
                    }

                  </div>
                </div>

                {/* Media Object Start Here */}

                {commentsData?.records &&
                  commentsData?.records.map((commentData) => {
                    return (
                      <div className="d-flex media-object">
                        <div className="flex-shrink-0 media-avtar">
                          {(user == " " || user == null || user == undefined) ?
                            <a onClick={guestUserShow} className="disabledClickGuest">
                              {commentData.profileMedia ?
                                <img className="rounded-circle" src={fileUrl + commentData.profileMedia} alt="Medcraze" width="50"
                                  height="50" style={{ border: "solid 1px #283ADC" }} />
                                :
                                <img className="rounded-circle" src="/static/images/profile-placeholder.png" alt="Medcraze" />
                              }
                            </a>
                            :
                            <a id={commentData?.user_id} className="cursor-pointer" onClick={handleUserDetailShow}>
                              {commentData.profileMedia ?
                                <img className="rounded-circle" src={fileUrl + commentData.profileMedia} alt="Medcraze" width="50"
                                  height="50" style={{ border: "solid 1px #283ADC" }} />
                                :
                                <img className="rounded-circle" src="/static/images/profile-placeholder.png" alt="Medcraze" />
                              }
                            </a>
                          }
                        </div>
                        <div className="flex-grow-1 ms-3 media-body">
                          <h5>
                            {commentData.firstName + " " + commentData.lastName}
                            {/* Mark Adward */}
                          </h5>
                          <div className="media-comment mb-2">
                            <p className="m-0">{commentData.comment}</p>
                            {commentData.media.length > 0 ? (
                              helper.isVideoFormat(commentData.media) ? (
                                <div className="comment_media_video">
                                  <video width="100%" controls>
                                    <source
                                      src={fileUrl + commentData.media}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                              ) : (["pdf"].includes(
                                commentData.media.split(".")[1]) ?
                                <a
                                  href={isValidLinkURL(
                                    imgPath + commentData?.media
                                  )}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="sources-link"
                                >
                                  {commentData?.media}
                                </a>
                                :
                                <div className="media-comment-image">
                                  <img src={fileUrl + commentData.media} />
                                </div>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="media-action mb-2">
                            <div className="action-link">
                              {(user == " " || user == null || user == undefined) ?
                                <a
                                  href="javascript:void(0);"
                                  onClick={guestUserShow}
                                >
                                  Reply
                                </a>
                                :
                                <a
                                  href="javascript:void(0);"
                                  onClick={(e) => {
                                    handleCommentReply(e, commentData.id);
                                  }}
                                >
                                  Reply
                                </a>
                              }
                              {
                                userId === commentData.user_id ?
                                  <>
                                    <a
                                      href="javascript:void(0);"
                                      onClick={handleDelectCommentShow}
                                    // onClick={(e) => {
                                    //   handleCommentDelete(e, commentData.id);
                                    // }}
                                    >
                                      Delete
                                    </a>
                                    <Modal show={showDelectComment} onHide={handleDelectCommentClose} dialogClassName="add-topic-modal contact-modal">
                                      <Modal.Body>
                                        <Modal.Header closeButton></Modal.Header>
                                        <div className="modal-body-info text-center">
                                          <p className="font-size20">Are you sure you want to delete comment?</p>
                                        </div>
                                        <div className="modal-action-btns text-center">
                                          <Button type="button" className="btn btn-blue btn-blue-outline mr-1" onClick={handleDelectCommentClose}>
                                            No
                                          </Button>
                                          <Button type="button" className="btn-blue"
                                            onClick={(e) => {
                                              handleCommentDelete(e, commentData.id);
                                            }}>
                                            Yes
                                          </Button>
                                        </div>
                                      </Modal.Body>
                                    </Modal>
                                  </>
                                  :
                                  ''
                              }



                            </div>
                            <div className="action-comment">
                              <a
                                href="javascript:void(0);"
                                onClick={(e) => {
                                  handleUpVote(e, commentData.id);
                                }}
                              >
                                <i className="fas fa-arrow-up text-blue me-1"></i>{" "}
                                {commentData.upvotes}
                              </a>
                            </div>
                          </div>
                          {showReply === commentData.id && (
                            <ReplyComment
                              userId={userId}
                              topicId={topicId}
                              token={token}
                              getCommentsData={getCommentsData}
                              className={showReply === commentData.id ? 'show' : ''}
                              // getReplyCommentsData={getReplyCommentsData}
                              commentId={commentData.id}
                            />
                          )}
                          {/* {commentData.repliesCount > 0 ?
                            <div className="view-all-action  mb-2">
                              <a className="cursor-pointer" onClick={toggleRepliedView}>
                                View {commentData.repliesCount} Replies
                              </a>
                            </div>
                            :
                            ''
                          } */}

                          {/* Reply Panel Start Here */}
                          {commentData.repliesCount > 0 && (
                            <ListReplyComments
                              topicId={topicId}
                              token={token}
                              commentId={commentData.id}
                              repliesCount={commentData.repliesCount}
                              toggleRepliesData={toggleReplied}
                            />
                          )}
                          {/* Reply Panel End Here */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Col>
          {/* Right Side Panel Start */}
          <Col lg={3}>
            <aside className="sidebar">
              <h3>Trending on MEDcraze</h3>
              {topicData?.slice(0, 4).map((topic) => (
                <div className="sidebar-trending-item" key={topic.id}>
                  <Link to={`/topic-detail/${topic.id}/${topic.user_id}`} reloadDocument >
                    <figure>
                      <video playsInline width="100%" height="200">
                        <source
                          src={imgPath + topic.introVideo}
                          type="video/mp4"
                        />
                      </video>
                      <figcaption className="single-line-ellipsis">
                        {topic.name}
                      </figcaption>
                    </figure>
                  </Link>
                </div>
              ))}
            </aside>
          </Col>
        </Row>
      </Container>
      <Modal
        show={showUserDetail}
        onHide={handleUserDetailClose}
        dialogClassName="profile-modal"
      >
        <Modal.Body className="p-0">
          <div className="modal-body-info profile-modal-info">
            <div className="profile-cover-banner">
              <img src="/static/images/profile-cover.png" alt="Medcraze" />

              <span className="edit-profile-cover-icon">
                <button className="btn  btn-pink" onClick={() => handleUserDetailClose(true)}>
                  <i className="fas fa-times"></i>
                </button>
              </span>
            </div>
            <div className="profile-pic-panel">
              <div className="profile-pic">
                {UsrProfileData?.profileMedia ?
                  <img src={imgPath + UsrProfileData?.profileMedia} alt="Medcraze" />
                  :
                  <img src="/static/images/profile-placeholder.png" alt="Medcraze" />
                }
              </div>
            </div>
            <div className="profile-form-panel">
              <Container className="mt-30">
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-user.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>First Name</h4>
                          <p className="value-field">{UsrProfileData?.firstName ? `${UsrProfileData?.firstName}` : 'NA'}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-user.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>Last Name</h4>
                          <p className="value-field">{UsrProfileData?.lastName ? `${UsrProfileData?.lastName}` : 'NA'}</p>
                        </div>
                      </div>
                    </div>
                  </Col>

                  {UsrProfileData?.userType == "consumer" ?
                    ""
                    :
                    <>
                      <Col md={6}>
                        <div className="form-group">
                          <div className="profile-value">
                            <div className="profile-value-icon">
                              <img src="/static/images/icon-user.png" alt="Medcraze" />
                            </div>
                            <div className="profile-value-info">
                              <h4>Contributor Type</h4>
                              <p className="value-field text-capitalize">{UsrProfileData?.contributorDetails?.contributorType ? `${UsrProfileData?.contributorDetails?.contributorType}` : 'NA'}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      {UsrProfileData?.contributorDetails?.contributorType == "industry representative" ?
                        <Col md={6}>
                          <div className="form-group">
                            <div className="profile-value">
                              <div className="profile-value-icon">
                                <img src="/static/images/icon-hospital.png" alt="Medcraze" />
                              </div>
                              <div className="profile-value-info">
                                <h4>Company Name</h4>
                                <p className="value-field">{UsrProfileData?.contributorDetails?.clinicName ? `${UsrProfileData?.contributorDetails?.clinicName}` : 'NA'}</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        :
                        <Col md={6}>
                          <div className="form-group">
                            <div className="profile-value">
                              <div className="profile-value-icon">
                                <img src="/static/images/icon-hospital.png" alt="Medcraze" />
                              </div>
                              <div className="profile-value-info">
                                <h4>Name of Hospital or Practice Name</h4>
                                <p className="value-field">{UsrProfileData?.contributorDetails?.clinicName ? `${UsrProfileData?.contributorDetails?.clinicName}` : 'NA'}</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      }
                    </>
                  }


                  <Col md={6}>
                    <div className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-city.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>City</h4>
                          <p className="value-field">{UsrProfileData?.city ? `${UsrProfileData?.city}` : 'NA'}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-state.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>State</h4>
                          <p className="value-field">{UsrProfileData?.state ? `${UsrProfileData?.state}` : 'NA'}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-bio.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>Bio</h4>
                          <div className="full-control">
                            <div as="textarea">{UsrProfileData?.contributorDetails?.bio ? `${UsrProfileData?.contributorDetails?.bio}` : 'NA'}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </main>
  );
}

export default TopicDetails;

