import React, { useEffect, useState, useRef } from 'react'
import { Container } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getNotification, markReadNotification } from "../services";
import { imgPath } from "../services";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Spinner from './common/Spinner';
import GoogleAds from './GoogleAds';

function Notification() {

  let userData = localStorage.getItem("user");
  userData = JSON.parse(userData);
  const userId = userData?.id;
  const token = userData?.auth?.token;

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [notificationCount, setnotificationCount] = useState('');

  const getNotificationItems = async () => {
    const params = { "pageSize": 300, "pageNumber": 1, "user_id": userId };
    const response = await getNotification(params, token);
    if (response && response.data.type == 'success' && response.data.data.records.length > 0) {
      let idsArray = [];
      response.data.data.records.map(x => {
        if (x.isRead == 0) {
          idsArray.push(x.id);
        }
      })
      setData(response.data.data.records);
      if (idsArray.length > 0) {
        setReadNotification(idsArray);
      }
      setLoading(false);
      
    } else {
      toast("Server Error", { type: "error", autoClose: 2000 });
      setLoading(false);
    }
  };

  const setReadNotification = async (ids) => {
    const params = { "ids": ids };
    const response = await markReadNotification(params, token);
    if (response && response.data.type == 'success') {
      // console.log(response.data.data)
      document.getElementById('notification-unread-count').className = 'd-none';
    }
  }

  const useOnceCall = (cb, condition = true) => {
    const isCalledRef = useRef(false);
    useEffect(() => {
      if (condition && !isCalledRef.current) {
        isCalledRef.current = true;
        cb();
      }
    }, [cb, condition]);
  }

  useOnceCall(() => {
    getNotificationItems();
    // setReadNotification();
  });

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];
  const timeSince = (date) => {
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
  }

  return (
    <main className="main-container notification-page">
      {isLoading && <div className="page-spinner"><div className="white-box"><Spinner /></div></div>}
      <Container>
        <Row className="justify-content-center mb-50">
          <Col md={8} lg={6}>
            <div className="white-box ">
              <div className="sub-title">
                <h2>Notifications</h2>
              </div>
              <div className="notification-list">
                <p className="font-size18"><i className="far fa-check-circle me-1 text-success"></i> Marked as read</p>
                <ul className="list-style-none">
                  {data.length > 0 ?
                    data.map((item, i) => {
                      return <li key={i} className={`topic-profile ${item.isRead ? 'readed' : 'unread'} `}>
                        {item.metadata !== null && item.metadata.profileMedia !== null && item.metadata.profileMedia !== '' ?
                          <figure>
                            <img
                              src={imgPath + item.metadata.profileMedia}
                              alt="profile"
                              width={60}
                              height={60} />
                          </figure>
                          :
                          <figure>
                          <img
                            src="/static/images/profile-placeholder.png"
                            alt="profile"
                            width={60}
                            height={60} />
                            </figure>
                        }

                        <div className="notification-text">
                          <div className="notify-title">
                            {item.message}
                          </div>
                          <p>{item.createdOn ? timeSince(new Date(item.createdOn)) : ''}</p>
                          {
                            item.isRead ? 
                            <i className="far fa-check-circle text-success"></i>
                            :
                            null
                          }                          
                        </div>

                      </li>
                    })
                    : <div className="no-notify">No record found</div>}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
      </Container>
    </main>
  )
}

export default Notification