import React, { useRef, useState } from 'react';
import { Container } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { addTechnology } from '../services';
import { toast, ToastContainer } from 'react-toastify';
import Spinner from './common/Spinner';
import "react-toastify/dist/ReactToastify.css";
import AddTechnology from './AddTechnology';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import GoogleAds from './GoogleAds';

const AddTechWrapper = () => {

    const [isLoading, setLoading] = useState(false);
    const techRef = useRef(null);
    const navigate = useNavigate(); 

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    const params = useParams();
    const topicId = params?.id;
    const userId = params?.userid;

    let userData = localStorage.getItem("user");
    userData = JSON.parse(userData);
    const token = userData?.auth?.token;

    const onSubmit = async (data, e) => {
        e.preventDefault();
        setLoading(true);

        let params = techRef.current.getTechnologyData()[0];
        params['user_id'] = userId;
        params['topic_id'] = topicId;
        params['type'] = 'ADD';

        const paramSend = JSON.stringify(params);

        let response = await addTechnology(paramSend, token);

        if (response.data && response.data.type === 'success') {
            toast('Technology added successfully', {
                type: "success", autoClose: 2000,
                onClose: setTimeout(async function () {
                    navigate('/spotlighttech-list');
                    setLoading(false);
                }, 1000)                
            }            
        );
        } else {
            setLoading(false);
            toast('Server error', { type: "error", autoClose: 2000 });
        }
    }

    return (<>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false} />

        <main className="main-container add-topic-page">
            {isLoading && <div className="page-spinner"><div className="white-box"><Spinner /></div></div>}
            <Container>
                <div className="white-box mb-50">
                    <div className="sub-title">
                        <h2 className="text-capitalize">Add Technology</h2>
                    </div>
                    <div className="add-topic-panel">
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <AddTechnology ref={techRef} isEditView={false} register={register} errors={errors} />

                            <div className="form-action text-end mt-2 mb-2">
                                <button className="btn btn-blue mr-1" type="submit" disabled={isLoading}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
                <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
            </Container>
        </main>
    </>)
}

export default AddTechWrapper;