import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Formik } from "formik";
import * as Yup from "yup";
import { forgotPassword, verifyOTP } from "../../services";

const VerifyOTP = (props) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");

  const VerifyOTPSchema = Yup.object().shape({
    No1: Yup.string().required().min(1).max(1),
    No2: Yup.string().required().min(1).max(1),
    No3: Yup.string().required().min(1).max(1),
    No4: Yup.string().required().min(1).max(1),
    No5: Yup.string().required().min(1).max(1),
    No6: Yup.string().required().min(1).max(1),
  });

  const handleClick = () => {
    let values = {
      email: props.forgotPasswordEmail,
      type: "forgotPassword",
    };

    forgotPassword(values)
      .then((response) => {
        setAlertSuccessMessage("OTP has been resend successfully!");
      })
      .catch((error) => {
        console.error("VerifyOTP handleClick  --- ", error);
        let message = error.response?.data?.message;
        if (message) {
          setAlertMessage(message);
          setAlertSuccessMessage("");
        }
      });
  };

  const numOfFields = 6;

const useSSNFields = () => {
  const [ssnValues, setValue] = React.useState({
    No1: "",
    No2: "",
    No3: "",
    No4: "",
    No5: "",
    No6: ""
  });

  return {
    handleChange: e => {
      const { maxLength, value, name } = e.target;
      const [fieldName, fieldIndex] = name.split("-");

      // Check if they hit the max character length
      if (value.length >= maxLength) {
        // Check if it's not the last input field
        if (parseInt(fieldIndex, 10) < 6) {
          // Get the next input field
          const nextSibling = document.querySelector(
            `input[name=No${parseInt(fieldIndex, 10) + 1}]`
          );

          // If found, focus the next field
          if (nextSibling !== null) {
            nextSibling.focus();
          }
        }
      }

      setValue({
        ...value,
        [`ssn${fieldIndex}`]: value
      });
    }
  };
};


  return (
    <>
      {alertSuccessMessage && (
        <Alert
          variant="primary"
          onClose={() => {
            setAlertSuccessMessage("");
          }}
          dismissible
        >
          <p>{alertSuccessMessage}</p>
        </Alert>
      )}
      {alertMessage && (
        <Alert
          variant="danger"
          onClose={() => {
            setAlertMessage("");
          }}
          dismissible
        >
          <p>{alertMessage}</p>
        </Alert>
      )}
      <Formik
        validationSchema={VerifyOTPSchema}
        initialValues={{
          No1: "",
          No2: "",
          No3: "",
          No4: "",
          No5: "",
          No6: "",
        }}
        onSubmit={(values) => {
          let otp =
            values.No1 +
            values.No2 +
            values.No3 +
            values.No4 +
            values.No5 +
            values.No6;
            
          let params = {
            type: "email",
            source: props.forgotPasswordEmail,
            otp: otp,
          };

          verifyOTP(params)
            .then((response) => {
              // console.log(response);
              setAlertMessage("");
              props.handleResetPasswordPanel("block");
            })
            .catch((error) => {
              props.handleResetPasswordPanel("none");
              console.error("verifyOTP --- ", error);
              let message = error.response?.data?.message;
              if (message) {
                setAlertMessage(message);
              }
            });
        }}
      >
        {({ handleChange, handleSubmit, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit} method="post">
            <div className="otp-list"></div>
            <Form.Group className="form-group">
              <div className="otp-group">
                <Form.Control
                  type="text"
                  name="No1"
                  tabIndex={1}
                  maxLength={1}
                  value={values.No1}
                  onChange={handleChange}
                  isInvalid={!!errors.No1}
                />
                <Form.Control
                  type="text"
                  name="No2"
                  tabIndex={2}
                  maxLength={1}
                  value={values.No2}
                  onChange={handleChange}
                  isInvalid={!!errors.No2}
                />
                <Form.Control
                  type="text"
                  name="No3"
                  tabIndex={3}
                  maxLength={1}
                  value={values.No3}
                  onChange={handleChange}
                  isInvalid={!!errors.No3}
                />
                <Form.Control
                  type="text"
                  name="No4"
                  tabIndex={4}
                  maxLength={1}
                  value={values.No4}
                  onChange={handleChange}
                  isInvalid={!!errors.No4}
                />
                <Form.Control
                  type="text"
                  name="No5"
                  tabIndex={5}
                  maxLength={1}
                  value={values.No5}
                  onChange={handleChange}
                  isInvalid={!!errors.No5}
                />
                <Form.Control
                  type="text"
                  tabIndex={6}
                  maxLength="1"
                  name="No6"
                  value={values.No6}
                  onChange={handleChange}
                  isInvalid={!!errors.No6}
                />
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <div className="modal-action-btns mt-30">
                <Button type="submit" className="btn-blue w-100">
                  Verify
                </Button>
              </div>
            </Form.Group>
            <Form.Group className="form-group  text-center">
              <a href="javascript:void(0);" onClick={handleClick}>
                Resend
              </a>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default VerifyOTP;
