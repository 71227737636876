
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { getNewList } from "../services";
import Spinner from "./common/Spinner";
import GoogleAds from "./GoogleAds";

function NewsList() {

  const [newsList, setNewsList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getNewsListCall = async (records) => {
    let response = await getNewList(records);
    // console.log("response", response);
    let data = response.data?.data;
    setLoading(false);
    setNewsList(data);
  }
  useEffect(() => {
    getNewsListCall(10)
  }, []);
  return (
    <main className="main-container news-list-page">
      {isLoading && <div className="page-spinner"><div className="white-box"><Spinner /></div></div>}
      <section className="news-listing-section">
        <Container>
          <div className="sub-title">
            <h2 className="text-capitalize">MEDTech News</h2>
          </div>
          <Row className="mb-50">
            {newsList?.records ? newsList?.records.map((list) => (
              <Col md={4}>
                <article className="news-article mb30">
                  <a href={list.url} target="_blank" rel="noreferrer">
                    <figure>
                      <img src="/static/images/medtech-news.png" alt="Medcraze" />
                    </figure>
                    <div className="news-info" style={{ background: "rgba(79,192,172,0.85)" }}>
                      <h5 className="text-uppercase">{list.title}</h5>
                    </div>
                  </a>
                </article>
              </Col>
            )) :
            <div className="no-record-found text-center">
              No Record Found
            </div>
            }
          </Row>
          <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
        </Container>
      </section>
    </main>
  )
}

export default NewsList