import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

const Search = (props) => {
  const SearchSchema = Yup.object().shape({
    searchText: Yup.string().required("Search Text is required"),
  });

  return (
    <Row className="justify-content-center mb-5">
      <Col md={8} lg={6}>
        <Formik
          initialValues={{
            searchText: "",
          }}
          onSubmit={(values) => {
            props.searchList(values);
          }}
        >
          {({ handleChange, handleSubmit, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} method="post">
              <div className="search-panel">
                <Form.Control
                  type="text"
                  name="searchText"
                  value={values.searchText}
                  onChange={handleChange}
                  placeholder="Search"
                />
                <Button
                  type="submit"
                  className="btn-blue"
                >
                  <i className="fas fa-search"></i>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default Search;
