import React from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { uploadMedia } from '../services';
import UploadMedia from './UploadMedia';
import { isVideoFormat } from './utility/Utility';

class TopicAddTechnology extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            technology: [{
                name: '',
                media: [],
                description: '',
                tags: [],
                manufactureLink: '',
                sources: [],
                caseStudies: []
            }],
            videoPath: [],
            menufectureError: [],
            sourceError: [],
            disabledSourceBtn: true
        }

        this.tectRef = React.createRef();
    }

    componentDidMount() {

        if (typeof this.props.data != 'undefined' && this.props.data != null && this.props.data.length > 0) {
            let tech = [];
            let videoPath = this.state.videoPath;
            let disabledBtn = this.state.disabledSourceBtn;

            this.props.data.map(x => {
                let mediaI = [];
                let tag = x.tags ? Object.values(x.tags) : [];
                let caseStudy = x.caseStudies ? Object.values(x.caseStudies) : [];
                let source = x.sources ? Object.values(x.sources) : [];

                if (source.length > 0) {
                    disabledBtn = false;
                }

                let videoUrl = null;

                if (x.media && Object.keys(x.media).length > 0) {
                    Object.keys(x.media).map((key, i) => {
                        if (isVideoFormat(x.media[key])) {
                            videoUrl = x.media[key];
                        }
                        mediaI.push(x.media[i]);
                    })
                }

                videoPath.push(videoUrl);

                if (mediaI.length > 0) {
                    if (isVideoFormat(mediaI[0]) && mediaI.length <= 2) {
                        if (mediaI.length === 1) {
                            mediaI.push(null);
                            mediaI.push(null);

                        } else {
                            mediaI.push(null);
                        }
                    } else {
                        mediaI.push(null);
                    }
                } else {
                    mediaI = [null, null];
                }

                let obj = {};
                obj['id'] = x.id;
                obj['name'] = x.name;
                obj['description'] = x.description;
                obj['media'] = JSON.stringify(mediaI);
                obj['manufactureLink'] = x.manufactureLink;
                obj['tags'] = tag;
                obj['caseStudies'] = caseStudy;
                obj['sources'] = source;

                tech.push(obj);
            });

            this.setState({ technology: tech, videoPath: videoPath, disabledSourceBtn: disabledBtn });
        }
    }

    onChangeFields = (e, pIndex) => {
        let data = this.state.technology;
        data[pIndex]['name'] = e.target.value;
        this.setState({ technology: data });
    }

    onChangeDescFields = (e, pIndex) => {
        let data = this.state.technology;
        data[pIndex]['description'] = e.target.value;
        this.setState({ technology: data });
    }

    onChangeManufactureFields = (e, pIndex) => {
        let data = this.state.technology;
        data[pIndex]['manufactureLink'] = e.target.value;
        this.setState({ technology: data });
    }

    onChangeSourceFields = (e, pIndex, i) => {
        let data = this.state.technology;
        let isDisable = this.state.disabledSourceBtn;

        if (e.target.value !== '' && e.target.value !== '') {
            isDisable = false;
        } else {
            isDisable = true;
        }

        data[pIndex]['sources'][i] = e.target.value;
        this.setState({ technology: data, disabledSourceBtn: isDisable });
    }

    addTechnology = () => {
        let data = this.state.technology;
        let obj = { name: '', media: [], description: '', tags: [], manufactureLink: '', sources: [], caseStudies: [] };
        data.push(obj);
        this.setState({ technology: data }, () => {
            this.scrollDown();
        });
    }

    scrollDown = () => {
        window.scrollTo({
            top: this.tectRef.offsetTop,
            behavior: 'smooth',
        });
    };

    onImageUpload = (media, index) => {
        let data = [...this.state.technology];
        let temp = [];
        media.map(x => typeof x !== 'undefined' && x !== null && temp.push(x));

        data[index].media = temp;
        this.setState({ technology: data });
    }

    onPDFUpload = async (e, index) => {
        if (e.target.files) {
            let file = e.target.files[0];
            if (file.type !== 'application/pdf') {
                toast('Only pdf file allowed', { type: "error", autoClose: 2000 });
            } else {
                const formData = new FormData();
                formData.append("file", file, file.name);

                let response = await uploadMedia(formData);

                if (response.data && response.data.type === 'success') {
                    this.updateCaseStudy(response.data.data.fd, index);

                } else {
                    toast(response.data.message, { type: "error", autoClose: 2000 });
                }
            }
        }
    }

    updateCaseStudy = (url, index) => {
        let data = this.state.technology;
        data[index].caseStudies.push(url);
        this.setState({ technology: data }, () => {
            if (this.state.technology[index].caseStudies.length === 5) {
                toast("Case studies limit exceeded ", { type: "error", autoClose: 2000 });
            }
        });
    }

    addSources = (index) => {
        let data = this.state.technology;

        if (data[index].sources.length === 0) {
            data[index].sources.push(null);
            data[index].sources.push(null);
        } else {
            data[index].sources.push(null);
        }

        this.setState({ technology: data, disabledSourceBtn: true }, () => {
            if (this.state.technology[index].sources.length === 5) {
                toast("Source limit exceeded ", { type: "error", autoClose: 2000 });
            }
        });
    }

    removeSources = (pIndex, i) => {
        let data = this.state.technology;
        data[pIndex].sources.splice(i, 1);
        this.setState({ technology: data, disabledSourceBtn: false });
    }

    onChangeTagField = (e, index) => {
        if (e.target.value) {
            let data = this.state.technology;
            data[index].tags = e.target.value.split(',')
            this.setState({ technology: data });

        } else {
            let data = this.state.technology;
            data[index].tags = []
            this.setState({ technology: data });
        }
    }

    getTechnologyData = () => {
        let technology = [];
        this.state.technology.map(x => {

            if (x.media && !Array.isArray(x.media)) {
                x.media = JSON.parse(x.media).filter(x => x !== null);

            } else if (x.media.length > 0) {
                x.media = x.media.filter(x => x !== null);
            }
            if ( x.sources && x.sources.length > 0 ) {
                x.sources.map((key, index) => {
                    if(key == null || key == "" ) {
                        x.sources.splice(index, 1);
                    }
                })
            } 

            technology.push(x);
        });
        return technology;
    }

    removeCaseStudy = (pIndex, i) => {
        let data = this.state.technology;
        data[pIndex].caseStudies.splice(i, 1);
        this.setState({ technology: data });
    }

    removeTechnology = (pIndex) => {
        let data = this.state.technology;
        data.splice(pIndex, 1);
        this.setState({ technology: data });
    }

    render() {

        return (<>
            <div className="add-topic-column">
                <div className="topic-title">
                    <h3 className="text-nacviblue">
                        Spotlight Technologies (Max.5)
                    </h3>
                    <a className="btn btn-sm btn-blue color-white" onClick={() => this.addTechnology()}>
                        <i className="fa fa-plus mr-1" aria-hidden="true"></i>Add a Technology
                    </a>
                </div>

                {this.state.technology.map((item, index) => {
                    return <div ref={(e) => this.tectRef = e} key={'em' + index} className='technology-block mb-4 added-technology'>
                        <div className="form-group">
                            <div className='d-flex justify-content-between align-items-center'>
                                <label>Title</label>
                                {index !== 0 && <span className='btn btn-primary btn-sm btn-rounded btn-icon mb-1'
                                    onClick={() => this.removeTechnology(index)}><i className="fa fa-times" ></i>
                                </span>}
                            </div>

                            <input name={'name' + index} type="text" className="form-control" placeholder="Enter title"

                                {...this.props.register(`name${index}`, {
                                    required: true,
                                    onChange: (e) => this.onChangeFields(e, index)
                                })}
                            />
                            <small className="text-danger">
                                {this.props.errors && this.props.errors[`name${index}`] ? 'Title is required' : ''}
                            </small>
                        </div>

                        <UploadMedia videoPath={this.state.videoPath.length > 0 ? this.state.videoPath[index] : null}
                            isEditView={this.props.isEditView} type='tech' index={index} lastIndex={this.state.technology.length - 1}
                            data={item.media.length > 0 ? Array.isArray(item.media) ? item.media
                                : JSON.parse(item.media) : null}
                            uuId={uuidv4()}
                            callBack={(media) => this.onImageUpload(media, index)} />

                        <div className="form-group">
                            <label>Description</label>
                            <textarea name={'techDesc' + index} className="form-control" placeholder="Description"
                                {...this.props.register(`techDesc${index}`, {
                                    required: true,
                                    onChange: (e) => this.onChangeDescFields(e, index)
                                })}
                            />
                            <small className="text-danger">
                                {this.props.errors && this.props.errors[`techDesc${index}`] ? 'Description is required' : ''}
                            </small>

                            {/* <textarea required name='description' className="form-control" placeholder="Description" 
                                value={item.description} onChange={(e) => this.onChangeFields(e, index)} /> */}
                        </div>

                        <div className="form-group">
                            <label>Manufacturer link <small>(Please provide URL to patient page of product website)</small></label>
                            <input name={'techManufacture' + index} type="text" className="form-control mb-2"
                                value={item.manufactureLink}
                                {...this.props.register(`techManufacture${index}`, {
                                    pattern: {
                                        value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                        message: "Please enter a valid link",
                                    },
                                    required: true,
                                    onChange: (e) => this.onChangeManufactureFields(e, index)
                                })}
                            />
                            <small className="text-danger">
                                {this.props.errors && typeof this.props.errors[`techManufacture${index}`] !== 'undefined' ?
                                    this.props.errors[`techManufacture${index}`].type === 'required' ? 'Manufacturer link  is required' 
                                    : this.props.errors[`techManufacture${index}`].message : ''}
                            </small>
                        </div>
                        <div className="form-group">
                            <label>Tags/Keywords</label>
                            <input name='tags' type="text" className="form-control mb-2"
                                value={item.tags.join()} onChange={(e) => this.onChangeTagField(e, index)} />
                        </div>
                        <div className="form-group">
                            <div className='d-flex justify-content-between mb-1 align-items-center'>
                                <label>Sources</label>
                                {item.sources.length < 5 && <button type='button' className='btn btn-primary btn-sm btn-rounded btn-icon'
                                    onClick={() => this.addSources(index)}
                                    disabled={this.state.disabledSourceBtn || ((Object.keys(this.props.errors).length > 0 && typeof this.props.errors[`techSources${index}${item.sources.length > 0 ? item.sources.length - 1 : '0'}`] != 'undefined'))}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </button>}
                            </div>

                            <input name={`techSources${index}0`} type="text" className="form-control"
                                value={item.sources[0]}
                                {...this.props.register(`techSources${index}0`, {
                                    pattern: {
                                        value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                        message: "Please enter a valid link",
                                    },
                                    onChange: (e) => this.onChangeSourceFields(e, index, 0)
                                })}
                            />
                            <div className="text-danger mt-1 mb-2">
                                {this.props.errors && typeof this.props.errors[`techSources${index}0`] !== 'undefined' ?
                                    this.props.errors[`techSources${index}0`].message : ''}
                            </div>

                            {item.sources.length >= 1 && item.sources.map((source, i) => {
                                return i != 0 && <div key={i}>
                                    <div className='d-flex align-items-center mb-2 add-remove-source-link'>
                                        <input name={`techSources${index}${i}`} type="text" className="form-control"
                                            value={source}
                                            {...this.props.register(`techSources${index}${i}`, {
                                                pattern: {
                                                    value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                                    message: "Please enter a valid link",
                                                },
                                                onChange: (e) => this.onChangeSourceFields(e, index, i)
                                            })}
                                        />
                                        <span className='btn btn-primary btn-sm btn-rounded btn-icon ms-2'
                                            onClick={() => this.removeSources(index, i)}><i className="fa fa-times" ></i></span>
                                    </div>
                                    <div className='mt-1 mb-2'>
                                        <small className="text-danger">
                                            {this.props.errors && typeof this.props.errors[`techSources${index}${i}`] !== 'undefined' ?
                                                this.props.errors[`techSources${index}${i}`].message : ''}
                                        </small>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="form-group">
                            <div className='d-flex justify-content-between align-items-center position-relative'>
                                <label>Case Studies <small>(PDF Only)</small></label>
                                {item.caseStudies.length < 5 && <div className='btn btn-primary btn-sm btn-rounded btn-icon position-relative'>
                                    <input type="file" accept="application/pdf" id="logo-file-input" className="file-blank"
                                        onChange={(e) => this.onPDFUpload(e, index)} />
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </div>}
                            </div>

                            <div className="pdf-panel">
                                <div className="pdf-btn-panel">
                                    {item.caseStudies.map((study, i) => {
                                        return <span key={i} className='mr-1 pdf-btn pdf-btn2'>
                                            <a href="#">
                                                <i className="far fa-file-pdf me-1" aria-hidden="true"></i>
                                                {study}
                                            </a>
                                            <span className='btn'
                                                onClick={() => this.removeCaseStudy(index, i)}><i className="far fa-trash-alt" ></i>
                                            </span>
                                        </span>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </>)
    }
}

export default TopicAddTechnology;