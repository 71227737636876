import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { listUser } from '../services';
import { imgPath } from "../services";
import { Link } from 'react-router-dom';

function Footer() {

  let userData = localStorage.getItem("user");

  const [profileData, setProfileData] = useState([])
  const [user, setUser] = useState(userData);

  const getUsersData = async () => {
    let params = { "pageSize": 100, "pageNumber": 1, "userType": 'contributor' };
    let response = await listUser(params);
    let data = response.data?.data;
    data.records = data.records.filter((value)=>value.profileMedia)
    setProfileData(data);
  }
  useEffect(() => {
    getUsersData()
  }, []);

  return (
    <footer className="footer">
      <div className="footer-top">
        <Container>
          <Row>
            <Col md={12} lg={4}>
              <div className="footer-about">
                <figure><img src="/static/images/logo-white.png" alt="Medcraze" /></figure>
                <p>MEDcraze is a unique online destination built by healthcare providers and you, the consumer.</p>
                <p>MEDcraze is an advocacy platform that allows you to search for the latest information surrounding healthcare and the medical industry at large, with topics and content generated by both clinicians and everyday people.</p>
              </div>
            </Col>

            <Col md={6} lg={4} sm={6}>
              <nav>
                <h4>Important Links</h4>
                <ul>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/editorial-board">Editorial Board</Link>
                  </li>

                  {(user == " " || user == null || user == undefined) ? (
                    ''
                  )
                    :
                    (
                      <>
                        <li>
                          <Link to="/contact-us">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/mediapress-list">Press & Media</Link>
                        </li>
                      </>
                    )}

                  <li>
                    <Link to="/news-list">MedTech News</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-use">Terms of Use</Link>
                  </li>
                </ul>
              </nav>
            </Col>
            <Col md={6} lg={4} sm={6}>
              <div className="social-panel">
                <h4>Social Links</h4>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/MEDCrazeTM/" target="_blank" className="fb"><i className="fab fa-facebook-square"></i></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/medcraze/" target="_blank" className="linkedin"><i className="fab fa-linkedin"></i></a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCqnlOp5-aMxRg1dIgpod6HQ" target="_blank" className="youtube"><i className="fab fa-youtube"></i></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MEDcraze" target="_blank" className="twitter"><i className="fab fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/medcraze/" target="_blank" className="insta"><i className="fab fa-instagram"></i></a>
                  </li>
                </ul>
                <div className="app-store">
                  <p><a href="https://apps.apple.com/us/app/medcraze/id1638147946" target="_blank"><img src="/static/images/app-icon-app-store.png" alt="Medcraze" /></a></p>
                  <p><a href="https://play.google.com/store/apps/details?id=com.medcraze" target="_blank"><img src="/static/images/app-icon-play-store.png" alt="Medcraze" /></a></p>
                </div>
              </div>
            </Col>
            {/* <Col md={12} lg={3}>
              <div className="member-list">
                <h4>Our Contributors</h4>
                <ul>
                  {profileData?.records ? profileData?.records.slice(0, 15).map((profile) => (
                    <li key={profile.user_id}>
                      <a>
                        {profile.profileMedia ?
                          <img src={imgPath + profile.profileMedia} alt="Medcraze" />
                          :
                          <img src='/static/images/profile-placeholder.png' alt="Medcraze" />
                        }

                      </a>
                    </li>
                  )) :
                    ''
                  }
                </ul>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <Container>
          <Row className="align-items-center">
            <Col md={12} className="copy">
              copyright &copy; 2022 All rights reserved.
            </Col>
            {/* <Col md={6} className="social-panel">
              <ul>
                <li>
                  <a href="#" className="fb"><i className="fab fa-facebook-square"></i></a>
                </li>
                <li>
                  <a href="#" className="linkedin"><i className="fab fa-linkedin"></i></a>
                </li>
                <li>
                  <a href="#" className="youtube"><i className="fab fa-youtube"></i></a>
                </li>
                <li>
                  <a href="#" className="twitter"><i className="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#" className="insta"><i className="fab fa-instagram"></i></a>
                </li>
              </ul>
            </Col> */}
          </Row>
        </Container>
      </div>
    </footer >
  )
}

export default Footer