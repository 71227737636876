import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Formik } from "formik";
import * as Yup from "yup";
import { changePassword } from "../../services";

const ResetPassword = (props) => {
  const [alertMessage, setAlertMessage] = useState("");

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(1, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  return (
    <>
      {alertMessage && (
        <Alert
          variant="danger"
          onClose={() => {
            setAlertMessage("");
          }}
          dismissible
        >
          <p>{alertMessage}</p>
        </Alert>
      )}
      <Formik
        validationSchema={ResetPasswordSchema}
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        onSubmit={(values) => {
          let params = {
            type: "email",
            source: props.forgotPasswordEmail,
            password: values.password,
          };

          changePassword(params)
            .then((response) => {
              // console.log(response);
              setAlertMessage("");
              props.handleChangedPasswordPanel("block");
            })
            .catch((error) => {
              props.handleChangedPasswordPanel("none");
              console.error("verifyOTP --- ", error);
              let message = error.response?.data?.message;
              if (message) {
                setAlertMessage(message);
              }
            });
        }}
      >
        {({ handleChange, handleSubmit, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit} method="post">
            <div className="otp-list"></div>
            <Form.Group className="form-group">
              <Form.Control
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
                placeholder="Password *"
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Control
                type="password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                isInvalid={!!errors.confirmPassword}
                placeholder="Confirm Password *"
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group">
              <div className="modal-action-btns mt-30">
                <Button type="submit" className="btn-blue w-100">
                  Send
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;
