import React from "react";

const ErrorMessage = (props) => {
    const errors = props.errors;
    const name = props.name;
    
    return ( <>
        <small className="text-danger">
            { errors && errors[name] ? errors[name].message : ''}
        </small>
    </> )
}    

export default ErrorMessage;
    