import React, { useEffect, useState } from 'react'
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { getSpotlightTechnologies } from "../services";
import { imgPath } from "../services";
import { Link, useNavigate } from 'react-router-dom';
import Search from './Search';
import Spinner from './common/Spinner';
import GoogleAds from './GoogleAds';

function SpotlightTechnologiesList() {

  const [techList, setTechList] = useState([])
  const [isLoading, setLoading] = useState(true);
  // const navigate = useNavigate();

  const getTechnologiesListCall = async (searchKey = "") => {
    let params = { "pageSize": 1000, "pageNumber": 1, "name": searchKey };
    let response = await getSpotlightTechnologies(params);
    let data = response.data?.data;
    setLoading(false)
    setTechList(data);
  }
  useEffect(() => {
    getTechnologiesListCall()
  }, []);

  const searchList = (values) => {
    getTechnologiesListCall(values.searchText);
  }

  // const redirectView = (data) => {
  //   navigate('/spotlighttech-detail', {
  //     query: {
  //       id: data.id, name: data.name, media: JSON.stringify(data.media), description: data.description,
  //       tags: JSON.stringify(data.tags), manufactureLink: data.manufactureLink, sources: JSON.stringify(data.sources),
  //       caseStudies: JSON.stringify(data.caseStudies),
  //       user_id: data.user_id
  //     }
  //   });
  // }

  const getExtension = (filename) => {
    //const parts = filename.split('.');
    const parts = filename.split(/\.(?=[^\.]+$)/);

    return parts[parts.length - 1];
  };
  const isVideoFormat = (filename) => {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mpeg":
      case "mp4":
      case "webm":
      case "mkv":
      case "flv":
        // etc
        return true;
    }
    return false;
  };

  return (
    <main className="main-container spotlight-list-page">
      {isLoading && <div className="page-spinner"><div className="white-box"><Spinner /></div></div>}
      <div className="search-section mb-50">
        <Container>
          <Search searchList={searchList} />
        </Container>
      </div>
      <section className="spot-listing-panel">
        <Container>
          <div className="sub-title">
            <h2 className="text-capitalize">Spotlight Technologies</h2>
          </div>
          <Row className="mb-50">
            {techList?.records ? techList?.records.map((list) => (
              <Col md={4} sm={6}>
                <div className="spotlight-item mb30">
                  <Link
                    to={`/spotlighttech-detail/${list.id}`}
                    state={list}
                  >
                    <figure>
                      {list?.media[0] ?
                        (
                          isVideoFormat(
                            list?.media[0]
                          ) ? (
                            <div className="topic-gallery-video">
                              <video
                                width="100%"
                                height="260"
                              >
                                <source
                                  src={
                                    imgPath +
                                    list?.media[0]
                                  }
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          ) : (
                            <a
                              href={
                                imgPath +
                                list?.media[0]
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={
                                  imgPath +
                                  list?.media[0]
                                }
                                alt="medcraze"
                              />
                            </a>
                          )
                        )
                        :
                        <img
                          src='/static/images/placeholder.jpg'
                          alt="Medcraze"
                        />
                      }
                      <figcaption>
                        <h4 className="text-uppercase">
                          {list?.name}
                        </h4>
                        <p className="single-line-ellipsis">For {list.topicName}</p>
                      </figcaption>
                    </figure>
                  </Link>
                </div>
              </Col>
            )) :
              <div className="no-record-found text-center">
                No Record Found
              </div>
            }
          </Row>
          <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
        </Container>
      </section>
    </main>
  )
}

export default SpotlightTechnologiesList