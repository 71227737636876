import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getHome, getRecentSearch, addTopicRecentSearch } from "../services";
import { imgPath } from "../services";
import { Link } from "react-router-dom";
import Search from "./Search";
import { getTopicList } from "../services";
import GoogleAds from "./GoogleAds";
import Spinner from "./common/Spinner";
import Emitter from "../components/common/Emitter";
import EventName from "../components/common/EventName";

function Home() {
  let userData = localStorage.getItem("user");
  userData = JSON.parse(userData);
  const userId = userData?.id;
  const token = userData?.auth?.token;

  const [topicData, setTopicData] = useState();
  const [technologyData, setTechnologyData] = useState();
  const [newsData, setNewsData] = useState();
  const [recentSearch, setRecentSearch] = useState();
  const [topicList, setTopicList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setLoading] = useState(true);

  const swiperRef = React.useRef(null);
  const swiperRefTech = React.useRef(null);
  const swiperRefNews = React.useRef(null);

  const getTopicListCall = async (searchKey = "") => {
    if (searchKey != "") {
      let params = { pageSize: 20, pageNumber: 1, name: searchKey };
      // console.log(params);
      let response = await getTopicList(params);
      let data = response.data?.data;
      setTopicList(data);
    }
  };

  const searchList = (values) => {
    setSearchKey(values.searchText);
    getTopicListCall(values.searchText);
  };

  useEffect(() => {
    getHome()
      .then((response) => {
        let data = response.data?.data;
        setTopicData(data?.trendingTopics);
        setTechnologyData(data?.spotlightTechnologies);
        setNewsData(data?.medtechNews);
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSearchData = async (e) => {
    let topicId = e.currentTarget.id;
    let response = await addTopicRecentSearch(topicId, token, true);
    let data = response.data?.data;
  }

  const useOnceCall = (cb, condition = true) => {
    const isCalledRef = useRef(false);
    useEffect(() => {
      if (condition && !isCalledRef.current) {
        isCalledRef.current = true;
        cb();
      }
      Emitter.on(EventName.SHOW_SEARCHES, showRecentSearchData);
      return () => {
        Emitter.off(EventName.SHOW_SEARCHES, showRecentSearchData);
      };
    }, [cb, condition]);
  }

  const getRecentSearchItem = async () => {
    console.log(userId)
    if (userId != " " && token != " " ) {
      let userDataa = localStorage.getItem("user");
      userData = JSON.parse(userDataa);
      const userIds = userData?.id;
      const tokens = userData?.auth?.token;
      let params = { pageSize: 20, pageNumber: 1, user_id: userIds };
      let response = await getRecentSearch(params, tokens);
      let data = response.data?.data;
      setLoading(false)
      setRecentSearch(data);
      // console.log(data)
    }
  };

 

  const showRecentSearchData = () => {
    getRecentSearchItem();
  }

  useOnceCall(() => {
    getRecentSearchItem();
  });

   useEffect(() => {
    getRecentSearchItem();
  }, [userId]);


  return (
    <>
      <main className="main-container">
        {isLoading && <div className="page-spinner"><div className="white-box"><Spinner /></div></div>}
        <Container>
          <Search searchList={searchList} />

          {searchKey.length > 0 && topicList?.records && (
            <section className="topic-listing-panel mb-50">
              <Container>
                <div className="sub-title">
                  <h2 className="text-capitalize">Search Result</h2>
                </div>
                <Row>
                  {topicList?.records.length > 0 ? (
                    topicList?.records.map((list) => (
                      <Col md={4}>
                        <div className="topic-item mb30">
                          <div className="topic-pic">
                            <video
                              src={imgPath + list.introVideo}
                              width="100"
                              height="200"
                            ></video>
                          </div>
                          <div className="topic-info" id={list.id} onClick={handleSearchData}>

                            <Link className="share"
                              to={`/topic-detail/${list.id}/${list.user_id}`}
                            >
                              <i className="fas fa-share-alt"></i>
                            </Link>
                            <h4 className="text-uppercase">
                              <Link
                                to={`/topic-detail/${list.id}/${list.user_id}`}
                              >
                                {list.name}
                              </Link>

                            </h4>
                            <p>
                              {list.firstName} {list.lastName},{" "}
                            </p>
                            <p className="text-capitalize">
                              {list.contributorType}
                            </p>
                            <div className="topic-action">
                              <Link
                                to={`/topic-detail/${list.id}/${list.user_id}`}
                                className="btn btn-blue btn-sm"
                              >
                                Read More
                              </Link>
                              <Link
                                to={`/topic-detail/${list.id}/${list.user_id}`}>
                                <span className="btn btn-gray-light btn-sm btn-radius20">
                                  <i className="fas fa-arrow-up text-blue"></i>{" "}
                                  {list.upvotes}
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <div className="no-record-found text-center">No Record Found</div>
                  )}
                </Row>
              </Container>
            </section>
          )}

          {searchKey.length === 0 && topicData ? (
            <section className="topic-panel mb-50">
              <div className="sub-title">
                <h2 className="text-capitalize">Trending Topics</h2>
                <Link
                  to="/trending-topics"
                  className="btn btn-blue btn-sm ml-auto btn-radius20"
                >
                  View All
                </Link>
              </div>
              <div className="topic-slider">
                <div id="previousButton" onClick={() => swiperRef.current.swiper.slidePrev()} >previousButton</div>
                <div id="nextButton" onClick={() => swiperRef.current.swiper.slideNext()} >NextBtn</div>
                <Swiper
                  spaceBetween={20}
                  slidesPerView={3}
                  ref={swiperRef}
                  modules={[Navigation, Pagination]}
                  pagination={{ clickable: true }}
                  breakpoints={{
                    769: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    576: {
                      slidesPerView: 2,
                    },
                    320: {
                      slidesPerView: 1,
                    },
                  }}
                >
                  {topicData.map((topic) => (
                    <SwiperSlide key={topic.id}>
                      <div className="topic-item">
                        <div className="topic-pic">
                          {/* <img  
                              src={imgPath + topic.profileMedia}
                              alt={topic.name}
                            /> */}
                          <video playsInline width="100%" height="200">
                            <source
                              src={imgPath + topic.introVideo}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                        <div className="topic-info">
                          <Link className="share"
                            to={`/topic-detail/${topic.id}/${topic.user_id}`}
                          >
                            <i className="fas fa-share-alt"></i>
                          </Link>
                          <h4 className="text-uppercase">
                            <Link
                              to={`/topic-detail/${topic.id}/${topic.user_id}`}
                            >
                              {topic.name}
                            </Link>
                          </h4>
                          <p>
                            {topic.firstName} {topic.lastName},{" "}
                            {topic.contributorSubType}
                          </p>
                          <p className="text-capitalize">
                            {topic.contributorType}
                          </p>
                          <div className="topic-action">
                            <Link
                              to={`/topic-detail/${topic.id}/${topic.user_id}`}
                              className="btn btn-blue btn-sm"
                            >
                              Read More
                            </Link>
                            <Link
                              to={`/topic-detail/${topic.id}/${topic.user_id}`}>
                              <span className="btn btn-gray-light btn-sm btn-radius20">
                                <i className="fas fa-arrow-up text-blue"></i>{" "}
                                {topic.upvotes}
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>

              </div>
            </section>
          ) : (
            ""
          )}

          {searchKey.length === 0 && technologyData ? (
            <section className="spotlight-panel mb-50">
              <div className="sub-title">
                <h2>Spotlight Technologies</h2>
                <Link
                  to="/spotlighttech-list"
                  className="btn btn-blue btn-sm ml-auto btn-radius20"
                >
                  View All
                </Link>
              </div>
              <div className="spotlight-slider">
                <div id="previousButton" onClick={() => swiperRefTech.current.swiper.slidePrev()} >previousButton</div>
                <div id="nextButton" onClick={() => swiperRefTech.current.swiper.slideNext()} >NextBtn</div>
                <Swiper
                  spaceBetween={20}
                  slidesPerView={3}
                  ref={swiperRefTech}
                  modules={[Navigation, Pagination]}
                  pagination={{ clickable: true }}
                  breakpoints={{
                    769: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    576: {
                      slidesPerView: 2,
                    },
                    320: {
                      slidesPerView: 1,
                    },
                  }}
                >
                  {technologyData.map((technology) => (
                    <SwiperSlide>
                      <div className="spotlight-item">
                        <Link
                          to={`/spotlighttech-detail/${technology.id}`}
                          state={technology}
                        >
                          <figure>
                            {technology.media[0] ? (
                              <img
                                src={imgPath + technology.media[0]}
                                alt="Medcraze"
                              />
                            ) : (
                              <img
                                src="/static/images/placeholder.jpg"
                                alt="Medcraze"
                              />
                            )}

                            <figcaption>
                              <h4 className="text-uppercase">
                                {technology.name}
                              </h4>
                              <p className="single-line-ellipsis">
                                {technology.description}
                              </p>
                            </figcaption>
                          </figure>
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </section>
          ) : (
            ""
          )}

          {searchKey.length === 0 && newsData ? (
            <section className="news-panel mb-50">
              <div className="sub-title">
                <h2 className="text-capitalize">MEDTech News</h2>
                <Link
                  to="/news-list"
                  className="btn btn-blue btn-sm ml-auto btn-radius20"
                >
                  View All
                </Link>
              </div>
              <div className="news-slider">
                <div id="previousButton" onClick={() => swiperRefNews.current.swiper.slidePrev()} >previousButton</div>
                <div id="nextButton" onClick={() => swiperRefNews.current.swiper.slideNext()} >NextBtn</div>
                <Swiper
                  spaceBetween={20}
                  slidesPerView={3}
                  ref={swiperRefNews}
                  modules={[Navigation, Pagination]}
                  pagination={{ clickable: true }}
                  breakpoints={{
                    769: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    576: {
                      slidesPerView: 2,
                    },
                    320: {
                      slidesPerView: 1,
                    },
                  }}
                >
                  {newsData.map((news) => (
                    <SwiperSlide>
                      <article className="news-article">
                        <a href={news.url} target="_blank" rel="noreferrer">
                          <figure>
                            <img
                              src="/static/images/medtech-news.png"
                              alt="Medcraze"
                            />
                          </figure>
                          <div
                            className="news-info"
                            style={{ background: "rgba(79,192,172,0.85)" }}
                          >
                            <h5 className="text-uppercase">{news.title}</h5>
                          </div>
                        </a>
                      </article>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </section>
          ) : (
            ""
          )}

          {searchKey.length === 0 && recentSearch?.records ? (
            <section className="news-panel mb-50">
              <div className="sub-title">
                <h2 className="text-capitalize">Recent Searches</h2>

                {recentSearch?.records.length > 0 && (
                  <Link
                    to="/recent-search-list"
                    className="btn btn-blue btn-sm ml-auto btn-radius20"
                  >
                    View All
                  </Link>
                )}
              </div>
              <div className="recent-search-panel">
                <Swiper
                  spaceBetween={20}
                  slidesPerView={3}
                  navigation
                  modules={[Navigation, Pagination]}
                  pagination={{ clickable: true }}
                  breakpoints={{
                    769: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    576: {
                      slidesPerView: 2,
                    },
                    320: {
                      slidesPerView: 1,
                    },
                  }}
                >
                  {recentSearch?.records.length > 0 ? (
                    recentSearch?.records.map((recentSearchItem) => (
                      <SwiperSlide>
                        <div className="topic-item recent-search-item">
                          <div className="topic-pic">
                            <video playsInline width="100%" height="200">
                              <source
                                src={imgPath + recentSearchItem.introVideo}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                          <div className="topic-info">
                            <h4 className="text-uppercase">
                              <Link to={`/topic-detail/${recentSearchItem.id}/${recentSearchItem.user_id}`}>{recentSearchItem.name}</Link>
                            </h4>
                            <p>
                              {recentSearchItem.firstName}{" "}
                              {recentSearchItem.lastName}
                            </p>
                            <p>{recentSearchItem.userType}</p>
                            <div className="topic-action">
                              <span className="btn btn-gray-light btn-sm btn-radius20">
                                <i className="fas fa-arrow-up text-blue"></i>{" "}
                                {recentSearchItem.upvotes}
                              </span>
                              <Link to={`/topic-detail/${recentSearchItem.id}/${recentSearchItem.user_id}`} className="share">
                                <i className="fas fa-share-alt"></i>
                              </Link>

                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <div className="no-search-found">No search found</div>
                  )}
                </Swiper>
              </div>
            </section>
          ) : (
            ""
          )}


          <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />


        </Container>
      </main>
    </>
  );
}

export default Home;
