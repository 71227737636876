import React, { useEffect, useState } from "react";
import { fileUrl } from "../../constants";
import { deleteComments, getComments, getFetchProfile, imgPath } from "../../services";
import * as helper from "../../helper";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import GuestUser from "../GuestUser";

const ListReplyComments = (props) => {
  let userData = localStorage.getItem("user");
  userData = JSON.parse(userData);
  const userId = userData?.id;
  const token = userData?.auth?.token;
  
  const [replyCommentsData, setReplyCommentsData] = useState({});

  const [UsrProfileData, setUsrProfileData] = useState([]);
  const [showUserDetail, setShowUserDetail] = useState(false);
  const handleUserDetailClose = () => setShowUserDetail(false);

  const [showDelectComment, setShowDelectComment] = useState(false);
  const handleDelectCommentClose = () => setShowDelectComment(false);
  const handleDelectCommentShow = () => setShowDelectComment(true);
  const [toggleReplied, setToggleReplied] = useState("");
  const [user, setUser] = useState(userData);
  const [guestUser, setGuestUser] = useState(false);



  const getReplyCommentsData = () => {
    const replyData = {
      type: "sub",
      pageSize: 100,
      pageNumber: 1,
      topic_id: props.topicId,
      subId: props.commentId,
    };
    getComments(replyData)
      .then((response) => {
        setReplyCommentsData(response?.data?.data);
      })
      .catch((error) => {
        console.error("getCommentsReply ", error);
      });
  };

  useEffect(() => {
    getReplyCommentsData();
  }, [props.topicId, props.commentId, props.repliesCount,]);

  const handleUserDetailShow = (e) => {
    setShowUserDetail(true);
    const userProfileId = e.currentTarget.id;
    // console.log(userProfileId)

    getFetchProfile(userProfileId, token)
      .then((response) => {
        setUsrProfileData(response.data.data);
        // console.log(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const guestUserAlert = () => {
    setGuestUser(!guestUser);
  }
  const guestUserShow = () => {
    setGuestUser(true);
  }

  const handleCommentDelete = (e, commentId) => {
    deleteComments(commentId, token)
      .then((response) => {
        let data = response.data?.data;
        getReplyCommentsData();
        handleDelectCommentClose(true);
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const isValidLinkURL = (url) => {
    const pattern = /^((http|https|ftp):\/\/)/;
    let link = url;
    if (!pattern.test(url)) {
      link = "http://" + url;
    }

    return link;
  };
  const toggleRepliedView = () => {
    // console.log("clicked")
    setToggleReplied(!toggleReplied)
  }

  return (
    <>
      <GuestUser alertGuestUser={guestUserAlert} toggleAlert={guestUser} />
      <div className="view-all-action  mb-2">
        <a className="cursor-pointer"
          onClick={toggleRepliedView}
        >
          View {props?.repliesCount} Replies
        </a>
      </div>
      <div className={`${toggleReplied ? "show" : "collapse"}`}>
        {replyCommentsData?.records &&
          replyCommentsData?.records.map((replyComment) => (
            <div className={`d-flex media-object mt-3 `}>
              <div className="flex-shrink-0 media-avtar">
                {(user == " " || user == null || user == undefined) ?
                  <a onClick={guestUserShow} className="disabledClickGuest">
                    {replyComment.profileMedia ?
                      <img
                        src={fileUrl + replyComment.profileMedia}
                        className="rounded-circle"
                        width="50"
                        height="50"
                        alt="Medcraze"
                        style={{ border: "solid 1px #3DB4E5" }}
                      />
                      :
                      <img
                        src="/static/images/profile-placeholder.png"
                        className="rounded-circle"
                        width="50"
                        height="50"
                        alt="Medcraze"
                        style={{ border: "solid 1px #3DB4E5" }}
                      />
                    }
                  </a>
                  :
                  <a id={replyComment?.user_id} className="cursor-pointer" onClick={handleUserDetailShow}>
                    {replyComment.profileMedia ?
                      <img
                        src={fileUrl + replyComment.profileMedia}
                        className="rounded-circle"
                        width="50"
                        height="50"
                        alt="Medcraze"
                        style={{ border: "solid 1px #3DB4E5" }}
                      />
                      :
                      <img
                        src="/static/images/profile-placeholder.png"
                        className="rounded-circle"
                        width="50"
                        height="50"
                        alt="Medcraze"
                        style={{ border: "solid 1px #3DB4E5" }}
                      />
                    }
                  </a>
                }
              </div>
              <div className="flex-grow-1 ms-3 media-body">
                <div className="media-comment mb-2" style={{ background: "#DEDEDE" }}>
                  <h5> {replyComment.firstName + " " + replyComment.lastName}</h5>
                  <p>{replyComment.comment}</p>
                  {replyComment.media.length > 0 ? (
                    helper.isVideoFormat(replyComment.media) ? (
                      <div className="comment_media_video">
                        <video width="100%">
                          <source src={fileUrl + replyComment.media} type="video/mp4" />
                        </video>
                      </div>
                    ) : (
                      <div className="media-comment-image">
                        <img src={fileUrl + replyComment.media} />
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
                {/* delete comment */}
                {
                  userId === replyComment.user_id ?
                    <>
                      <a
                        href="javascript:void(0);"
                        onClick={handleDelectCommentShow}
                      // onClick={(e) => {
                      //   handleCommentDelete(e, commentData.id);
                      // }}
                      >
                        Delete
                      </a>
                      <Modal show={showDelectComment} onHide={handleDelectCommentClose} dialogClassName="add-topic-modal contact-modal">
                        <Modal.Body>
                          <Modal.Header closeButton></Modal.Header>
                          <div className="modal-body-info text-center">
                            <p className="font-size20">Are you sure you want to delete comment?</p>
                          </div>
                          <div className="modal-action-btns text-center">
                            <Button type="button" className="btn btn-blue btn-blue-outline mr-1" onClick={handleDelectCommentClose}>
                              No
                            </Button>
                            <Button type="button" className="btn-blue"
                              onClick={(e) => {
                                handleCommentDelete(e, replyComment.id);
                              }}>
                              Yes
                            </Button>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </>
                    :
                    ''
                }
                {/* End delete comment */}
              </div>

            </div>
          ))
        }
        {/* User profile display modal */}
        <Modal
          show={showUserDetail}
          onHide={handleUserDetailClose}
          dialogClassName="profile-modal"
        >
          <Modal.Body className="p-0">
            <div className="modal-body-info profile-modal-info">

              <div className="profile-cover-banner">
                <img src="/static/images/profile-cover.png" alt="Medcraze" />

                <span className="edit-profile-cover-icon">
                  <button className="btn  btn-pink" onClick={() => handleUserDetailClose(true)}>
                    <i className="fas fa-times"></i>
                  </button>
                </span>
              </div>
              <div className="profile-pic-panel">
                <div className="profile-pic">
                  {UsrProfileData?.profileMedia ?
                    <img src={imgPath + UsrProfileData?.profileMedia} alt="Medcraze" />
                    :
                    <img src="/static/images/profile-placeholder.png" alt="Medcraze" />
                  }
                </div>
              </div>
              <div className="profile-form-panel">
                <Container className="mt-30">
                  <Row>
                    <Col md={6}>
                      <div className="form-group">
                        <div className="profile-value">
                          <div className="profile-value-icon">
                            <img src="/static/images/icon-user.png" alt="Medcraze" />
                          </div>
                          <div className="profile-value-info">
                            <h4>First Name</h4>
                            <p className="value-field">{UsrProfileData?.firstName ? `${UsrProfileData?.firstName}` : 'NA'}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group">
                        <div className="profile-value">
                          <div className="profile-value-icon">
                            <img src="/static/images/icon-user.png" alt="Medcraze" />
                          </div>
                          <div className="profile-value-info">
                            <h4>Last Name</h4>
                            <p className="value-field">{UsrProfileData?.lastName ? `${UsrProfileData?.lastName}` : 'NA'}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {UsrProfileData?.userType == "consumer" ?
                      ""
                      :
                      <>
                        <Col md={6}>
                          <div className="form-group">
                            <div className="profile-value">
                              <div className="profile-value-icon">
                                <img src="/static/images/icon-user.png" alt="Medcraze" />
                              </div>
                              <div className="profile-value-info">
                                <h4>Contributor Type</h4>
                                <p className="value-field text-capitalize">{UsrProfileData?.contributorDetails?.contributorType ? `${UsrProfileData?.contributorDetails?.contributorType}` : 'NA'}</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {UsrProfileData?.contributorDetails?.contributorType == "industry representative" ?
                          <Col md={6}>
                            <div className="form-group">
                              <div className="profile-value">
                                <div className="profile-value-icon">
                                  <img src="/static/images/icon-hospital.png" alt="Medcraze" />
                                </div>
                                <div className="profile-value-info">
                                  <h4>Company Name</h4>
                                  <p className="value-field">{UsrProfileData?.contributorDetails?.clinicName ? `${UsrProfileData?.contributorDetails?.clinicName}` : 'NA'}</p>
                                </div>
                              </div>
                            </div>
                          </Col>
                          :
                          <Col md={6}>
                            <div className="form-group">
                              <div className="profile-value">
                                <div className="profile-value-icon">
                                  <img src="/static/images/icon-hospital.png" alt="Medcraze" />
                                </div>
                                <div className="profile-value-info">
                                  <h4>Name of Hospital or Practice Name</h4>
                                  <p className="value-field">{UsrProfileData?.contributorDetails?.clinicName ? `${UsrProfileData?.contributorDetails?.clinicName}` : 'NA'}</p>
                                </div>
                              </div>
                            </div>
                          </Col>
                        }
                      </>
                    }
                    <Col md={6}>
                      <div className="form-group">
                        <div className="profile-value">
                          <div className="profile-value-icon">
                            <img src="/static/images/icon-city.png" alt="Medcraze" />
                          </div>
                          <div className="profile-value-info">
                            <h4>City</h4>
                            <p className="value-field">{UsrProfileData?.city ? `${UsrProfileData?.city}` : 'NA'}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group">
                        <div className="profile-value">
                          <div className="profile-value-icon">
                            <img src="/static/images/icon-state.png" alt="Medcraze" />
                          </div>
                          <div className="profile-value-info">
                            <h4>State</h4>
                            <p className="value-field">{UsrProfileData?.state ? `${UsrProfileData?.state}` : 'NA'}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-group">
                        <div className="profile-value">
                          <div className="profile-value-icon">
                            <img src="/static/images/icon-bio.png" alt="Medcraze" />
                          </div>
                          <div className="profile-value-info">
                            <h4>Bio</h4>
                            <div className="full-control">
                              <div>{UsrProfileData?.contributorDetails?.bio ? `${UsrProfileData?.contributorDetails?.bio}` : 'NA'}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ListReplyComments;
