import React from 'react'
import { Container } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GoogleAds from './GoogleAds';

function EngagementLetter() {
  return (
    <main className="main-container engagement-letter-page">
      <Container>
        <Row className="mb-50">
          <Col md={12} lg={12}>
            <div className="white-box ">
              <div className="sub-title justify-content-center">
                <h2 className="text-blue">Engagement Letter</h2>
              </div>
              <div className="engagement-content-detail">
                <p><strong>Dear Contributor</strong></p>                
                <p>Thank you for your interest in MEDcraze, an innovative platform designed at the intersection of patient, physician, and technology. This Letter sets forth your engagement with MEDcraze as a Content Contributor (“Contributor”). By your acknowledgement, you agree to the following:</p>
                <h5>Role & Services Education</h5>
                <p>MEDcraze exists to educate. As a Contributor, you are expected to help create the clinical technology content that will be featured on the MEDcraze platform. Specifically, your contribution should include one (1) to five (5) medical technology summaries that will be posted to educate MEDcraze platform visitors (“Topic(s)”). </p>
                <h5>Field of Expertise</h5>
                <p>MEDcraze engages Contributors from various clinical and medical industry fields. You are being engaged as a Contributor in the field of so your content creation for the MEDcraze platform will be primarily focused in.</p>
                <h5>Compensation</h5>
                <p>MEDcraze will pay you five hundred dollars ($500.00) for each approved “Main Topic” you contribute, up to five (5) topics. For each “Technology Topic” you submit, MEDcraze will pay you two hundred dollars ($200.00). There is no limit to the number of technology topics you can submit. Please note: Technology Topics must be within your medical orindustry area of expertise. </p>
                <h5>Additional Compensation</h5>
                <p>In addition to Main Topic and Technology Topic fees, you will be eligible for additional compensation opportunities through participation in special projects as designated from time- to-time by MEDcraze. Special project compensation will be approved and set in advance by MEDcraze and in writing prior to the commencement of any services. Special projects may include appearing in marketing videos, creating educational media, and conducting technology/innovation.....</p>
              </div>
            </div>
          </Col>
        </Row>
        <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
      </Container>
    </main>
  )
}

export default EngagementLetter