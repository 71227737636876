import React from "react";
import { v4 as uuidv4 } from 'uuid';
import UploadImage from "./UploadImage";
import { isVideoFormat } from "./utility/Utility";

class UploadMedia extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mediaItem: [null, null],
            mediaUrl: []
        }

        this.isCallOnMount = true;
    }

    componentDidUpdate(prevProps) {      
        
        if(this.props.isEditView && this.isCallOnMount && this.props.data !== null && prevProps.uuId !== this.props.uuId) {
            this.isCallOnMount = false;
            let array = [];
            let tempData = this.props.data;
            
            if(tempData.length > 0) {
                if((typeof tempData[0] !='undefined' && tempData[0] !== null) && isVideoFormat(tempData[0])) {
                    array.push(tempData[0]);
                    tempData.splice(0, 1);
                }                

            } else if(this.props.videoPath) {
                array.push(this.props.videoPath);
            }

            let mediaUrl = [...array, ...tempData];

            if(tempData.length === 0) {
                tempData = [null, null];
            }

            this.setState({mediaItem: tempData, mediaUrl: mediaUrl});
        }
    }

    addMoreImage = () => {
        let data = this.state.mediaItem;
        data.push(null);
        this.setState({ mediaItem: data });
    }

    removeMoreImage = (i) => {
        let data = [...this.state.mediaItem];
        let mediaU = [...this.state.mediaUrl];

        if((i == 0 || i == 1)) {
            data[i] = null; 

        } else {
            data.splice(i, 1);
        } 

        if(mediaU.length > 0 && mediaU[0] !== null && isVideoFormat(mediaU[0])) {
            if(i==0) {
                let ind = i+1;
                mediaU.splice(ind, 1);
            } else {
                let ind = i+1;
                mediaU.splice(ind, 1);
            } 

        } else {
            mediaU.splice(i, 1);
        }

        this.setState({ mediaItem: data, mediaUrl: mediaU}, ()=>{
            this.props.callBack(this.state.mediaUrl);
        });
    }

    removeVideo = () => {
        let mediaurl = this.state.mediaUrl;
        mediaurl.splice(0, 1);
        this.setState({mediaUrl: mediaurl}, () => {
            this.props.callBack(this.state.mediaUrl);
        });
    }

    onImageUpload = (url, i) => {
        let mediaItem = this.state.mediaItem;
        let mediaU = this.state.mediaUrl;

        if(mediaU.length > 0 && mediaU[0] !== null && typeof mediaU != 'undefined' && isVideoFormat(mediaU[0])) {            

            if(i==0) {
                mediaU[i+1] = url;
            } else {
                mediaU[i+1] = url;
            } 

        } else {
            mediaU[i] = url;
        }        

        mediaItem[i] = url;

        this.setState({ mediaUrl: mediaU, mediaItem: mediaItem }, () => {
            this.props.callBack(this.state.mediaUrl);
        });
    }

    onVideoUpload = (url) => {
        let temp = [...this.state.mediaUrl];
        if (temp.length == 0) {
            temp.push(url);

        } else if (typeof temp[0] == 'undefined' || temp[0] == null) {
            temp[0] = url;

        }
         else if (temp[0].split('.')[1] == 'mp4') {
            temp[0] = url;

        } else {
            temp.unshift(url);
        }

        this.setState({ mediaUrl: temp }, () => {
            this.props.callBack(this.state.mediaUrl);
        });
    }

    render() {

        let isVideoExist = this.state.mediaUrl !== null && this.state.mediaUrl.length > 0 && typeof this.state.mediaUrl[0] !== 'undefined' && this.state.mediaUrl[0] !== null && isVideoFormat(this.state.mediaUrl[0]);

        return (<div key={this.props.index}>
            <ul className="add-topic-list">
                <li>
                    <UploadImage url={isVideoExist ? this.state.mediaUrl[0] : null} type={'Video'} callBack={(url) => this.onVideoUpload(url)} />
                    { isVideoExist && isVideoFormat(this.state.mediaUrl[0]) && 
                        <span className="remove-btn" onClick={() => this.removeVideo()}>
                            <i className="fas fa-times" aria-hidden="true"></i>
                        </span> 
                    }
                </li>
                {this.state.mediaItem.map((item, i) => {

                    let isVisible = false; 
                    if(i == 0 || i == 1) {
                        isVisible = typeof item !== 'undefined' && item !== null;
                    } else {
                        isVisible = true;
                    }
                    
                    return <li key={uuidv4()}>
                        <UploadImage url={item} type={'Image'} callBack={(url) => this.onImageUpload(url, i)} />
                        { isVisible && <span className="remove-btn" onClick={() => this.removeMoreImage(i)}>
                                <i className="fas fa-times" aria-hidden="true"></i>
                        </span> }
                    </li>
                })}
                <li className="add-upload-image">
                    {this.state.mediaItem.length < 5 && <button type='button' onClick={() => this.addMoreImage()}>
                        <div className="upload-file">
                            <div className="upload-file-text">
                                <i className="fas fa-plus" aria-hidden="true"></i>
                            </div>                            
                        </div>
                    </button>}
                </li>
            </ul>
        </div>)
    }
}

export default UploadMedia;