import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { getMediaPressList, imgPath } from "../services";
import { Link } from "react-router-dom";
import Spinner from './common/Spinner';
import GoogleAds from "./GoogleAds";

function MediaPressList() {

  const [pressList, setPressList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  let userData = localStorage.getItem("user");
  userData = JSON.parse(userData);
  const token = userData?.auth?.token;


  const getPressListCall = async (records, page) => {
    let response = await getMediaPressList(records, page, token);
    // console.log("response", response);
    let data = response.data?.data;
    setLoading(false);
    setPressList(data);
  }
  useEffect(() => {
    getPressListCall(100, 1)
  }, []);


  return (
    <main className="main-container media-press-list-page">
      {isLoading && <div className="page-spinner"><div className="white-box"><Spinner/></div></div>}
      <section className="media-listing-section">
        <Container>
          <div className="sub-title">
            <h2 className="text-capitalize">Media & Press Releases</h2>
          </div>
          <Row className="mb-50">
            {pressList?.records ? pressList?.records.map((list) => (
              <Col md={4}>
                <article className="press-media-article mb30">
                  <Link
                    to={`/mediapress-detail/${list.id}`}
                    state= {{
                      id: list.id, title: list.title, media: list.media, description: list.description, url: list.url
                    }}
                  >
                    <figure>
                      <img src={list.media ? `${imgPath + list.media}` : '/static/images/medtech-news.png'} alt="Medcraze" />

                      <figcaption className="w-100">{list.title}</figcaption>
                    </figure>
                  </Link>
                </article>
              </Col>
            )) :
            <div className="no-record-found text-center">
              No Record Found
            </div>
            }

          </Row>
          <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
        </Container>
      </section>
    </main>
  )
}

export default MediaPressList