import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Formik } from "formik";
import * as Yup from "yup";
import { forgotPassword } from "../../services";

const ForgotPassword = (props) => {
  const [alertMessage, setAlertMessage] = useState("");

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  return (
    <>
      {alertMessage && (
        <Alert
          variant="danger"
          onClose={() => {
            setAlertMessage("");
          }}
          dismissible
        >
          <p>{alertMessage}</p>
        </Alert>
      )}
      <Formik
        validationSchema={ForgotPasswordSchema}
        initialValues={{
          email: "",
          type: "forgotPassword",
        }}
        onSubmit={(values) => {
          forgotPassword(values)
            .then((response) => {
              setAlertMessage("");
              props.handleOTPModelPanel("block", values);
            })
            .catch((error) => {
              props.handleOTPModelPanel("none");
              console.error("forgotPassword --- ", error);
              let message = error.response?.data?.message;
              if (message) {
                setAlertMessage(message);
              }
            });
        }}
      >
        {({ handleChange, handleSubmit, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit} method="post">
            <Form.Group className="form-group">
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={!!errors.email}
                placeholder="Email *"
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group ">
              <div className="modal-action-btns">
                <Button type="submit" className="btn-blue w-100">
                  Send
                </Button>
              </div>
            </Form.Group>
            <Form.Group className="form-group  text-center">
              <a href="javascript:void(0);" onClick={props.handleSignIn}>
                Back to Login
              </a>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPassword;
