import React, { useEffect, useState } from 'react'
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { getRecentSearch } from "../services";
import { imgPath } from "../services";
import Search from './Search';
import { getTopicList } from "../services";
import Spinner from './common/Spinner';
import { Link } from 'react-router-dom';
import GoogleAds from './GoogleAds';

function RecentSearchList() {
  let userData = localStorage.getItem("user");
  userData = JSON.parse(userData);
  const userId = userData?.id;
  const token = userData?.auth?.token;

  const [recentSearch, setRecentSearch] = useState();
  const [isLoading, setLoading] = useState(true);

  const getRecentSearchItem = async () => {
    let params = { pageSize: 20, pageNumber: 1, user_id: userId };
    let response = await getRecentSearch(params, token);
    // console.log(" Recent response", response);
    let data = response.data?.data;
    setRecentSearch(data);
    setLoading(false);
  };

  useEffect(() => {
    getRecentSearchItem();
  }, []);

  return (
    <main className="main-container recent-search-list-page">
      {isLoading && <div className="page-spinner"><div className="white-box"><Spinner /></div></div>}
      <section className="recent-search-panel">
        <Container>
          <div className="sub-title">
            <h2 className="text-capitalize">Recent Searches</h2>
          </div>
          <Row className="mb-50">
            {(recentSearch?.records.length > 0) ? recentSearch?.records.map((recentSearchItem) => (
              <Col md={4} sm={6} className="mb30">
                <div className="topic-item recent-search-item">
                  <div className="topic-pic">
                    <video playsInline width="100%" height="200">
                      <source
                        src={imgPath + recentSearchItem.introVideo}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="topic-info">
                    <h4 className="text-uppercase">
                      <Link to={`/topic-detail/${recentSearchItem.id}/${recentSearchItem.user_id}`}>{recentSearchItem.name}</Link>
                    </h4>
                    <p>{recentSearchItem.firstName} {recentSearchItem.lastName}</p>
                    <p>{recentSearchItem.userType}</p>
                    <div className="topic-action">
                      <span className="btn btn-gray-light btn-sm btn-radius20">
                        <i className="fas fa-arrow-up text-blue"></i>{" "}
                        {recentSearchItem.upvotes}
                      </span>
                      <Link to={`/topic-detail/${recentSearchItem.id}/${recentSearchItem.user_id}`} className="share">
                        <i className="fas fa-share-alt"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            )) :
              <div className="no-search-found">No search found</div>
            }
          </Row>
          <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
        </Container>
      </section>
    </main>
  )
}

export default RecentSearchList