import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";
import Emitter from './common/Emitter';
import EventName from './common/EventName';

class GuestUser extends Component {
    constructor(props) {
        super(props);
    }

    onButtonClicked = () => {
        Emitter.emit(EventName.SIGNUP_MODAL);
        this.props.alertGuestUser()
    }

    render() {
        return (
            <Modal
                show={this.props.toggleAlert}
                onHide={this.props.alertGuestUser}
                dialogClassName="add-topic-modal contact-modal"
            >
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <div className="modal-body-info text-center">
                        <p className="font-size20">
                            You are not a registered user. Please Sign up to access all the
                            features.
                        </p>
                    </div>
                    <div className="modal-action-btns text-center mb-3">
                        <Button type="button" className="btn-blue" onClick={() => this.onButtonClicked()}>
                            Sign Up Now
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default GuestUser