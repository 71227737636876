import React, { useRef, useState, useEffect } from 'react';
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import ErrorMessage from './common/ErrorMessage';
import { VALIDATION_OPTIONS } from './utility/constant';
import { addTopic, listUser, uploadMedia } from '../services';
import { toast, ToastContainer } from 'react-toastify';
import EvolutionTechnologies from './EvolutionTechnologies';
import EvolutionProcedures from './EvolutionProcedures';
import TopicAddTechnology from './TopicAddTechnology';
import Spinner from './common/Spinner';
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import GoogleAds from './GoogleAds';

const AddTopic = () => {

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [status, setStatus] = useState('');
    const [introVideo, setIntroVideo] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [videoLoading, setVideoLoading] = useState(false);
    const [contrubutorBy, setContrubutorBy] = useState('');
    const [contrubutorData, setContrubutorData] = useState([]);

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    const evolutionRef = useRef(null);
    const proceduresRef = useRef(null);
    const techRef = useRef(null);

    let userData = localStorage.getItem("user");
    userData = JSON.parse(userData);
    const userId = userData?.id;
    const token = userData?.auth?.token;
    const userType = userData?.userType;

    const handleClose = () => {
        setShow(false);
        navigate('/topic-list');

    };

    const getContrubutorByList = async () => {
        let params = { pageSize: 200, pageNumber: 1, userType: 'contributor' };
        let response = await listUser(params);
        if (response.data && response.data.type === 'success') {
            setContrubutorData(response.data.data.records);
        }
    }

    const useOnceCall = (cb, condition = true) => {
        const isCalledRef = useRef(false);

        useEffect(() => {
            if (condition && !isCalledRef.current) {
                isCalledRef.current = true;
                cb();
            }
        }, [cb, condition]);
    }

    useOnceCall(() => {
        getContrubutorByList();
    });

    const onChangeVideo = async (file) => {
        if (file) {
            const duration = await getVideoDuration(file);
            if (duration > 90) {
                toast('Video duration should be less than 90 seconds', { type: "error", autoClose: 2000 });
                return false;
            }

            setVideoLoading(true);
            const formData = new FormData();
            formData.append("file", file, file.name);

            let response = await uploadMedia(formData);

            if (response.data && response.data.type === 'success') {
                setVideoLoading(false);
                setIntroVideo(response.data.data.fd);

            } else {
                setVideoLoading(false);
                toast(response.data.message, { type: "error", autoClose: 2000 });
            }
        }
    }

    const getVideoDuration = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const media = new Audio(reader.result);
                media.onloadedmetadata = () => resolve(media.duration);
            };
            reader.readAsDataURL(file);
            reader.onerror = (error) => reject(error);
        });

    const onSubmit = async (data, e) => {
        e.preventDefault();

        if (introVideo == '' || introVideo == null) {
            toast("Please upload introductory video", { type: "error", autoClose: 2000 });
            return false;
        }

        setLoading(true);

        let params = {
            'user_id': userId,
            'type': 'ADD',
            'topicDetails': { name: data.name, status: status, introVideo: introVideo },
            'evolutionTechnologies': evolutionRef.current.getEvolutionData(),
            'evolutionProcedures': proceduresRef.current.getProceduresData(),
            'technologies': techRef.current.getTechnologyData(),
        };

        const paramSend = JSON.stringify(params);

        let response = await addTopic(paramSend, token);

        if (response.data && response.data.type === 'success') {
            setShow(true);
            setLoading(false);    
            // toast('Topic add successfully', {
            //     type: "success", autoClose: 2000,
            //     onClose: setTimeout(async function () {
            //         setShow(true);
            //         setLoading(false);                    
            //     }, 1000)
            // });
        } else {
            setLoading(false);
            toast('Server error', { type: "error", autoClose: 2000 });
        }
    }

    return (<>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false} />

        {isLoading && <div className="page-spinner"><div className="white-box"><Spinner /></div></div>}
        <main className="main-container add-topic-page">
            <Container>
                {/* {isLoading && <Spinner />} */}

                <div className="white-box mb-50">
                    <div className="sub-title">
                        <h2 className="text-capitalize">Add Topic</h2>
                    </div>
                    <div className="add-topic-panel">
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <Form.Group className="form-group">
                                <Form.Label>Topic Name</Form.Label>
                                {/* <Form.Control type="text" placeholder="Osteoarthritis Of The Hip" /> */}
                                <input type="text" className="form-control" name="fname" placeholder="Topic Name"
                                    {...register("name", VALIDATION_OPTIONS.name)} />
                                <ErrorMessage errors={errors} name='name' />
                            </Form.Group>

                            <div className="form-group">
                                <div className="upload-file upload-file-video">
                                    <input type="file" id="logo-file-input" className="file-blank"
                                        onChange={(e) => onChangeVideo(e.target.files[0])} />
                                    <div className="upload-file-text">
                                        <span className="upload-icon"><i className="fas fa-cloud-upload-alt"></i></span>
                                        <h3>{introVideo != '' ? introVideo : 'Upload Introductory Video'}</h3>
                                        <p className="max-size">90 Sec. Max</p>
                                    </div>
                                    <div className={`spinner-loader ${videoLoading ? '' : 'd-none'}`}>
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <EvolutionTechnologies ref={evolutionRef} isEditView={false} register={register} errors={errors} />

                            <EvolutionProcedures ref={proceduresRef} isEditView={false} register={register} errors={errors} />

                            <TopicAddTechnology ref={techRef} isEditView={false} register={register} errors={errors} />

                            <div className="form-action text-end mt-2 mb-2">
                                <button onClick={() => setStatus('draft')} className="btn btn-blue btn-gray mr-1"
                                    type="submit" disabled={isLoading}>Save as Draft</button>
                                <button onClick={() => setStatus('published')} className="btn btn-blue" type="submit"
                                    disabled={isLoading}>Publish</button>
                            </div>
                        </form>
                    </div>
                </div>
                <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
            </Container>
        </main>

        <Modal show={show} onHide={handleClose} dialogClassName="publish-topic-modal">
            <Modal.Body>
                <Modal.Header closeButton></Modal.Header>
                <div className="modal-body-info text-center">
                    <figure className="mb-20">
                        <img src="/static/images/publish-success.png" alt="Medcraze" className="mx-auto" />
                    </figure>
                    {status == "draft" ?
                        <p className="font-size20">Your topic has been saved as a draft successfully.</p>
                        :
                        <p className="font-size20">Topic Published Successfully</p>
                    }
                </div>
                <div className="modal-action-btns text-center">
                    <Button type="submit" className="btn-blue" onClick={handleClose}>
                        Okay
                    </Button>
                </div>
            </Modal.Body>
        </Modal>

    </>)
}

export default AddTopic;