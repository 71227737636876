import React, { useEffect, useState } from 'react'
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormBoot from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { getFetchProfile, updateProfile, uploadMedia } from "../services";
import { imgPath } from "../services";
import Alert from "react-bootstrap/Alert";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getYear";
import range from "lodash/range";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { isVideoFormat } from './utility/Utility';
import GoogleAds from './GoogleAds';

function MyProfile() {

  let userData = localStorage.getItem("user");
  userData = JSON.parse(userData);
  const userTypeData = userData?.userType;
  const userContrTypeData = userData?.contributorDetails?.contributorType;
  const userId = userData?.id;
  const token = userData?.auth?.token;

  const [user, setUser] = useState(userData);
  const [profileData, setProfileData] = useState([])
  const [items, setItems] = useState([]);
  const [show, setShow] = useState(false)
  const [startDate, setStartDate] = useState(new Date());

  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertMessageSuccess, setAlertMessageSuccess] = useState("");

  const [isLoading, setLoading] = useState(false);
  const [isVideoLoading, setVideoLoading] = useState(false);

  const [profileImage, setProfileImage] = useState("");
  const [businessImage, setBusinessImage] = useState("");
  const [bioVideo, setBioVideo] = useState("");
  const [expertise, setExpertiseOnChange] = useState("");

  const [mediaExpertise, setMediaExpertise] = useState([]);

  const getFetchProfileCall = async () => {
    const items = JSON.parse(localStorage.getItem('user'));
    if (items) {
      setItems(items);
    }
    let response = await getFetchProfile(items.id);
    let data = response.data?.data;

    if (typeof data.dob !== 'undefined' && data.dob !== '') {
      setStartDate(new Date(data.dob));
    }

    data?.profileMedia && setProfileImage(data.profileMedia);

    data?.contributorDetails?.businessCard && setBusinessImage(data.contributorDetails.businessCard);

    data?.contributorDetails?.bioMedia && setBioVideo(data.contributorDetails.bioMedia);

    data?.contributorDetails?.mediaExpertise && setMediaExpertise(Object.values(data.contributorDetails.mediaExpertise));

    setProfileData(data);
  }

  const years = range(1960, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    getFetchProfileCall()
  }, []);

  const onChangeImage = async (file, type, name) => {
    if (file) {
      if (type === 'video') {
        if (!isVideoFormat(file.name)) {
          toast('Please upload valid video format', { type: "error", autoClose: 2000 });
          return false;
        }

        const duration = await getVideoDuration(file);
        if (duration > 300) {
          toast('Video duration should be less than 5 minutes', { type: "error", autoClose: 2000 });
          return false;
        }

      } else if (!['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp'].includes(file.name.split('.')[1])) {
        toast('Please upload valid image format', { type: "error", autoClose: 2000 });
        return false;
      }

      if (type === 'video') {
        setVideoLoading(true);
      } else {
        setLoading(true);
      }

      const formData = new FormData();
      formData.append("file", file, file.name);

      let response = await uploadMedia(formData);
      if (response.data && response.data.type === 'success') {
        setLoading(false);
        setVideoLoading(false);

        if (name === 'profile') {
          setProfileImage(response.data.data.fd);

        } else if (name === 'business') {
          setBusinessImage(response.data.data.fd);

        } else if (name === 'bio') {
          setBioVideo(response.data.data.fd);
        }

      } else {
        setLoading(false);
        setVideoLoading(false);
        toast(response.data.message, { type: "error", autoClose: 2000 });
      }
    }
  }

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  function onChangeFields(e, field, values, setValues) {
    // update dynamic form
    let tempValues = { ...values };
    tempValues[e.target.name] = e.target.value;

    setValues({ ...tempValues });
    // call formik onChange method
    field.onChange(e);
  }

  const onChangeMediaExpertise = () => {
    let data = mediaExpertise;
    data.push(expertise);
    setMediaExpertise(data);
    setExpertiseOnChange("");
  }

  function onSubmit(fields) {

    const object = {
      "id": userId,
      "profileMedia": profileImage,
      "userType": profileData.userType,
      "dob": startDate !== '' && startDate !== null ? startDate.toLocaleDateString("en-US") : '',
      "city": fields.city,
      "gender": fields.gender,
      "state": fields.state,
      "zip": fields.zip,
      "country": fields.country,
      "firstName": fields.firstName,
      "lastName": fields.lastName,
      "NPInumber": fields.NPInumber,
      "clinicName": fields.clinicName,
      "linkedinURL": fields.linkedinURL,
      "contributorSubType": fields.contributorSubType,
      "bio": fields.bio,
      "headshotMedia": "",
      "businessCard": businessImage,
      "bioMedia": bioVideo,
      "mediaExpertise": mediaExpertise
    };

    updateProfile(object, token)
      .then((response) => {
        console.log(response);
        if (response.data && response.data.type === 'success') {
          toast('Profile updated successfully', {
            type: "success", autoClose: 2000,
            onClose: setTimeout(async function () {
              setShow(false)
              getFetchProfileCall();
            }, 1000)
          });
        } else {
          toast('Server error', { type: "error", autoClose: 2000 });
        }
      })
      .catch((error) => {
        toast('Server error', { type: "error", autoClose: 2000 });
      });
  }

  const profileValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
  });

  return (<>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop
      closeOnClick
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false} />
    <main className="main-container profile-page">

      <div className="profile-cover-banner">
        <img src="/static/images/profile-cover.png" alt="Medcraze" />
        {
          show ?
            ""
            :
            <Container>
              <span className="edit-profile-cover-icon">
                {/* <FormBoot.Control type="file" className="file-blank" /> */}
                <button className="btn  btn-pink" onClick={() => setShow(true)}>

                  <i className="fas fa-pencil-alt"></i>
                </button>
              </span>
            </Container>
        }
      </div>

      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: profileData?.firstName,
          lastName: profileData?.lastName,
          clinicName: profileData?.contributorDetails?.clinicName,
          city: profileData?.city,
          linkedinURL: profileData?.contributorDetails?.linkedinURL,
          NPInumber: profileData?.contributorDetails?.NPInumber,
          contributorSubType: profileData?.contributorDetails?.contributorSubType,
          gender: profileData?.gender,
          state: profileData?.state,
          zip: profileData?.zip,
          country: profileData?.country,
          bio: profileData?.contributorDetails?.bio
        }}
        validationSchema={profileValidationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, values, touched, setValues }) => (
          <Form>
            <div className="profile-pic-panel">
              <Container>
                <div className="profile-pic">

                  {profileImage !== '' ?
                    <img src={imgPath + profileImage} alt="Medcraze" />
                    :
                    <img src="/static/images/profile-placeholder.png" alt="Medcraze" />
                  }
                  <span className="edit-profile-pic-icon">
                    <FormBoot.Control type="file" className="file-blank" onChange={(e) => onChangeImage(e.target.files[0], 'image', 'profile')} />
                    {
                      show ?
                        <button className="btn">
                          <i className="fas fa-camera"></i>
                        </button>
                        :
                        null
                    }
                  </span>
                  <div className={`spinner-loader ${isLoading ? '' : 'd-none'}`}>
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <div className="profile-form-panel">
              <Container className="mb-50 mt-50">
                <Row>
                  <Col md={6}>
                    <FormBoot.Group className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-user.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>First Name</h4>
                          {
                            show ?
                              <div className="profile-control">
                                <Field name="firstName">
                                  {({ field }) => (
                                    <input {...field} value={field.value || ''} className={'form-control'}
                                      onChange={(e) => onChangeFields(e, field, values, setValues)}
                                    />
                                  )}
                                </Field>
                                <div className="text-danger">
                                  {errors.firstName}
                                </div>
                              </div>
                              :
                              <p className="value-field">{profileData?.firstName ? `${profileData?.firstName}` : 'NA'}</p>
                          }

                        </div>
                      </div>
                    </FormBoot.Group>
                  </Col>
                  <Col md={6}>
                    <FormBoot.Group className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-user.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>Last Name </h4>
                          {
                            show ?
                              <div className="profile-control">
                                <Field name="lastName">
                                  {({ field }) => (
                                    <input {...field} value={field.value || ''} className={'form-control'}
                                      onChange={(e) => onChangeFields(e, field, values, setValues)}
                                    />
                                  )}
                                </Field>
                                <div className="text-danger">
                                  {errors.lastName}
                                </div>
                              </div>
                              :
                              <p className="value-field">{profileData?.lastName ? `${profileData?.lastName}` : 'NA'}</p>
                          }

                        </div>
                      </div>
                    </FormBoot.Group>
                  </Col>
                  <Col md={6}>
                    <FormBoot.Group className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-phone.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>Phone Number</h4>
                          <p className="value-field">{profileData?.phone ? `${profileData?.phone}` : 'NA'}</p>
                        </div>
                      </div>
                    </FormBoot.Group>
                  </Col>
                  <Col md={6}>
                    <FormBoot.Group className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-email.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>Email</h4>
                          <p className="value-field">{profileData?.email ? `${profileData?.email}` : 'NA'}</p>
                        </div>
                      </div>
                    </FormBoot.Group>
                  </Col>
                  {(user == " " || user == null || user == undefined || userTypeData == "consumer") ? (
                    ''
                  )
                    :
                    (
                      <>
                        <Col md={6}>
                          <FormBoot.Group className="form-group">
                            <div className="profile-value">
                              <div className="profile-value-icon">
                                <img src="/static/images/icon-user.png" alt="Medcraze" />
                              </div>
                              <div className="profile-value-info">
                                <h4>Contributor Type</h4>
                                <p className="value-field">{profileData?.contributorDetails?.contributorType ? `${profileData?.contributorDetails?.contributorType}` : 'NA'}</p>
                              </div>
                            </div>
                          </FormBoot.Group>
                        </Col>
                        <Col md={6}>
                          <FormBoot.Group className="form-group">
                            <div className="profile-value">
                              <div className="profile-value-icon">
                                <img src="/static/images/icon-hospital.png" alt="Medcraze" />
                              </div>
                              <div className="profile-value-info">
                                <h4>Name of Hospital</h4>
                                {
                                  show ?
                                    <div className="profile-control">
                                      <Field name="clinicName">
                                        {({ field }) => (
                                          <input {...field} value={field.value || ''} className={'form-control'}
                                            onChange={(e) => onChangeFields(e, field, values, setValues)} />
                                        )}
                                      </Field>
                                    </div>
                                    :
                                    <p className="value-field">{profileData?.contributorDetails?.clinicName ? `${profileData?.contributorDetails?.clinicName}` : 'NA'}</p>
                                }

                              </div>
                            </div>
                          </FormBoot.Group>
                        </Col>
                      </>
                    )
                  }
                  <Col md={6}>
                    <FormBoot.Group className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-dob.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>Date of Birth</h4>
                          {
                            show ?
                              <div className="profile-control">
                                {/* <FormBoot.Control type="text" value={profileData?.dob} /> */}
                                <DatePicker
                                  className="form-control"
                                  renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                  }) => (
                                    <div
                                      style={{
                                        margin: 10,
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                        {"<"}
                                      </button>
                                      <select
                                        value={getYear(date)}
                                        onChange={({ target: { value } }) => changeYear(value)}
                                      >
                                        {years.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>

                                      <select
                                        value={months[getMonth(date)]}
                                        onChange={({ target: { value } }) =>
                                          changeMonth(months.indexOf(value))
                                        }
                                      >
                                        {months.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>

                                      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                        {">"}
                                      </button>
                                    </div>
                                  )}
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                />
                              </div>
                              :
                              <p className="value-field">{profileData?.dob ? `${profileData?.dob}` : 'NA'}</p>
                          }
                        </div>
                      </div>
                    </FormBoot.Group>
                  </Col>
                  <Col md={6}>
                    <FormBoot.Group className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-gender.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>Gender</h4>
                          {
                            show ?
                              <div className="profile-control">
                                <Field name="gender">
                                  {({ field }) => (
                                    <select
                                      {...field}
                                      className={'form-control'}
                                      onChange={(e) =>
                                        onChangeFields(e, field, values, setValues)
                                      }
                                      value={field.value || ''}
                                    >
                                      <option>Select Gender</option>
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                    </select>
                                  )}
                                </Field>
                              </div>
                              :
                              <p className="value-field">{profileData?.gender ? `${profileData?.gender}` : 'NA'}</p>
                          }
                        </div>
                      </div>
                    </FormBoot.Group>
                  </Col>
                  <Col md={6}>
                    <FormBoot.Group className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-city.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>City</h4>
                          {
                            show ?
                              <div className="profile-control">
                                <Field name="city">
                                  {({ field }) => (
                                    <input {...field} value={field.value || ''} className={'form-control'}
                                      onChange={(e) => onChangeFields(e, field, values, setValues)} />
                                  )}
                                </Field>
                              </div>
                              :
                              <p className="value-field">{profileData?.city ? `${profileData?.city}` : 'NA'}</p>
                          }
                        </div>
                      </div>
                    </FormBoot.Group>
                  </Col>
                  <Col md={6}>
                    <FormBoot.Group className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-state.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>State</h4>
                          {
                            show ?
                              <div className="profile-control">
                                <Field name="state">
                                  {({ field }) => (
                                    <input {...field} value={field.value || ''} className={'form-control'}
                                      onChange={(e) => onChangeFields(e, field, values, setValues)}
                                    />
                                  )}
                                </Field>
                              </div>
                              :
                              <p className="value-field">{profileData?.state ? `${profileData?.state}` : 'NA'}</p>
                          }
                        </div>
                      </div>
                    </FormBoot.Group>
                  </Col>
                  <Col md={6}>
                    <FormBoot.Group className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-zip.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>Zip</h4>
                          {
                            show ?
                              <div className="profile-control">
                                <Field name="zip">
                                  {({ field }) => (
                                    <input {...field} value={field.value || ''} className={'form-control'}
                                      onChange={(e) => onChangeFields(e, field, values, setValues)}
                                    />
                                  )}
                                </Field>
                              </div>
                              :
                              <p className="value-field">{profileData?.zip ? `${profileData?.zip}` : 'NA'}</p>
                          }
                        </div>
                      </div>
                    </FormBoot.Group>
                  </Col>
                  <Col md={6}>
                    <FormBoot.Group className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-country.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>Country</h4>
                          {
                            show ?
                              <div className="profile-control">
                                <Field name="country">
                                  {({ field }) => (
                                    <input {...field} className={'form-control'}
                                      onChange={(e) => onChangeFields(e, field, values, setValues)}
                                    />
                                  )}
                                </Field>
                              </div>
                              :
                              <p className="value-field">{profileData?.country ? `${profileData?.country}` : 'NA'}</p>
                          }
                        </div>
                      </div>
                    </FormBoot.Group>
                  </Col>
                  {(user == " " || user == null || user == undefined || userTypeData == "consumer") ? (
                    ''
                  )
                    :
                    (
                      <>
                        <Col md={6}>
                          <FormBoot.Group className="form-group">
                            <div className="profile-value">
                              <div className="profile-value-icon">
                                <img src="/static/images/icon-npi.png" alt="Medcraze" />
                              </div>
                              <div className="profile-value-info">
                                <h4>NPI Number</h4>
                                {
                                  show ?
                                    <div className="profile-control">
                                      <Field name="NPInumber">
                                        {({ field }) => (
                                          <input {...field} value={field.value || ''} className={'form-control'}
                                            onChange={(e) => onChangeFields(e, field, values, setValues)} />
                                        )}
                                      </Field>
                                    </div>
                                    :
                                    <p className="value-field">{profileData?.contributorDetails?.NPInumber ? `${profileData?.contributorDetails?.NPInumber}` : 'NA'}</p>
                                }

                              </div>
                            </div>
                          </FormBoot.Group>
                        </Col>
                        <Col md={6}>
                          <FormBoot.Group className="form-group">
                            <div className="profile-value">
                              <div className="profile-value-icon">
                                <img src="/static/images/icon-linkedin.png" alt="Medcraze" />
                              </div>
                              <div className="profile-value-info">
                                <h4>Linkedin URL</h4>
                                {
                                  show ?
                                    <div className="profile-control">
                                      <Field name="linkedinURL">
                                        {({ field }) => (
                                          <input {...field} value={field.value || ''} className={'form-control'}
                                            onChange={(e) => onChangeFields(e, field, values, setValues)} />
                                        )}
                                      </Field>
                                    </div>
                                    :
                                    <p className="value-field">{profileData?.contributorDetails?.linkedinURL ? `${profileData?.contributorDetails?.linkedinURL}` : 'NA'}</p>
                                }

                              </div>
                            </div>
                          </FormBoot.Group>
                        </Col>
                        {(user == " " || user == null || user == undefined || userContrTypeData == "Clinician") ? (
                          <Col md={6}>
                            <FormBoot.Group className="form-group">
                              <div className="profile-value">
                                <div className="profile-value-icon">
                                  <img src="/static/images/icon-clinician.png" alt="Medcraze" />
                                </div>
                                <div className="profile-value-info">
                                  <h4>Type of Clinician</h4>
                                  {
                                    show ?
                                      <div className="profile-control">
                                        <Field name="contributorSubType">
                                          {({ field }) => (
                                            <input {...field} value={field.value || ''} className={'form-control'}
                                              onChange={(e) => onChangeFields(e, field, values, setValues)} />
                                          )}
                                        </Field>
                                      </div>
                                      :
                                      <p className="value-field">{profileData?.contributorDetails?.contributorSubType ? `${profileData?.contributorDetails?.contributorSubType}` : 'NA'}</p>
                                  }

                                </div>
                              </div>
                            </FormBoot.Group>
                          </Col>
                        )
                          :
                          (
                            ""
                          )
                        }

                        <Col md={12}>
                          <Row>
                            <Col md={4}>
                              <div className="profile-update-card mb15">
                                <h4 className="text-blue text-center">Business Card</h4>

                                {show ? <div className="upload-file upload-file-video">
                                  <input type="file" id="logo-file-input" className="file-blank"
                                    onChange={(e) => onChangeImage(e.target.files[0], 'image', 'business')} />

                                  {businessImage !== '' ? <>
                                    {/* <i style={{ position: 'absolute', top: -18, right: 0, cursor: 'pointer' }} className="fas fa-times"
                                      onClick={() => setBusinessImage("")} /> */}
                                    <img width={'100%'} height={'100%'} src={imgPath + businessImage}
                                      alt="Medcraze" />
                                  </>
                                    :
                                    <div className="upload-file-text">
                                      <span className="upload-icon"><i className="fas fa-cloud-upload-alt" aria-hidden="true"></i></span>
                                      <h5>Upload File</h5>
                                      <p className="max-size">5 Min. Max</p>
                                    </div>
                                  }

                                  <div className={`spinner-loader ${isLoading ? '' : 'd-none'}`}>
                                    <div className="spinner-border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </div>
                                </div>
                                  :
                                  profileData?.contributorDetails?.businessCard && <>
                                    <figure>
                                      {profileData?.contributorDetails?.businessCard ?
                                        <img src={imgPath + profileData?.contributorDetails.businessCard} alt="Medcraze" />
                                        :
                                        <img src="/static/images/placeholder.jpg" alt="Medcraze" />
                                      }
                                    </figure>
                                  </>
                                }
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="profile-update-video mb15">
                                <h4 className="text-blue text-center">Bio Video</h4>
                                {show ? <div className="upload-file upload-file-video">
                                  <input type="file" id="logo-file-input" className="file-blank"
                                    onChange={(e) => onChangeImage(e.target.files[0], 'video', 'bio')}
                                  />

                                  {bioVideo !== '' ? <>
                                    {/* <i style={{ position: 'absolute', top: -18, right: 0, cursor: 'pointer' }} className="fas fa-times"
                                      onClick={() => setBioVideo("")} /> */}
                                    <video width="100%" height="160" controls>
                                      <source src={imgPath + bioVideo} type="video/mp4" />
                                    </video>
                                  </>
                                    :
                                    <div className="upload-file-text">
                                      <span className="upload-icon"><i className="fas fa-cloud-upload-alt" aria-hidden="true"></i></span>
                                      <h5>Upload File</h5>
                                    </div>
                                  }

                                  <div className={`spinner-loader ${isVideoLoading ? '' : 'd-none'}`}>
                                    <div className="spinner-border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </div>
                                </div>
                                  :
                                  profileData?.contributorDetails?.bioMedia && <>
                                    <div className="profile-video">
                                      {profileData?.contributorDetails?.bioMedia ?
                                        <video width="100%" height="182" controls>
                                          <source src={imgPath + profileData?.contributorDetails?.bioMedia} type="video/mp4" />
                                        </video>
                                        :
                                        <img src="/static/images/placeholder.jpg" alt="Medcraze" />
                                      }
                                    </div>
                                  </>
                                }
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    )
                  }

                  <Col md={12}>
                    <FormBoot.Group className="form-group">
                      <div className="profile-value">
                        <div className="profile-value-icon">
                          <img src="/static/images/icon-bio.png" alt="Medcraze" />
                        </div>
                        <div className="profile-value-info">
                          <h4>Bio</h4>
                        </div>
                      </div>
                      {
                        show ?
                          <div className="full-control">
                            <Field name="bio">
                              {({ field }) => (
                                <textarea {...field} className={'form-control'} placeholder="Description Here"
                                  onChange={(e) => onChangeFields(e, field, values, setValues)}
                                />
                              )}
                            </Field>
                          </div>
                          :
                          <div className="full-control">
                            <FormBoot.Control as="textarea" disabled placeholder="Description Here" value={profileData?.contributorDetails?.bio} />
                          </div>
                      }

                    </FormBoot.Group>
                  </Col>
                  {(user == " " || user == null || user == undefined || userTypeData == "consumer") ? (
                    ''
                  )
                    :
                    (
                      <Col md={12}>
                        <FormBoot.Group className="form-group">
                          <div className="expert-list">
                            <h4>Medical Expertise List</h4>

                            {
                              show ?
                                <div className="profile-control">
                                  <input className={'form-control'} value={expertise}
                                    onChange={(e) => setExpertiseOnChange(e.target.value)} />

                                  <p>{mediaExpertise.join(', ')}</p>
                                </div>
                                :
                                <ul className="list-style-none">
                                  {profileData?.contributorDetails?.mediaExpertise ? (
                                    Object.keys(profileData.contributorDetails.mediaExpertise).map((key) => (
                                      <li>{profileData.contributorDetails.mediaExpertise[key]}</li>
                                    ))
                                  ) : (
                                    <li>No Record Found</li>
                                  )}
                                </ul>
                            }
                            {
                              show ?
                                <div className="form-action text-end mt-2 mb-2">
                                  <Button type="button" className="btn-blue btn-md btn-radius20" onClick={() => onChangeMediaExpertise()}>
                                    Add
                                  </Button>
                                </div>
                                :
                                null
                            }
                          </div>
                        </FormBoot.Group>
                      </Col>
                    )
                  }
                  {
                    show ?
                      <Col md={12}>
                        <FormBoot.Group className="form-group">
                          <div className="form-action text-end mt-2 mb-2">
                            <Button type="submit" className="btn-blue w-100 font-size20">
                              Save
                            </Button>
                          </div>
                        </FormBoot.Group>
                      </Col>
                      :
                      null
                  }

                </Row>
              </Container>
              <Container>
              <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
              </Container>
            </div>

          </Form>
        )}
      </Formik>

    </main>
  </>)
}

export default MyProfile