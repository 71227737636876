import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { addRequest } from "../services";
import Alert from "react-bootstrap/Alert";
import * as Yup from "yup";
import { Formik } from "formik";
import { useLocation } from 'react-router';
import GoogleAds from './GoogleAds';

function ContactUs() {

  const fetchData = useLocation();
  const topicId = fetchData?.state?.id;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // console.log('fetched data', topicId)

  let userData = localStorage.getItem("user");
  userData = JSON.parse(userData);
  const userId = userData?.id;
  const token = userData?.auth?.token;

  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertMessageSuccess, setAlertMessageSuccess] = useState("");

  const contactValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  return (
    <main className="main-container contact-page">
      <Container>
        <Row className="justify-content-center mb-50">
          <Col md={8} lg={6}>
            <div className="white-box contact-panel">
              <div className="sub-title justify-content-center">
                <h2>Contact Us</h2>
              </div>
              <h4 className="text-blue text-uppercase">Send Us a Message</h4>
              {alertMessage && (
                <Alert
                  variant="danger"
                  onClose={() => {
                    setAlertShow(false);
                    setAlertMessage("");
                  }}
                  dismissible
                >
                  {alertMessage}
                </Alert>
              )}
              {alertMessageSuccess && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setAlertShow(false);
                    setAlertMessageSuccess("");
                  }}
                  dismissible
                >
                  {alertMessageSuccess}
                </Alert>
              )}
              <Formik
                validationSchema={contactValidationSchema}
                onSubmit={(values) => {
                  addRequest(values, token)
                    .then((response) => {
                      // console.log("data", response.data?.data);
                      let message = response?.data?.message;
                      if (message) {
                        setAlertShow(true);
                        setAlertMessageSuccess(message);
                      }
                      setShow(true)
                    })
                    .catch((error) => {
                      // console.error("contact --- ", error);
                      let message = error.response?.data?.message;
                      if (message) {
                        setAlertShow(true);
                        setAlertMessage(message);
                      }
                    });
                }}
                initialValues={{
                  subject: "",
                  message: "",
                  topic_id: topicId,
                  user_id: userId
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="form-group">
                      <Form.Label>Subject *</Form.Label>
                      <Form.Control
                        type="text"
                        name="subject"
                        value={values.subject}
                        onChange={handleChange}
                        isInvalid={!!errors.subject}
                        placeholder="subject"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.subject}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Message *</Form.Label>
                      <Form.Control
                        name="message"
                        as="textarea"
                        value={values.message}
                        onChange={handleChange}
                        isInvalid={!!errors.message}
                        placeholder="Enter your message"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="form-group ">
                      <div className="modal-action-btns">
                        <Button type="submit" className="btn-blue w-100">
                          Send Message
                        </Button>
                      </div>
                    </Form.Group>
                  </Form>
                )}
              </Formik>

              {/* <Form onSubmit={handleSubmit}>
                <h3 className="text-blue">SEND US A MESSAGE</h3>
                <Form.Group className="form-group">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" value={message} onChange={(e) => setMessage(e.target.value)} />
                </Form.Group>
                <Form.Group className="form-group">
                  <div className="form-action text-end mt-3">
                    <Button type="submit" className="btn-blue w-100" onClick={handleShow}>
                      Send Message
                    </Button>
                  </div>
                </Form.Group>
              </Form> */}

            </div>
          </Col>
        </Row>
        <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
      </Container>

      <Modal show={show} onHide={handleClose} dialogClassName="add-topic-modal contact-modal">
        <Modal.Body>
          <Modal.Header closeButton></Modal.Header>
          <div className="modal-body-info text-center">
            <p className="font-size20">We have received your request and will respond soon.</p>
          </div>
          <div className="modal-action-btns text-center">
            <Button type="submit" className="btn-blue" onClick={handleClose}>
              Okay
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  )
}

export default ContactUs