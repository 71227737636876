export const isVideoFormat = (filename) => {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mpeg":
    case "mp4":
    case "webm":
    case "mkv":
    case "flv":
      return true;
  }
  return false;
};

export const getVideoDuration = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });

export const getExtension = (filename) => {
  const parts = filename.split(/\.(?=[^\.]+$)/);
  return parts[parts.length - 1];
};
