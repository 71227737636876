import React from 'react'
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation, useParams } from "react-router-dom";
import { imgPath } from "../services";
import GoogleAds from './GoogleAds';

function MediaPressDetail() {

  const fethcData = useLocation();
  // console.log('fetch datat', fethcData)

  return (
    <main className="main-container media-press-detail-page">
      <Container>
        <div className="white-box mb-50">
          <div className="sub-title">
            <h2 className="text-uppercase">{fethcData.state.title}</h2>
          </div>
          <div className="media-press-detail-content">
            {fethcData.state.media ?
              <figure>
                <img src={imgPath + fethcData.state.media} alt="Medcraze" />
              </figure>
              :
              ''
            }

            <p>{fethcData.state.description}</p>
            {fethcData.state.url ?
              <p><a href={fethcData.state.url} target="_blank" rel="noreferrer">Click here</a> to read more.</p>
              :
              ''
            }

          </div>
        </div>
        <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
      </Container>
    </main>
  )
}

export default MediaPressDetail