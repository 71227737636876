 export const IMAGE_PATH = 'https://medcraze.s3.us-west-2.amazonaws.com/';

 export const VALIDATION_OPTIONS = {
    firstName: { required: "First name is required" },
    lastName: { required: "Last name is required" },
    email: { 
        required: "Email is required",
        pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format"
        }
    },
    password: { required: "Password is required" },
    confirmPassword: { required: "Confirm password is required" },
    title: { required: "Title is required" },
    url: { required: "URL is required" },
    subject: { required: "Subject is required" },
    phone: { required: "Phone number is required" },
    message: { required: "Message is required" },
    name: { required: "Name is required" },
    description: { required: "Description is required" }
}