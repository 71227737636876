import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

const ContributorType = (props) => {
  const ContributorSignupSchema = Yup.object().shape({
    contributorType: Yup.string().required("Contributor Type is required"),
  });

  return (
    <Formik
      validationSchema={ContributorSignupSchema}
      initialValues={{
        contributorType: "",
      }}
      onSubmit={(values) => {
        props.handleChangeUserType(values);
        props.handleSignUpContributor();
      }}
    >
      {({ handleChange, handleSubmit, values, errors }) => (
        <Form
          className="contribute-modal-form"
          noValidate
          onSubmit={handleSubmit}
          method="post"
        >
          <Form.Group className="form-group">
            <Form.Select
              name="contributorType"
              className="form-control"
              value={values.contributorType}
              onChange={handleChange}
              isInvalid={!!errors.contributorType}
            >
              <option value="">Select Contributor Type</option>
              <option value="Clinician">Clinician</option>
              <option value="Industry representative">
                Industry representative
              </option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.contributorType}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="mt30">
            <Button
              type="submit"
              className="btn-blue w-100"
            >
              Next
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ContributorType;
