import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import UploadMedia from './UploadMedia';
import { isVideoFormat } from './utility/Utility';

class EvolutionTechnologies extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            evolution: {
                title: '',
                media: [],
                description: ''
            },
            videoPath: null
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data != null && (prevProps.data !== this.props.data)) {
            let media = [];
            let videoPath = null;

            if (this.props.data.media && Object.keys(this.props.data.media).length > 0) {
                Object.keys(this.props.data.media).map(key => {
                    if (this.props.data.media[key] !== null && isVideoFormat(this.props.data.media[key])) {
                        videoPath = this.props.data.media[key];
                    }

                    media.push(this.props.data.media[key]);
                })
            }

            if (media.length > 0) {
                if (media[0] !== null && isVideoFormat(media[0]) && media.length <= 2) {
                    if (media.length === 1) {
                        media.push(null);
                        media.push(null);

                    } else {
                        media.push(null);
                    }

                } else {
                    media.push(null);
                }
            } else {
                media = [null, null];
            }

            let obj = {};
            obj['id'] = this.props.data.id;
            obj['title'] = this.props.data.title;
            obj['media'] = JSON.stringify(media);
            obj['description'] = this.props.data.description;

            this.setState({ evolution: obj, videoPath: videoPath });
        }
    }

    onChangeFields = (e) => {
        let data = this.state.evolution;
        data[e.target.name] = e.target.value;
        this.setState({ evolution: data });
    }

    onImageUpload = (media) => {
        let data = this.state.evolution;
        let temp = [];
        media.map(x => typeof x !== 'undefined' && x !== null && temp.push(x));

        data['media'] = temp;
        this.setState({ evolution: data });
    }

    getEvolutionData = () => {
        let obj = this.state.evolution;

        if (obj.media && !Array.isArray(obj.media)) {
            obj.media = JSON.parse(obj.media).filter(x => x !== null);

        } else if (obj.media.length > 0) {
            obj.media = obj.media.filter(x => x !== null);
        }

        return obj;
    }

    render() {

        return (<>
            <div className="add-topic-column">
                <div className="topic-title">
                    <h3 className="text-nacviblue">
                        Evolution of: Technologies
                    </h3>
                </div>

                <div className="form-group">
                    <label>Title</label>
                    <input name='title' type="text" className="form-control" placeholder="Title"                        
                        {...this.props.register("title", {
                            required: true,
                            onChange: (e) => this.onChangeFields(e)
                        })}
                    />
                    <small className="text-danger">
                        {this.props.errors && this.props.errors['title'] ? 'Title is required' : ''}
                    </small>
                </div>

                <UploadMedia videoPath={this.state.videoPath} isEditView={this.props.isEditView} type='evolution'
                    data={this.state.evolution.media.length > 0 ? Array.isArray(this.state.evolution.media) ? this.state.evolution.media
                        : JSON.parse(this.state.evolution.media) : null}
                    callBack={(media) => this.onImageUpload(media)}
                    uuId={uuidv4()} />

                <div className="form-group">
                    <label>Description</label>
                    <textarea name='description' className="form-control" placeholder="Description"
                        {...this.props.register("description", {
                            required: true,
                            onChange: (e) => this.onChangeFields(e)
                        })}
                    />
                    <small className="text-danger">
                        {this.props.errors && this.props.errors['description'] ? 'Description is required' : ''}
                    </small>
                </div>
            </div>
        </>)
    }
}

export default EvolutionTechnologies;