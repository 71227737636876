import React, { useEffect, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { uploadMedia } from "../services";
import { isVideoFormat } from "./utility/Utility";

const UploadImage = (props) =>  {

    const [isLoading, setLoading] = useState(false);
    const [url, setUrl] = useState(null);


    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }  

    const prevUrl = usePrevious(props.url);

    useEffect(() => {
        if(prevUrl !== props.url) {
            setUrl(props.url);
        }
    }, [props]);      

    const onChangeImage = async (file) => {
        if(file) {

            if(props.type === 'Video') {
                if(!isVideoFormat(file.name)) {
                    toast('Please upload valid video format', { type: "error", autoClose: 2000 });
                    return false;
                } 
                
                const duration = await getVideoDuration(file);
                if(duration > 300) {
                    toast('Video duration should be less than 5 minutes', { type: "error", autoClose: 2000 });
                    return false;
                }

            } else if(!['png','jpg','jpeg','gif','svg','webp'].includes(file.name.split('.')[1])) {
                toast('Please upload valid image format', { type: "error", autoClose: 2000 });
                return false;
            }

            setLoading(true);
            const formData = new FormData();
            formData.append("file", file, file.name);

            let response = await uploadMedia(formData);
            if(response.data && response.data.type === 'success') {
                setLoading(false);
                setUrl(response.data.data.fd);
                props.callBack(response.data.data.fd);

            } else {
                setLoading(false);
                toast(response.data.message, { type: "error", autoClose: 2000 });
            }
        }
    }

    const getVideoDuration = (file) =>
        new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const media = new Audio(reader.result);
            media.onloadedmetadata = () => resolve(media.duration);
        };
        reader.readAsDataURL(file);
        reader.onerror = (error) => reject(error);
    });

    return (<>
        <div className="upload-file upload-file-video">
            <input type="file" id="logo-file-input" className="file-blank"
                onChange={(e) => onChangeImage(e.target.files[0])} />
            <div className="upload-file-text">
                <span className="upload-icon"><i className="fas fa-cloud-upload-alt" aria-hidden="true"></i></span>
                <h5>{url !== null ? url : `Upload ${props.type}`}</h5>
                { props.type === 'Video' && <p className="max-size">5 Min. Max</p> }
            </div>
            <div className={`spinner-loader ${isLoading ? '' : 'd-none'}`}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </>)
    
}

export default UploadImage;