import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import GoogleAds from './GoogleAds';


function AboutUs() {

  const [openItem1, setOpenItem1] = useState(false);
  const [openItem2, setOpenItem2] = useState(false);
  const [openItem3, setOpenItem3] = useState(false);
  const [openItem4, setOpenItem4] = useState(false);

  return (
    <main className="main-container about-us-page">
      <Container>
        <Row className="mb-50">
          <Col md={12} lg={12}>
            <div className="white-box ">
              <div className="sub-title justify-content-center">
                <h2 className="text-blue">About MEDcraze</h2>
              </div>
              <div className="about-detail">
                <p>MEDcraze is a unique online destination built by healthcare providers and you, the consumer. If you have been recently diagnosed, or have an upcoming surgical procedure, you can now obtain unprecedented access to technologies that address your medical circumstance. MEDcraze is an advocacy platform that allows you to search for the latest information surrounding healthcare technology and the medical industry at large, with topics and content generated by clinicians, medical companies, and everyday people. </p>
                
                <p>Medical technology experiences are shared here with the goal of creating patient empowerment for all. We want individuals to realize not only the important role medical innovation plays but the importance of gaining access to this information. This information enables you to truly be an advocate for your own health circumstance and obtain the best care possible in a crazy world we call the healthcare “industry.” We hope the knowledge you receive on our platform will be useful as you navigate your personal healthcare journey.</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-50">
          <Col md={12} lg={12}>
            <div className="Leadership-panel">
              <div className="sub-title justify-content-center">
                <h2 className="text-blue">Leadership Team</h2>
              </div>
              <div className="accordian-panel">
                <ul>
                  <li>
                    <div className="acc-team-panel">
                     
                      <div className="acc-team-pic">
                        <img src="/static/images/team/carey-markey.jpg" alt="Medcraze" />
                      </div>
                      <div className="acc-team-info">
                      <Button
                        onClick={() => setOpenItem1(!openItem1)}
                        aria-controls="example-collapse-text"
                        aria-expanded={openItem1}
                        className="accordian-arrow"
                      >
                        {openItem1 ?
                          <i className="fas fa-chevron-up"></i>
                          :
                          <i className="fas fa-chevron-down"></i>
                        }

                      </Button>
                        <h3>CAREY MARKEY</h3>
                        <h4>MEDcraze Founder & CEO</h4>
                        <div className="acc-team-description">
                          <p>Carey spent a career in the medical and biotech industry and constantly asked himself, “How do patients access the newest medical advancements? How can I help to increase their awareness of technologies that address their medical circumstances so when they see a physician they feel empowered?” </p>
                          <Collapse in={openItem1} dimension="height">
                            <div id="example-collapse-text">
                              <p>Currently, access to new medical advancements is very limited and these limits aren’t just placed on the general public but clinicians as well. Carey wants to change that. </p>
                              <p>Carey’s wife was diagnosed with breast cancer a couple years ago and he experienced this limited access first-hand. But luckily, Carey worked in the breast cancer industry so he could contact companies directly to obtain product information. The real question is, why can’t you have the same access to information that Carey did?</p>
                              <p>Carey created MEDcraze so patients can become advocates for their healthcare, beyond diagnosis – and technology is a significant part of that care. Technology, in most cases, can be the sole determining factor in whether or not your treatment outcome is successful.</p>
                              <p>Throughout his healthcare career he has represented and consulted to both start-up and established medical device organizations. These companies include Tissue Science Laboratories, Allosource, MTF, Johnson and Johnson (Mentor and Ethicon). Most recently, Carey served as the National Hernia Manager for Acell.</p>
                              <p>Carey received a BA degree in Political Science from the University of Georgia.</p>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="acc-team-panel">
                     
                      <div className="acc-team-pic">
                        <img src="/static/images/team/neil-patel.jpg" alt="Medcraze" />
                      </div>
                      <div className="acc-team-info">
                      <Button
                        onClick={() => setOpenItem2(!openItem2)}
                        aria-controls="example-collapse-text2"
                        aria-expanded={openItem2}
                        className="accordian-arrow"
                      >
                        {openItem2 ?
                          <i className="fas fa-chevron-up"></i>
                          :
                          <i className="fas fa-chevron-down"></i>
                        }

                      </Button>
                        <h3>NEIL PATEL</h3>
                        <h4>MEDcraze Senior Advisor</h4>
                        <div className="acc-team-description">
                          <p>Neil Patel co-founded The Daily Caller (<a href="https://dailycaller.com/" rel="noreferrer" target="_blank">www.dailycaller.com</a>), one of America’s fastest growing online news outlets, with his partner, Tucker Carlson, current host of Fox News’ “Tucker Carlson Tonight.” With over 15 million monthly readers, 8 million followers on Facebook and Twitter and a growing YouTube presence, The Daily Caller is breaking news on a regular basis and distributing it broadly throughout America.</p>
                          <Collapse in={openItem2} dimension="height">
                            <div id="example-collapse-text2">
                              <p>Patel also co-founded The Daily Caller News Foundation, a nonprofit news company that trains journalists, conducts longer term investigative reporting and produces fact checks. The Daily Caller News Foundation licenses its content free of charge to over 300 news outlets reaching potentially hundreds of millions of people per month.</p>
                              <p>In addition to his role as publisher of The Daily Caller, Neil Patel is co-founder and managing director of Bluebird Asset Management, a hedge fund investing in mortgage-backed securities.</p>
                              <p>Before starting his two companies, Neil served in the White House from 2005 to 2009 as the chief policy adviser to Vice President Dick Cheney. From 2001 to 2004, Neil was staff secretary to Vice President Cheney. Prior to joining the Bush administration, Neil was assistant general counsel at UUNET Technologies. Earlier in his career, Neil practiced law with Dechert Price & Rhoads. He also served as Counsel to the U.S. House of Representatives Select Committee on U.S. National Security and Military/Commercial Concerns with the People’s Republic of China.</p>
                              <p>Neil received his B.A. from Trinity College in Connecticut and his J.D. from the Georgetown University Law Center, where he served as associate editor of the Journal of Law and Policy in International Business.</p>
                              <p>Neil lives in Washington, D.C., and Jackson Hole, Wyoming, with his wife, Amy, their two daughters, Caroline and Bela, and their son, Charlie.</p>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="acc-team-panel">
                   
                      <div className="acc-team-pic">
                        <img src="/static/images/team/Paul-Headshot.jpg" alt="Medcraze" />
                      </div>
                      <div className="acc-team-info">
                      <Button
                        onClick={() => setOpenItem3(!openItem3)}
                        aria-controls="example-collapse-text2"
                        aria-expanded={openItem3}
                        className="accordian-arrow"
                      >
                        {openItem3 ?
                          <i className="fas fa-chevron-up"></i>
                          :
                          <i className="fas fa-chevron-down"></i>
                        }

                      </Button>
                        <h3>PAUL C. KONIGSBERG</h3>
                        <h4>MEDcraze Director of Investor Relations</h4>
                        <div className="acc-team-description">
                          <p>Paul C. Konigsberg has 20 years of experience in the debt markets spanning origination, trading and portfolio management. Having started several companies himself, he is excited to bring his financial and technical skills & knowledge to the MEDcraze team.</p>
                          <Collapse in={openItem3} dimension="height">
                            <div id="example-collapse-text2">
                              <p>Growing up in northern Virginia he attended Thomas Jefferson High School for Science & Technology and went on to MIT where he was awarded a BS in physics, a BS in electrical engineering, and a ME in electrical engineering. He enjoys technical hobbies and playing golf.</p>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="acc-team-panel">
                    
                      <div className="acc-team-pic">
                        <img src="/static/images/team/chris-avatar.jpg" alt="Medcraze" />
                      </div>
                      <div className="acc-team-info">
                      <Button
                        onClick={() => setOpenItem4(!openItem4)}
                        aria-controls="example-collapse-text2"
                        aria-expanded={openItem4}
                        className="accordian-arrow"
                      >
                        {openItem4 ?
                          <i className="fas fa-chevron-up"></i>
                          :
                          <i className="fas fa-chevron-down"></i>
                        }

                      </Button>
                        <h3>CHRIS ALEXANDER</h3>
                        <h4>MEDcraze CTO and Marketing Director</h4>
                        <div className="acc-team-description">
                          <p>Chris has 15 years of experience in web design, development and online marketing. He grew up in Covington, Georgia and has a Bachelor’s degree in Business Information Systems from the University of West Georgia.</p>
                          <Collapse in={openItem4} dimension="height">
                            <div id="example-collapse-text2">
                              <p>Chris joined MEDcraze in January 2020. He is excited about the potential of MEDcraze to bring new technologies to patients.</p>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
      </Container>
    </main>
  )
}

export default AboutUs