import React from 'react'
import { Container } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GoogleAds from './GoogleAds';

function ConfidentialAggrement() {
  return (
    <main className="main-container confidential-page">
      <Container>
        <Row className="mb-50">
          <Col md={12} lg={12}>
            <div className="white-box ">
              <div className="sub-title justify-content-center">
                <h2 className="text-blue">Confidentiality & Proprietary Right Agreement</h2>
              </div>
              <div className="engagement-content-detail">
                <p>This Confidentiality Agreement (“Agreement”) is between MEDcraze, LLC with its principal offices located at 6825 Jimmy Carter Blvd., Suite 1220-C, Norcross, GA 30071 (“MEDcraze”) and ______________________________ (“Recipient"). This Agreement shall be effective on the date acknowledged by Recipient (“Effective Date”).</p>                
                <p>The Parties acknowledge that MEDcraze has invested and continues to invest substantial time and sums building and maintaining the MEDcraze platform. This Agreement covers Recipient’s commitments to MEDcraze regarding Confidential Information (defined below) and assignment of proprietary rights. MEDcraze may provide Confidential Information to Recipient in connection with Recipient’s proposal for services and/or rendering of services for MEDcraze, if retained. In other instances, Recipient may receive access to Confidential Information in conjunction with the performance of duties as a contributor or other contractor of MEDcraze. Recipient acknowledges and agrees as follows:</p>
                <h5>Nondisclosure</h5>
                <p>Recognition of Company Rights. All Confidential Information (defined below) learned or created by Recipient in conjunction with Recipient’s rendering of services or performance of duties for MEDcraze is and shall remain the sole property of MEDcraze and its assigns. </p>
                <h5>Confidential Information</h5>
                <p>“Confidential Information" means any and all proprietary information of MEDcraze and includes but is not limited to any “trade secrets” as that term is defined by The Georgia Trade Secrets Act, O.C.G.A. § 10-1-760, et seq., or as defined in the Economic Espionage Act of 1996, as amended by the Defend Trade Secrets Act of 2016, 18 U.S.C. § 1836, et seq., as applicable. Confidential Information includes any public or non-public information compiled in a manner that provides an advantage to MEDcraze.</p>
                <ol className="ol-list">
                  <li>related to past, present, or future research, development, or business activities or the results of those activities and/or </li>
                  <li>that Company has received from others and is obligated to treat as confidential...</li>
                </ol>
                
              </div>
            </div>
          </Col>
        </Row>
        <GoogleAds slot="7256055403" googleAdId="ca-pub-3766100354061231" />
      </Container>
    </main>
  )
}

export default ConfidentialAggrement