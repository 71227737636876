import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Formik } from "formik";
import * as Yup from "yup";
import { userSignup, uploadFile } from "../../services";
import { Link } from "react-router-dom";
import * as helper from "../../helper";
import { isVideoFormat } from "../utility/Utility";

const ContributorSignup = (props) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [profileAlertMessage, setProfileAlertMessage] = useState("");
  const [businessAlertMessage, setBusinessAlertMessage] = useState("");
  const [bioAlertMessage, setBioAlertMessage] = useState("");
  const [profileloading, setProfileLoading] = useState(false);
  const [businessCardloading, setBusinessCardLoading] = useState(false);
  const [videoloading, setVideoLoading] = useState(false);
  const [value, setValue] = useState();
  const [profileMedia, setProfileMedia] = useState("");
  const [businessCard, setBusinessCard] = useState("");
  const [bioMedia, setBioMedia] = useState("");
  const [profileMediaFile, setProfileMediaFile] = useState("");
  const [businessCardFile, setBusinessCardFile] = useState("");
  const [bioMediaFile, setBioMediaFile] = useState("");

  let cType = props.selectedUserType;
  let ContributorSignupSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    clinicName: Yup.string().required(
      "Clinic/Hospital Name is required"
    ),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    password: Yup.string()
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    NPInumber: Yup.string().required("NPI Number is required"),
    linkedinURL: Yup.string().required("Linkedin URL is required"),
    contributorSubType: Yup.string().required(
      "Type of Clinician is required"
    ),
    bio: Yup.string().required("Bio is required"),
    profileMedia: Yup.string().required("Profile Image is required"),
    businessCard: Yup.string().required("Busnesscard is required"),
    bioMedia: Yup.string().required("Bio video is required"),
    contributorTerms: Yup.bool().required().oneOf([true], "Please check this mark if you want to proceed."),
  });

  let initialValues = {
    contributorType: cType,
    firstName: "",
    lastName: "",
    clinicName: "",
    email: "",
    phone: "",
    isPhoneVerified: 0,
    isEmailVerified: 0,
    userType: "contributor",
    password: "",
    NPInumber: "",
    linkedinURL: "",
    contributorSubType: "",
    bio: "",
    profileMedia: "",
    businessCard: "",
    bioMedia: "",
    deviceToken: "",
    contributorTerms: false,
  };

  let placeholder = "Name of Hospital or Clinic*";
  if (cType === "Industry representative") {
    placeholder = "Company Name*";

    ContributorSignupSchema = Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      clinicName: Yup.string().required(
        "Company Name is required"
      ),
      email: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
      phone: Yup.string().required("Phone number is required"),
      password: Yup.string()
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      linkedinURL: Yup.string().required("Linkedin URL is required"),
      bio: Yup.string().required("Bio is required"),
      profileMedia: Yup.string().required("Profile Image is required"),
      businessCard: Yup.string().required("Busnesscard is required"),
      bioMedia: Yup.string().required("Bio video is required"),
      contributorTerms: Yup.bool().required().oneOf([true], "Please check this mark if you want to proceed."),
    });

    initialValues = {
      contributorType: cType,
      firstName: "",
      lastName: "",
      clinicName: "",
      email: "",
      phone: "",
      isPhoneVerified: 0,
      isEmailVerified: 0,
      userType: "contributor",
      password: "",
      linkedinURL: "",
      bio: "",
      profileMedia: "",
      businessCard: "",
      bioMedia: "",
      deviceToken: "",
      contributorTerms: false,
    };
  }

  const uploadMedia = (formData, mName) => {
    if (mName == "handleProfileMedia") {
      setProfileLoading(true);
    }
    if (mName == "handleBusinessCard") {
      setBusinessCardLoading(true);
    }
    if (mName == "handleBioMedia") {
      setVideoLoading(true);
    }
    uploadFile(formData)
      .then((response) => {
        let uploadedFileName = response?.data?.data?.fd;
        if (mName == "handleProfileMedia") {
          setProfileMediaFile(uploadedFileName);
          setProfileLoading(false);
        }
        if (mName == "handleBusinessCard") {
          setBusinessCardFile(uploadedFileName);
          setBusinessCardLoading(false);
        }
        if (mName == "handleBioMedia") {
          setBioMediaFile(uploadedFileName);
          setVideoLoading(false);
        }
      })
      .catch((error) => {
        let message = error.response?.data?.message;
        if (message) {
          setProfileAlertMessage(message);
          setBioAlertMessage(message);
        }
      });
  };

  const onChangeImage = async (file, type, mName) => {
    if (file) {
      setProfileAlertMessage("");
      setBioAlertMessage("");

      if (type === "Video") {
        const duration = await helper.getVideoDuration(file);
        if (!isVideoFormat(file.name)) {
          setBioAlertMessage("Please upload valid video format");
          return false;
        }
        else if (duration > 60) {
          setBioAlertMessage("Video duration should be less than 1 minutes");
          return false;
        }
        else {
          const formData = new FormData();
          formData.append("file", file, file.name);
          uploadMedia(formData, mName);
        }
      } else if (
        !["png", "jpg", "jpeg", "gif", "svg", "webp"].includes(
          file.name.split(".")[1]
        )
      ) {
          if(mName === 'handleProfileMedia') {
            setProfileAlertMessage("Please upload valid image format");
          } else {
            setBusinessAlertMessage("Please upload valid image format");
          }          
          return false;
      } else {
        const formData = new FormData();
        formData.append("file", file, file.name);
        uploadMedia(formData, mName);
      }
    }
  };

  function handleProfileMedia(e) {
    setProfileMedia(URL.createObjectURL(e.target.files[0]));
    onChangeImage(e.target.files[0], "Image", "handleProfileMedia");
  }

  function handleBusinessCard(e) {
    setBusinessCard(URL.createObjectURL(e.target.files[0]));
    onChangeImage(e.target.files[0], "Image", "handleBusinessCard");
  }

  function handleBioMedia(e) {
    setBioMedia(URL.createObjectURL(e.target.files[0]));
    onChangeImage(e.target.files[0], "Video", "handleBioMedia");
  }

  const phoneNumberFormat = (phone) => {
    phone = phone.replace(/\D/g, "");
    phone = phone.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    const size = phone.length;
    if (size == 0) {
      phone = phone;
    } else if (size < 4) {
      phone = "(" + phone;
    } else if (size < 7) {
      phone = "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6);
    } else {
      phone =
        "(" +
        phone.substring(0, 3) +
        ") " +
        phone.substring(3, 6) +
        "-" +
        phone.substring(6, 10);
    }

    return phone;
  };

  const phoneFormat = (phone) => {
    setValue(phoneNumberFormat(phone));
  };

  return (
    <>
      {alertMessage && (
        <Alert
          variant="danger"
          onClose={() => {
            setAlertMessage("");
          }}
          dismissible
        >
          <p>{alertMessage}</p>
        </Alert>
      )}
      <Formik
        validationSchema={ContributorSignupSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          values.phone = value;
          values.profileMedia = profileMediaFile;
          values.businessCard = businessCardFile;
          values.bioMedia = bioMediaFile;

          if (values.contributorType) {
            values.contributorType = cType;
          }

          userSignup(values)
            .then((response) => {
              if (response.data?.type == "success") {
                setAlertMessage("");
                const obj = {email: values.email, password: values.password};
                props.handleContributorNextPanel("block", obj);
              }
            })
            .catch((error) => {
              props.handleContributorNextPanel("none", null);
              console.error("Contributor Signup --- ", error);
              let message = error.response?.data?.message;
              if (message) {
                setAlertMessage(message);
              }
            });
        }}
      >
        {({ handleChange, handleSubmit, touched, values, errors }) => (
          <Form
            className="contribute-signup-modal-form"
            noValidate
            onSubmit={handleSubmit}
            method="post"
            encType="multipart/form-data"
          >
            <Row>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Select
                    disabled
                    name="contributorType"
                    value={props.selectedUserType}
                  >
                    <option value="Clinician">Clinician</option>
                    <option value="Industry representative">
                      Industry representative
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    isInvalid={touched.firstName && !!errors.firstName}
                    placeholder="First Name *"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    isInvalid={touched.lastName && !!errors.lastName}
                    placeholder="Last Name *"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Control
                    type="text"
                    name="clinicName"
                    value={values.clinicName}
                    onChange={handleChange}
                    isInvalid={touched.clinicName && !!errors.clinicName}
                    placeholder={placeholder}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.clinicName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={touched.email && !!errors.email}
                    placeholder="Email *"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Control
                    type="tel"
                    name="phone"
                    value={value}
                    onChange={(e) => {
                      phoneFormat(e.target.value);
                      handleChange(e);
                    }}
                    isInvalid={touched.phone && !!errors.phone}
                    placeholder="Phone Number *"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Control
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                    placeholder="Password *"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    isInvalid={
                      touched.confirmPassword && !!errors.confirmPassword
                    }
                    placeholder="Confirm Password *"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              {cType === "Clinician" && (
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Control
                      type="text"
                      name="NPInumber"
                      value={values.NPInumber}
                      onChange={handleChange}
                      isInvalid={touched.NPInumber && !!errors.NPInumber}
                      placeholder="NPI Number *"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.NPInumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Control
                    type="text"
                    name="linkedinURL"
                    value={values.linkedinURL}
                    onChange={handleChange}
                    isInvalid={touched.linkedinURL && !!errors.linkedinURL}
                    placeholder="Linked URL *"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.linkedinURL}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {cType === "Clinician" && (
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Control
                      type="text"
                      name="contributorSubType"
                      value={values.contributorSubType}
                      onChange={handleChange}
                      isInvalid={
                        touched.contributorSubType &&
                        !!errors.contributorSubType
                      }
                      placeholder="Type of Clinician *"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contributorSubType}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Control
                    as="textarea"
                    name="bio"
                    value={values.bio}
                    onChange={handleChange}
                    isInvalid={touched.bio && !!errors.bio}
                    placeholder="Bio*"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bio}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <div className="signup-upload-list">
                    {profileAlertMessage && (
                      <p className="text-danger">{profileAlertMessage}</p>
                    )}
                    {(businessAlertMessage) && (
                      <p className="text-danger">{businessAlertMessage}</p>
                    )}
                    {bioAlertMessage && (
                      <p className="text-danger">{bioAlertMessage}</p>
                    )}
                    <ul className="list-style-none">
                      <li>
                        <div className="upload-file">
                          <Form.Control
                            type="file"
                            name="profileMedia"
                            isInvalid={touched.profileMedia && !!errors.profileMedia}
                            onChange={(e) => {
                              handleProfileMedia(e);
                              handleChange(e);
                            }}
                            className="file-blank"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.profileMedia}
                          </Form.Control.Feedback>
                          <div className="upload-file-text">
                            <span className="upload-icon">
                              <i className="fas fa-cloud-upload-alt"></i>
                            </span>
                            <h5>Upload Headshot Photo</h5>
                          </div>
                          {profileMedia && !profileAlertMessage ? (
                            <div className="signup-upload-file-pic">
                              <img src={profileMedia} />
                            </div>
                          ) : null}
                        </div>
                        {profileloading ? <><div className="spinner-loader"><div className="spinner-border"><span className="sr-only">Loading...</span></div></div></> : null}

                      </li>
                      <li>
                        <div className="upload-file">
                          <Form.Control
                            type="file"
                            name="businessCard"
                            isInvalid={touched.businessCard && !!errors.businessCard}
                            onChange={(e) => {
                              handleBusinessCard(e);
                              handleChange(e);
                            }}
                            className="file-blank"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.businessCard}
                          </Form.Control.Feedback>
                          <div className="upload-file-text">
                            <span className="upload-icon">
                              <i className="fas fa-cloud-upload-alt"></i>
                            </span>
                            <h5>
                              Upload Image of <br />
                              Business Card
                            </h5>
                          </div>
                          {businessCard && !businessAlertMessage ? (
                            <div className="signup-upload-file-pic">
                              <img src={businessCard} />
                            </div>
                          ) : null}

                        </div>
                        {businessCardloading ? <><div className="spinner-loader"><div className="spinner-border"><span className="sr-only">Loading...</span></div></div></> : null}

                      </li>
                      <li>
                        <div className="upload-file">
                          <Form.Control
                            type="file"
                            name="bioMedia"
                            isInvalid={touched.bioMedia && !!errors.bioMedia}
                            onChange={(e) => {
                              handleBioMedia(e);
                              handleChange(e);
                            }}
                            className="file-blank"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.bioMedia}
                          </Form.Control.Feedback>
                          <div className="upload-file-text">
                            <span className="upload-icon">
                              <i className="fas fa-cloud-upload-alt"></i>
                            </span>
                            <h5>Upload Bio Video</h5>
                            <p>
                              Limit Size of Video <br />
                              2-4 Mb (60Sec.)
                            </p>
                          </div>
                          {bioMedia && !bioAlertMessage ? (
                            <div className="signup-upload-file-pic">
                              <video playsInline width="100%" height="200">
                                <source src={bioMedia} type="video/mp4" />
                              </video>
                            </div>
                          ) : null}
                        </div>
                        {videoloading ? <><div className="spinner-loader"><div className="spinner-border"><span className="sr-only">Loading...</span></div></div></> : null}

                      </li>
                    </ul>



                  </div>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Check type="checkbox" id="">
                    <Form.Check.Input
                      type="checkbox"
                      required
                      name="contributorTerms"
                      value={values.contributorTerms}
                      onChange={handleChange}
                      // isInvalid={!!errors.contributorTerms}
                      isInvalid={touched.contributorTerms && !!errors.contributorTerms}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contributorTerms}
                    </Form.Control.Feedback>
                    <Form.Check.Label>
                      I have read and agree to MEDcraze’s{" "}
                      <Link to="/terms-of-use" target="_blank">
                        Terms & Conditions
                      </Link>{" "}
                      &{" "}
                      <Link to="/privacy-policy" target="_blank">
                        Privacy Policy
                      </Link>{" "}
                      &{" "}
                      <Link to="/engagement-letter" target="_blank">
                        Engagement Letter
                      </Link>{" "}
                      and{" "}
                      <Link to="/confidential-aggrement" target="_blank">
                        Confidentiality & Proprietary Rights Agreements
                      </Link>
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <div className="modal-action-btns">
                    <Button type="submit" className="btn-blue w-100">
                      Sign Up
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ContributorSignup;
